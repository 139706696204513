import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CForm,
  CFormInput,
  CDropdownMenu,
  CDropdownItem,
  CDropdown,
  CDropdownToggle,
  CDropdownDivider,
} from "@coreui/react-pro";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Link, useLocation, useNavigate } from "react-router";
import { Supplier, SupplierStock } from "src/api/suppliers";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm } from "src/types";
import Api from "src/api";

import { logEvent } from "src/helpers/analytics";

import { useFormik } from "formik";
import { queryStringToObject, truncateString } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import Pagination from "src/components/Pagination";
import ProductsConfigModal, {
  ProductsConfigModalForwardedRef,
} from "../products/components/ProductsConfigModal";
import { useRef } from "react";
import {
  Cog,
  FileDown,
  Loader,
  ShoppingCart,
  TextSearch,
  Truck,
} from "lucide-react";
import writeXlsxFile from "write-excel-file";
import { formatCurrency } from "src/helpers/numbers";
import { useUserStore } from "src/store/users";

const SuppliersScreen = () => {
  const configModalRef = useRef<ProductsConfigModalForwardedRef>(null);
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const { user, hasPermission } = useUserStore();

  const {
    data: suppliers,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Supplier>>(Api.Suppliers.LIST_SUPPLIERS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        search: querySearch.search ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination(
    "suppliers",
    refetch
  );

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: querySearch.search,
    },

    onSubmit: (input) => {
      if (!loading) {
        resetAndSearch({
          search: input.search,
          page: 1,
        });
      }
    },
  });

  const [fetchStock, { loading: fetching }] = useLazyQuery(
    Api.Suppliers.GET_SUPPLIER_STOCK,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ data }) => {
        downloadStock(data);
      },
    }
  );

  const downloadStock = (warehouseStock: SupplierStock[]) => {
    const excelData: any[][] = [[]];

    warehouseStock.forEach(({ products, supplier }, index) => {
      const excelSheet: any[] = [];

      excelSheet.push([
        { value: "Producto" },
        { value: "Proveedor" },
        { value: "Cantidad" },
        { value: "Costo por Unidad" },
        { value: "Costo Total" },
        { value: "Importe por Unidad" },
        { value: "Importe Total" },
      ]);

      if (products.length === 0) {
        excelData[index] = excelSheet;

        return;
      }

      products.forEach((product) => {
        excelSheet.push([
          {
            value: product.name,
          },
          {
            value: supplier.name,
          },
          {
            value: product.stock,
          },
          {
            value: product.cost,
          },
          {
            value: product.cost * product.stock,
          },
          {
            value: product.price,
          },
          {
            value: product.price * product.stock,
          },
        ]);
      });

      const { stock, cost, price } = products.reduce(
        (prev, curr) => {
          return {
            ...prev,
            stock: prev.stock + curr.stock,
            cost: prev.cost + curr.cost * curr.stock,
            price: prev.price + curr.price * curr.stock,
          };
        },
        {
          stock: 0,
          cost: 0,
          price: 0,
        }
      );

      excelSheet.push([
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: stock,
        },
        {
          value: "",
        },
        {
          value: formatCurrency(cost),
        },
        {
          value: "",
        },
        {
          value: formatCurrency(price),
        },
      ]);

      excelData[index] = excelSheet;
    });

    const reportName = `Stock Valorizado del Proveedor ${warehouseStock[0].supplier.name}`;

    writeXlsxFile(excelData, {
      sheets: warehouseStock.map(
        ({ warehouse, products }) =>
          `${truncateString(warehouse.name.replaceAll("/", "-"), 20)} (${
            products.length
          })`
      ),
      fileName: `${reportName}.xlsx`,
    });
  };

  const fields: any[] = [
    { key: "id", label: "ID" },
    { key: "name", label: "Nombre", className: "font-weight-bold" },
    { key: "count", label: "# de Productos" },
    { key: "notes", label: "Notas" },
  ];

  if (user?.isAdmin) {
    fields.push({ key: "company", label: "Empresa" });
  }

  if (hasPermission("UPDATE_SUPPLIER")) {
    fields.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right" },
    });
  }

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={8} className="px-0">
                  Proveedores
                </CCol>
                <CCol sm={4} className="row justify-content-end">
                  {hasPermission("CREATE_SUPPLIER") && (
                    <Link
                      to="/suppliers/new"
                      className="flex justify-content-end p-0"
                    >
                      <CButton
                        size="sm"
                        color="primary"
                        onClick={() => {
                          logEvent("suppliers.create");
                        }}
                      >
                        Crear proveedor
                      </CButton>
                    </Link>
                  )}
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar..."
                      name="search"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 1 }}
                    />
                    <CButton
                      color="primary"
                      type="submit"
                      className="ml-2 p-0 w-10 h-10"
                    >
                      <TextSearch />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={suppliers?.data.data || []}
                columns={fields}
                tableProps={{
                  striped: true,
                }}
                scopedColumns={{
                  company: (item: Supplier) => <td>{item.company.name}</td>,
                  count: (item: Supplier) => {
                    const productsCount = item.products.length;

                    return (
                      <td>
                        {productsCount} producto
                        {productsCount === 0 || productsCount > 1 ? "s" : ""}
                      </td>
                    );
                  },
                  notes: (item: Supplier) => <td>{item.notes}</td>,
                  actions: (item: Supplier) => (
                    <td className="text-right">
                      <CDropdown variant="btn-group" alignment="end">
                        <CButton
                          type="button"
                          color="info"
                          size="sm"
                          onClick={() => {
                            navigate(`/suppliers/${item.id}`);
                          }}
                        >
                          Editar Proveedor
                        </CButton>

                        <CDropdownToggle color="info" size="sm" split />

                        <CDropdownMenu>
                          <CDropdownItem
                            as="button"
                            onClick={() => {
                              navigate(`/suppliers/${item.id}/shipments`);
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              <Truck size={18} />
                              Ver pedidos
                            </div>
                          </CDropdownItem>

                          <CDropdownDivider />

                          <CDropdownItem
                            as="button"
                            onClick={() => {
                              logEvent("suppliers.navigate", {
                                date: item,
                              });

                              navigate(`/suppliers/${item.id}/products`);
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              <ShoppingCart size={18} />
                              Ver productos
                            </div>
                          </CDropdownItem>

                          <CDropdownItem
                            as="button"
                            onClick={(e) => {
                              configModalRef.current?.open(
                                item.id,
                                item.name,
                                "supplier"
                              );
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              <Cog size={18} />
                              Configurar productos
                            </div>
                          </CDropdownItem>

                          <CDropdownItem
                            as="button"
                            onClick={() => {
                              if (!fetching) {
                                fetchStock({
                                  variables: {
                                    supplierId: item.id,
                                  },
                                });
                              }
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              {fetching ? (
                                <Loader size={18} />
                              ) : (
                                <FileDown size={18} />
                              )}
                              Stock Valorizado
                            </div>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                }}
              />

              <Pagination
                meta={suppliers}
                page={page}
                pageChange={pageChange}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <ProductsConfigModal ref={configModalRef} />
    </>
  );
};

export default SuppliersScreen;
