import { gql } from "@apollo/client";
import { PaymentMethod } from "./sales";
import yup from "src/helpers/validation";
import { Nullable } from "src/types";

export const SendReportEmailSchema = yup.object().shape({
  reportId: yup.number().min(1).required(),
  email: yup.string().email().required(),
});

export type CheckingAccountMove = {
  id: number;
  accountId: number;
  amount: number;
  paymentMethod: PaymentMethod;
  notes: string;
  createdAt: Date;
};

export type CheckingAccount = {
  id: number;
  negativeMargin: number;
  balance: number;

  moves: CheckingAccountMove[];
};

export type CheckingAccountReport = {
  id: number;
  accountId: number;
  emailSent: Nullable<Date>;

  itemsCount: number;
  salesCount: number;
  prevBalance: number;
  nextBalance: number;

  pdfUrl: string;

  dateFrom: Date;
  dateTo: Date;

  account: CheckingAccount;
};

export const GET_ACCOUNT = gql`
  query checkingAccount($id: Int!) {
    data: checkingAccount(id: $id) {
      negativeMargin
      balance

      moves {
        id
        amount
        paymentMethod
        notes
        createdAt
      }
    }
  }
`;

export const LIST_REPORTS = gql`
  query checkingAccountReports($accountId: Int!) {
    data: checkingAccountReports(accountId: $accountId) {
      data {
        id
        itemsCount
        salesCount
        emailSent
        prevBalance
        nextBalance
        dateFrom
        dateTo
        pdfUrl
      }
      meta {
        total
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createCheckingAccount($input: CreateCheckingAccountInput!) {
    data: createCheckingAccount(input: $input) {
      id
    }
  }
`;

export const CREATE_MOVE = gql`
  mutation createCheckingAccountMove($input: CreateCheckingAccountMoveInput!) {
    data: createCheckingAccountMove(input: $input) {
      move {
        amount
      }

      sessions {
        id

        register {
          id
          name

          store {
            name
            type
          }
        }
      }
    }
  }
`;

export const SEND_REPORT = gql`
  mutation ($reportId: Int!, $emails: [String!]!) {
    data: sendReport(reportId: $reportId, emails: $emails) {
      id
    }
  }
`;
