import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLink,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { useRef } from "react";
import Api from "src/api";
import { CashRegister } from "src/api/registers";
import { Store, StoreType } from "src/api/stores";
import { AppLoader } from "src/components/Loader/Loader";
import { logEvent } from "src/helpers/analytics";
import { GraphQLMeta } from "src/types";
import ConnectStoreModal, {
  CustomDialogProps,
} from "./components/ConnectStoreModal";
import ConnectRegisterModal from "./components/ConnectRegisterModal";
import { useUserStore } from "src/store/users";

const MercadoPago = () => {
  const connectStoreModal = useRef<CustomDialogProps>(null);
  const connectRegisterModal = useRef<CustomDialogProps>(null);
  const { currentCompany } = useUserStore();

  const {
    data: stores,
    loading: loadingStores,
    refetch: refetchStores,
  } = useQuery<GraphQLMeta<Store>>(Api.Stores.LIST_STORES, {
    fetchPolicy: "no-cache",
  });

  const {
    data: cashRegisters,
    loading: loadingRegisters,
    refetch: refetchRegisters,
  } = useQuery<GraphQLMeta<CashRegister>>(Api.Registers.LIST_REGISTERS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        companyId: currentCompany?.id,
        types: [StoreType.Store, StoreType.Digital],
      },
    },
  });

  const [fetchMPData] = useLazyQuery(Api.MercadoPago.GET_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: ({ data }) => {
      if (data.registerId) {
        connectRegisterModal.current?.open({
          register: cashRegisters?.data.data?.find(
            (r) => r.id === data.registerId
          ),
          mpData: data,
        });
      } else {
        connectStoreModal.current?.open({
          store: stores?.data.data?.find((s) => s.id === data.storeId),
          mpData: data,
        });
      }
    },
  });

  const refetch = () => {
    refetchStores();
    refetchRegisters();
  };

  const [linkStoreMutation] = useMutation(Api.MercadoPago.LINK_STORE, {
    onCompleted: () => {
      refetch();
    },
  });

  const [createAndLinkStoreMutation] = useMutation(
    Api.MercadoPago.CREATE_LINK_STORE,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const [linkRegisterMutation] = useMutation(Api.MercadoPago.LINK_REGISTER, {
    onCompleted: () => {
      refetch();
    },
  });

  const [createAndLinkRegisterMutation] = useMutation(
    Api.MercadoPago.CREATE_LINK_REGISTER,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  if (!stores?.data.data) {
    return <AppLoader />;
  }

  return (
    <>
      <CCard>
        <CCardHeader>
          <CRow className="align-items-center justify-content-center">
            <CCol sm={6} xs="6" className="px-0">
              Puntos de Venta
            </CCol>
            <CCol sm={6} xs="6" className="row justify-content-end"></CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CSmartTable
            loading={loadingStores}
            items={
              stores.data.data.filter((store) =>
                [StoreType.Store, StoreType.Digital].includes(store.type)
              ) || []
            }
            columns={[
              {
                key: "id",
                label: "ID",
              },
              {
                key: "name",
                label: "Nombre",
              },
              {
                key: "address",
                label: "Dirección",
              },
              {
                key: "mercadoPagoAccount",
                label: "Cuenta MP",
                _props: { className: "text-right" },
              },
              {
                key: "mercadoPagoPOS",
                label: "Punto de Venta MP",
                _props: { className: "text-right" },
              },
            ]}
            scopedColumns={{
              name: (item: Store) => (
                <td>
                  <span>{item.name}</span>
                </td>
              ),
              address: (item: Store) => (
                <td>
                  <span>
                    {item.streetName} {item.streetNumber}
                  </span>
                </td>
              ),
              mercadoPagoAccount: (store: Store) => (
                <td className="text-right">
                  {store.connections?.mercadoPagoConnected ? (
                    <i>Conectado</i>
                  ) : (
                    <CButton
                      size="sm"
                      color="primary"
                      onClick={() => {}}
                      type="button"
                    >
                      Conectar
                    </CButton>
                  )}
                </td>
              ),
              mercadoPagoPOS: (store: Store) => (
                <td className="text-right">
                  {!store.connections?.mercadoPagoConnected ? (
                    <span>Cuenta MP sin conectar</span>
                  ) : store.metadata?.mercadoPagoPOSId ? (
                    <i>Configurado</i>
                  ) : (
                    <CButton
                      size="sm"
                      color="primary"
                      onClick={() => {
                        fetchMPData({
                          variables: {
                            storeId: store.id,
                          },
                        });

                        logEvent("mercado-pago.connect.store.click", {
                          store,
                        });
                      }}
                      type="button"
                    >
                      Enlazar
                    </CButton>
                  )}
                </td>
              ),
            }}
            tableProps={{
              striped: true,
            }}
          />
        </CCardBody>

        <ConnectStoreModal
          ref={connectStoreModal}
          closeOnSubmit
          onSubmit={(props) => {
            if (props.mercadoPagoPOSId === -1) {
              logEvent("mercado-pago.create-and-connect.store.submit", {
                input: props,
              });

              createAndLinkStoreMutation({
                variables: {
                  storeId: props.storeId,
                  name: props.name,
                },
              });
            } else {
              logEvent("mercado-pago.connect.store.submit", {
                input: props,
              });

              linkStoreMutation({
                variables: {
                  storeId: props.storeId,
                  mercadoPagoPOSId: props.mercadoPagoPOSId,
                },
              });
            }
          }}
        />
      </CCard>

      <CCard className="mt-3">
        <CCardHeader>
          <CRow className="align-items-center justify-content-center">
            <CCol sm={6} xs="6" className="px-0">
              Cajas Registradoras / QR de Mercado Pago
            </CCol>
            <CCol sm={6} xs="6" className="row justify-content-end"></CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CSmartTable
            loading={loadingRegisters}
            items={cashRegisters?.data.data || []}
            columns={[
              {
                key: "id",
                label: "ID",
              },
              {
                key: "name",
                label: "Nombre",
              },
              {
                key: "store",
                label: "Punto de Venta",
              },
              {
                key: "mercadoPago",
                label: "Caja Registradora MP",
                _props: { className: "text-right" },
              },
            ]}
            scopedColumns={{
              name: (register: CashRegister) => (
                <td>
                  <span>{register.name}</span>
                </td>
              ),
              store: (register: CashRegister) => (
                <td>
                  <span>
                    {register.store.name} - {register.store.streetName}{" "}
                    {register.store.streetNumber}
                  </span>
                </td>
              ),
              mercadoPago: (register: CashRegister) => (
                <td className="text-right">
                  {register.metadata?.mercadoPagoRegisterId ? (
                    <CLink
                      target="_blank"
                      href={register.metadata?.mercadoPagoQR?.templatePdf}
                      onClick={() =>
                        logEvent("mercado-pago.download-qr", {
                          register,
                        })
                      }
                    >
                      <CButton size="sm" color="success" type="button">
                        Descargar QR
                      </CButton>
                    </CLink>
                  ) : register.store.metadata?.mercadoPagoPOSId ? (
                    <CButton
                      size="sm"
                      color="primary"
                      onClick={() => {
                        logEvent("mercado-pago.connect.register.click", {
                          register,
                        });

                        fetchMPData({
                          variables: {
                            storeId: register.store.id,
                            registerId: register.id,
                          },
                        });
                      }}
                      type="button"
                    >
                      Enlazar
                    </CButton>
                  ) : (
                    <i>Punto de Venta sin conectar</i>
                  )}
                </td>
              ),
            }}
            tableProps={{
              striped: true,
            }}
          />
        </CCardBody>

        <ConnectRegisterModal
          ref={connectRegisterModal}
          closeOnSubmit
          onSubmit={(props) => {
            if (props.mercadoPagoRegisterId === -1) {
              logEvent("mercado-pago.create-and-connect.register.submit", {
                input: props,
              });

              createAndLinkRegisterMutation({
                variables: {
                  registerId: props.registerId,
                  name: props.name,
                },
              });
            } else {
              logEvent("mercado-pago.connect.register.submit", {
                input: props,
              });

              linkRegisterMutation({
                variables: {
                  registerId: props.registerId,
                  mercadoPagoRegisterId: props.mercadoPagoRegisterId,
                },
              });
            }
          }}
        />
      </CCard>
    </>
  );
};

export default MercadoPago;
