import {
  CButton,
  CModal,
  CModalBody,
  CNavItem,
  CNavLink,
} from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { logEvent } from "src/helpers/analytics";
import { useLayoutStore } from "src/store/layout";
import { AppVersion, CommitHash } from "src/types";

const AppVersionButton = () => {
  const { commitHash } = useLayoutStore();
  const [showModal, setShowModal] = useState(false);

  const handleDismiss = () => {
    setShowModal(false);
  };

  const downloadVersion = () => {
    logEvent("admin.commit.different.download", {
      AppVersion,
      currentVersion: CommitHash,
      newVersion: commitHash,
    });

    window.location.href = "/";
  };

  useEffect(() => {
    if (showModal) {
      if (commitHash !== CommitHash) {
        logEvent("admin.commit.different", {
          AppVersion,
          currentVersion: CommitHash,
          newVersion: commitHash,
        });
      }
    }
  }, [commitHash, showModal]);

  return (
    <>
      <CNavItem>
        <CNavLink
          onClick={() => {
            setShowModal(true);
          }}
        >
          v{AppVersion}
        </CNavLink>
      </CNavItem>

      {showModal && (
        <CModal
          alignment="center"
          size="sm"
          visible={showModal}
          onClose={() => {
            handleDismiss();
          }}
        >
          <CModalBody>
            <span>
              Version:
              <span className="ml-1">v{AppVersion}</span>
            </span>

            <br />

            {!!commitHash && (
              <span>
                Commit:
                <span className="ml-1">{commitHash ?? ""}</span>
              </span>
            )}

            {!!commitHash && commitHash !== CommitHash && (
              <CButton
                variant="ghost"
                color="danger"
                size="sm"
                className="mt-1"
                onClick={() => downloadVersion()}
              >
                Nueva version disponible ({commitHash})
              </CButton>
            )}
          </CModalBody>
        </CModal>
      )}
    </>
  );
};

export default AppVersionButton;
