import { ApolloError } from "@apollo/client";
import { useNavigate } from "react-router";
import { Customer } from "src/api/customers";
import { ApolloErrorWithData } from "src/types";

export const useCustomerError = () => {
  const navigate = useNavigate();

  const handleCustomerErrorMessage = (error: ApolloError) => {
    const { graphQLErrors, message } = error as ApolloErrorWithData<{
      customer: Customer;
    }>;

    const errorCustomer = graphQLErrors?.[0]?.data?.customer;

    if (message === "customer_already_exists") {
      if (
        window.confirm(
          "Este cliente ya se encuentra creado en este punto de venta. Quieres ir a ese cliente?"
        )
      ) {
        navigate(`/customers/${errorCustomer.id}`, {
          state: { trashed: true },
        });
      }
    }
  };

  return { handleCustomerErrorMessage };
};
