const SvgComponent = ({ containerClassName = "", size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 512 512"
    className={containerClassName}
  >
    <path d="M3.4 124.4 0 127.8v256.4l3.4 3.4 3.4 3.4h498.4l3.4-3.4 3.4-3.4v-53.6c0-58.3 0-57.9-5.5-60.4-3.3-1.5-5.7-1.5-9 0-5.5 2.5-5.5 2.7-5.5 53.8v47H20V141h472v25c0 27.3.4 29.5 5.5 31.8 3.3 1.5 5.7 1.5 9 0 5.3-2.4 5.5-4 5.5-38.4v-31.6l-3.4-3.4-3.4-3.4H6.8l-3.4 3.4z" />
    <path d="M45.1 167.4c-6.7 3.7-6.5 14.3.4 17.4 4.1 1.9 65.7 1.7 69.4-.2 6.6-3.5 6.6-13.7 0-17.2-3.9-2-66.2-2-69.8 0zM412.1 187.4c-3.7 2-5 5.5-5.1 12.7v6.7l-3.3.6c-4.9.9-12.2 5.9-15.7 10.7-11 15.2-5.8 36.5 10.8 44.6 4.6 2.3 7.2 2.8 15.1 3.1 11.3.4 14.7 1.5 16.6 5.3 1.8 3.4 1.9 6 .3 9.4-2.2 4.8-5.1 5.5-23.8 5.5-12.8 0-17.8.4-19.9 1.4-6.6 3.5-6.6 13.7 0 17.2 1.8.9 5.9 1.4 11.4 1.4h8.5v6c0 7.1 1.5 10.7 5.1 12.6 3.5 1.8 6.3 1.8 9.8 0 3.7-2 5.1-5.5 5.1-13v-6.4l3.3-.6c4.9-.9 12.2-5.9 15.7-10.7 11-15.2 5.8-36.5-10.8-44.6-4.6-2.3-7.2-2.8-15.1-3.1-11.3-.4-14.7-1.5-16.6-5.3-1.8-3.4-1.9-6-.3-9.4 2.2-4.8 5.1-5.5 23.8-5.5 18.7 0 21.6-.7 23.8-5.5 1.6-3.4 1.5-6-.2-9.4-2.1-3.9-5.6-5.1-15.1-5.1H427v-6c0-7.1-1.5-10.7-5.1-12.6-3.4-1.7-6.7-1.7-9.8 0zM497.1 225.4c-8.7 4.8-5 18.6 4.9 18.6 5.1 0 10-4.9 10-9.9 0-7.5-8.3-12.4-14.9-8.7zM45.1 227.4c-6.7 3.7-6.5 14.3.4 17.4 2 .9 34.4 1.2 134.6 1.2 112.3 0 132.4-.2 134.8-1.4 6.6-3.5 6.6-13.7 0-17.2-4-2-266.1-2.1-269.8 0zM45.1 267.4c-6.7 3.7-6.5 14.3.4 17.4 2 .9 31 1.2 119.6 1.2 99.3 0 117.4-.2 119.8-1.4 6.6-3.5 6.6-13.7 0-17.2-4-2-236.1-2.1-239.8 0z" />
  </svg>
);

export default SvgComponent;
