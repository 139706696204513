import { useMutation, useQuery } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLoadingButton,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useLocation, useNavigate, useParams } from "react-router";
import Api from "src/api";
import { AppLoader } from "src/components/Loader/Loader";
import { dateFormat } from "src/helpers/dates";
import { usePagination } from "src/hooks/pagination";

import { GraphQLFind, GraphQLMeta } from "src/types";
import yup from "src/helpers/validation";
import { Retire } from "src/api/retires";
import Pagination from "src/components/Pagination";
import { queryStringToObject } from "src/helpers/strings";
import { Warehouse } from "src/api/warehouses";
import { useUserStore } from "src/store/users";

export const MoveStockSchema = yup.object().shape({
  productId: yup.number().min(1).required(),
  cost: yup.number().min(0.1).required(),
  quantity: yup.number().min(0.1).required(),
});

const InitialScreen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();
  const { hasPermission, currentCompany, user } = useUserStore();
  const querySearch = { ...queryStringToObject(search) };

  const warehouseId = Number(params.warehouseId);

  if (!warehouseId) {
    navigate(-1);
  }

  const { data: retires, refetch } = useQuery<GraphQLMeta<Retire>>(
    Api.Retires.LIST_RETIRES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page: querySearch.page ? Number(querySearch.page) : 1,
          companyId: currentCompany?.id,
          warehouseId,

          type: ["MOVE"],
        },
      },
    }
  );
  const { data: warehouseData } = useQuery<GraphQLFind<Warehouse>>(
    Api.Warehouses.GET_WAREHOUSE,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: warehouseId,
      },
    }
  );
  const [createRetire, { loading }] = useMutation(Api.Retires.CREATE_RETIRE, {
    onCompleted: ({ data }) => {
      navigate(`/stock/${warehouseId}/moves/${data.id}`);
      refetch();
    },
  });

  const { page, pageChange } = usePagination(
    `stock/${warehouseId}/moves`,
    refetch
  );

  if (!retires?.data.data || !warehouseData?.data) {
    return <AppLoader />;
  }

  const retiresList = retires.data.data;
  const warehouse = warehouseData.data;

  let canCreateMove = false;

  if (warehouse.stores?.length > 0) {
    canCreateMove = warehouse.stores?.some(
      (store) =>
        hasPermission("CREATE_BATCH", { type: "store", id: store.id }) &&
        hasPermission("CREATE_RETIRE", { type: "store", id: store.id })
    );
  } else {
    canCreateMove =
      hasPermission("CREATE_BATCH", {
        type: "company",
        id: warehouse.company.id,
      }) &&
      hasPermission("CREATE_RETIRE", {
        type: "company",
        id: warehouse.company.id,
      });
  }

  return (
    <CCard>
      <CCardHeader>
        <CRow className="align-items-center justify-content-center">
          <CCol sm={6}>Movimientos de {warehouse?.name}</CCol>
          <CCol sm={6} className="text-right">
            {canCreateMove && (
              <CLoadingButton
                color="primary"
                loading={loading}
                disabled={loading}
                size="sm"
                onClick={() => {
                  createRetire({
                    variables: {
                      input: {
                        companyId: currentCompany?.id,
                        userId: user?.id,
                        warehouseId,
                        type: "MOVE",
                      },
                    },
                  });
                }}
              >
                Crear nuevo movimiento
              </CLoadingButton>
            )}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CSmartTable
          items={retiresList ?? []}
          noItemsLabel="Aun no has hecho movimientos de stock entre depósitos"
          columns={[
            { key: "id", label: "ID" },
            { key: "user", label: "Usuario" },
            { key: "warehouse", label: "Depósito Salida" },
            { key: "toWarehouse", label: "Depósito Entrada" },
            {
              key: "createdAt",
              label: "Fecha de Creación",
              _props: { className: "text-center" },
            },
            {
              key: "updatedAt",
              label: "Ultima actualización",
              _props: { className: "text-center" },
            },
            {
              key: "status",
              label: "Estado",
              _props: { className: "text-right" },
            },
          ]}
          scopedColumns={{
            user: (item: Retire) => (
              <td>
                {item.user.name} {item.user.lastname}
              </td>
            ),
            warehouse: (item: Retire) => <td>{item.warehouse.name}</td>,
            toWarehouse: (item: Retire) => <td>{item.toWarehouse?.name}</td>,
            createdAt: (item: Retire) => (
              <td className="text-center">
                {dateFormat(item.createdAt, "dd/MM/yyyy HH:mm")}
              </td>
            ),
            updatedAt: (item: Retire) => (
              <td className="text-center">
                {dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm")}
              </td>
            ),
            status: (item: Retire) => (
              <td className="text-right">
                {item.status === "OPEN" && "Abierto"}
                {item.status === "FULFILLED" && "Movido"}
                {item.status === "CLOSED" && "Cerrado"}
              </td>
            ),
          }}
          tableProps={{
            striped: true,
          }}
          clickableRows
          onRowClick={(item: Item) => {
            navigate(`/stock/${warehouseId}/moves/${item.id}`);
          }}
        />

        <Pagination meta={retires} page={page} pageChange={pageChange} />
      </CCardBody>
    </CCard>
  );
};

export default InitialScreen;
