import { useMutation } from "@apollo/client";
import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { sortBy } from "lodash";
import { useCallback, useImperativeHandle, useState } from "react";
import { SelectSearchOption } from "react-select-search";
import Api from "src/api";
import { CheckingAccountMove } from "src/api/checkingAccounts";
import { CashRegisterSession, TransactionKind } from "src/api/registers";
import { PaymentMethod } from "src/api/sales";
import SmartSelect, { SmartSelectType } from "src/components/SmartSelect";
import { nonEditableMethods, paymentMethods } from "src/helpers/payments";
import { GraphQLMutation } from "src/types";

enum Step {
  AddMoney = "AddMoney",
  AddToRegister = "AddRegister",
}

const AddMoneyDialog = ({ accountId, refetch, ref }) => {
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState<Step>(Step.AddMoney);
  const [options, setOptions] = useState<SelectSearchOption[]>([]);

  const [createTransactionMutation, { loading: adding }] = useMutation(
    Api.Transactions.CREATE_TRANSACTION,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [createMutation, { loading: creating }] = useMutation(
    Api.CheckingAccounts.CREATE_MOVE,
    {
      onCompleted: (
        res: GraphQLMutation<{
          sessions: CashRegisterSession[];
          move: CheckingAccountMove;
        }>
      ) => {
        if (res.data.sessions.length > 0) {
          formik2.setFieldValue("amount", res.data.move.amount);

          setStep(Step.AddToRegister);
          setOptions(
            res.data.sessions.map((session) => ({
              value: `${session.id}-${session.register.id}`,
              name: `${session.register.name} - ${session.register.store.name}`,
              type: session.register.store.type,
            }))
          );
        }

        if (formik.values.type !== PaymentMethod.Cash) {
          window.location.reload();
        } else {
          refetch();
        }
      },
    }
  );

  const handleClose = () => {
    if (step === Step.AddToRegister) {
      window.location.reload();
    } else {
      formik.resetForm();
      setShowModal(false);
      setStep(Step.AddMoney);
    }
  };

  useImperativeHandle(
    ref,
    useCallback(
      () => ({
        open: () => {
          setStep(Step.AddMoney);
          setShowModal(true);
        },
        close: () => {
          setShowModal(false);
        },
      }),
      []
    )
  );

  const filteredPaymentMethods = sortBy(
    paymentMethods.filter((p) => !nonEditableMethods.includes(p.type)),
    "name"
  );

  const formik2 = useFormik({
    initialValues: {
      registerId: 0,
      sessionId: 0,
      amount: 0,
      type: TransactionKind.AddMoney,
    },
    onSubmit: (values) => {
      if (!adding) {
        createTransactionMutation({
          variables: {
            input: {
              registerId: values.registerId,
              sessionId: values.sessionId,
              amount: values.amount,
              type: values.type,
              description: `Ingreso a Cuenta Corriente.`,
            },
          },
        });
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      accountId,
      amount: 0,
      type: PaymentMethod.Cash,
    },
    onSubmit: (values) => {
      if (!creating) {
        const input = {
          accountId: values.accountId,
          type: values.type,
          amount: Number(values.amount),
          notes: "Ingreso a Cuenta",
        };

        createMutation({
          variables: {
            input,
          },
        });
      }
    },
  });

  return (
    <CModal
      alignment="center"
      visible={showModal}
      onClose={() => {
        handleClose();
      }}
      backdrop="static"
      keyboard={false}
    >
      <CModalHeader closeButton={false}>
        {step === Step.AddMoney
          ? "Agregar Dinero a Cuenta Corriente"
          : "Agregar Dinero a Caja Registradora"}
      </CModalHeader>
      <CModalBody>
        {step === Step.AddMoney ? (
          <CRow>
            <CCol sm={6}>
              <CFormLabel>Metodo de Pago</CFormLabel>
              <CFormSelect
                onChange={formik.handleChange}
                name="type"
                defaultValue={formik.values.type}
              >
                {filteredPaymentMethods.map((paymentMethod) => (
                  <option key={paymentMethod.id} value={paymentMethod.type}>
                    {paymentMethod.name}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
            <CCol sm={6}>
              <CFormLabel>Descuento ($)</CFormLabel>
              <CFormInput
                name="amount"
                value={formik.values.amount}
                type="number"
                min={1}
                onChange={formik.handleChange}
              />
            </CCol>
          </CRow>
        ) : (
          <CRow>
            <SmartSelect
              options={[{ value: 0, name: "Seleccione Caja" }, ...options]}
              name="sessionId"
              type={SmartSelectType.Stores}
              onChange={(e) => {
                if (e) {
                  const [sessId, regId] = e.toString().split("-");

                  formik2.setFieldValue("sessionId", Number(sessId));
                  formik2.setFieldValue("registerId", Number(regId));
                } else {
                  formik2.setFieldValue("sessionId", Number(0));
                  formik2.setFieldValue("registerId", Number(0));
                }
              }}
              value={`${formik2.values.sessionId}-${formik2.values.registerId}`}
            />
          </CRow>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton size="sm" onClick={() => handleClose()} color="danger">
          Cancelar
        </CButton>
        <CLoadingButton
          size="sm"
          loading={creating || adding}
          onClick={() => {
            if (step === Step.AddMoney) {
              formik.handleSubmit();
            } else {
              formik2.handleSubmit();
            }
          }}
          color="success"
        >
          {step === Step.AddMoney ? "Agregar" : "Ingresar a Caja"}
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};

export default AddMoneyDialog;
