import { useMutation } from "@apollo/client";
import { CButton, CForm, CFormInput, CSmartTable } from "@coreui/react-pro";
import { useFormik } from "formik";
import { Pencil, Save, Trash, X } from "lucide-react";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Api from "src/api";
import { Customer, CustomerAddress } from "src/api/customers";
import { getPaginationProps } from "src/hooks/pagination";

const CustomerAddresses: ForwardRefRenderFunction<
  {
    createRow: () => void;
  },
  { customer: Customer; refetch: () => void }
> = ({ customer, refetch }, ref) => {
  const [editRow, setEditRow] = useState<number>(-1);

  const [createMutation, { loading: creating }] = useMutation(
    Api.Customers.CREATE_ADDRESS,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
        setEditRow(-1);
      },
    }
  );

  const [editMutation, { loading: editing }] = useMutation(
    Api.Customers.EDIT_ADDRESS,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
        setEditRow(-1);
      },
    }
  );

  const [deleteMutation, { loading: deleting }] = useMutation(
    Api.Customers.DELETE_ADDRESS,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
        setEditRow(-1);
      },
    }
  );

  useImperativeHandle(
    ref,
    useCallback(
      () => ({
        createRow: () => setEditRow(0),
      }),
      []
    )
  );

  const formik = useFormik({
    initialValues: {
      streetName: "",
      streetNumber: "",
      city: "",
      province: "",
      zipCode: "",
    },
    onSubmit: (data) => {
      if (!creating && !editing && !deleting) {
        if (editRow === 0) {
          createMutation({
            variables: {
              customerId: customer.id,
              input: data,
            },
          });
        } else {
          editMutation({
            variables: {
              id: editRow,
              customerId: customer.id,
              input: data,
            },
          });
        }
      }
    },
  });

  const addresses = useMemo(() => {
    if (editRow === 0) {
      return [...customer.addresses, { id: 0 }];
    }

    return customer.addresses;
  }, [customer.addresses, editRow]);

  return (
    <CForm>
      <CSmartTable
        itemsPerPage={20}
        items={addresses}
        {...getPaginationProps(customer.addresses)}
        columns={[
          {
            key: "streetName",
            label: "Calle y Numero",
          },
          { key: "city", label: "Ciudad" },
          { key: "province", label: "Provincia" },
          {
            key: "zipCode",
            label: "Codigo Postal",
            _props: { className: "text-center" },
          },
          {
            key: "actions",
            label: "Acciones",
            _props: { className: "text-right" },
          },
        ]}
        scopedColumns={{
          streetName: (address: CustomerAddress) => {
            return (
              <td>
                {editRow === address.id ? (
                  <div className="d-flex gap-2">
                    <CFormInput
                      name="streetName"
                      onChange={formik.handleChange}
                      value={formik.values.streetName}
                    />
                    <CFormInput
                      name="streetNumber"
                      onChange={formik.handleChange}
                      style={{ width: 72 }}
                      value={formik.values.streetNumber}
                    />
                  </div>
                ) : (
                  <span>
                    {address.streetName} {address.streetNumber}
                  </span>
                )}
              </td>
            );
          },
          city: (address: CustomerAddress) => (
            <td>
              {editRow === address.id ? (
                <CFormInput
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
              ) : (
                <span>{address.city}</span>
              )}
            </td>
          ),
          province: (address: CustomerAddress) => (
            <td>
              {editRow === address.id ? (
                <CFormInput
                  name="province"
                  onChange={formik.handleChange}
                  value={formik.values.province}
                />
              ) : (
                <span>{address.province}</span>
              )}
            </td>
          ),
          zipCode: (address: CustomerAddress) => (
            <td className="text-center">
              {editRow === address.id ? (
                <CFormInput
                  name="zipCode"
                  onChange={formik.handleChange}
                  value={formik.values.zipCode}
                />
              ) : (
                <span>{address.zipCode}</span>
              )}
            </td>
          ),
          actions: (address: CustomerAddress) => (
            <td className="text-right">
              {editRow === address.id ? (
                <div className="d-flex gap-2 justify-content-end">
                  {editRow > 0 && (
                    <CButton
                      color="warning"
                      size="sm"
                      type="button"
                      onClick={() => {
                        setEditRow(-1);
                        formik.resetForm();
                      }}
                    >
                      <X />
                    </CButton>
                  )}

                  <CButton
                    color="success"
                    size="sm"
                    type="button"
                    onClick={() => formik.handleSubmit()}
                  >
                    <Save />
                  </CButton>
                </div>
              ) : (
                <div className="d-flex gap-2 justify-content-end">
                  <CButton
                    color="primary"
                    size="sm"
                    type="button"
                    onClick={() => {
                      formik.setValues({
                        streetName: address.streetName,
                        streetNumber: address.streetNumber,
                        city: address.city,
                        province: address.province,
                        zipCode: address.zipCode,
                      });

                      setEditRow(address.id);
                    }}
                  >
                    <Pencil />
                  </CButton>
                  <CButton
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={() =>
                      deleteMutation({
                        variables: {
                          id: address.id,
                          customerId: address.customerId,
                        },
                      })
                    }
                  >
                    <Trash />
                  </CButton>
                </div>
              )}
            </td>
          ),
        }}
        tableProps={{
          striped: true,
        }}
      />
    </CForm>
  );
};

export default forwardRef(CustomerAddresses);
