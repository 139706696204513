import {
  CCard,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react-pro";
import { useState } from "react";
import ProductSales from "./components/ProductSales";
import PlanAlert from "src/containers/PlanAlert";

import { AppLoader } from "src/components/Loader/Loader";
import WarehouseStockValue from "./components/WarehouseStockValue";
import SalesReport from "./components/SalesReport";
import ProductsBestSeller from "./components/ProductsBestSeller";
import BatchesPerWeek from "./components/BatchesPerWeek";
import { useUserStore } from "src/store/users";

const Reports = () => {
  const { currentCompany } = useUserStore();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const onTabChange = (newIndex: number) => {
    setCurrentTab(newIndex);
  };

  if (!currentCompany) {
    return <AppLoader />;
  }

  return (
    <>
      <PlanAlert />

      <CCard className="p-2">
        <CNav variant="tabs" role="tablist">
          <CNavItem>
            <CNavLink onClick={() => onTabChange(0)} active={currentTab === 0}>
              Ventas por Producto
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(1)} active={currentTab === 1}>
              Stock Valorizado
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(2)} active={currentTab === 2}>
              Egresos entre Fechas
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(3)} active={currentTab === 3}>
              Productos mas vendidos
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(4)} active={currentTab === 4}>
              Ingresos entre Fechas
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent className="px-1">
          <CTabPane visible={currentTab === 0}>
            <ProductSales companyId={currentCompany.id} />
          </CTabPane>
          <CTabPane visible={currentTab === 1}>
            <WarehouseStockValue />
          </CTabPane>
          <CTabPane visible={currentTab === 2}>
            <SalesReport />
          </CTabPane>
          <CTabPane visible={currentTab === 3}>
            <ProductsBestSeller />
          </CTabPane>
          <CTabPane visible={currentTab === 4}>
            <BatchesPerWeek />
          </CTabPane>
        </CTabContent>
      </CCard>
    </>
  );
};

export default Reports;
