import {
  AppVersion,
  GenericState,
  Nullable,
  User,
  UserWithToken,
} from "src/types";
import { Company, PaymentStatus } from "src/api/companies";
import { getCompanyPaymentStatus } from "src/helpers/payments";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { create } from "zustand";
import { customStorage } from "./utils";
import Config from "src/config";

export type UserState = GenericState & {
  user?: Nullable<UserWithToken>;
  users: User[];

  currentCompany: Company | null;

  setUser: (user: UserWithToken | null) => void;
  setUsers: (user: User[]) => void;

  setCurrentCompany: (company: Company) => void;

  hasPermission: (
    key: string,
    value?: { type?: "company" | "store"; id?: number; force?: boolean }
  ) => boolean;
};

export const usersInitialState = {
  user: null,
  users: [],

  currentCompany: null,
};

export const useUserStore = create<
  UserState,
  [["zustand/devtools", never], ["zustand/persist", UserState]]
>(
  devtools(
    persist(
      (set, get) => ({
        ...usersInitialState,

        setUser: (user: UserWithToken | null) =>
          set((state) => ({ ...state, user })),
        setUsers: (users: User[]) => set((state) => ({ ...state, users })),
        setCurrentCompany: (company: Company) =>
          set((state) => ({
            ...state,
            currentCompany: company,
          })),

        hasPermission: (
          key: string,
          value?: { type?: "company" | "store"; id?: number; force?: boolean }
        ) => {
          const user = get().user;
          const currentCompany = get().currentCompany;

          if (user?.isAdmin) {
            return true;
          }

          if (
            value?.force !== true &&
            getCompanyPaymentStatus(currentCompany?.paymentStatus) ===
              PaymentStatus.DEBTOR
          ) {
            return false;
          }

          const permissions = user?.permissions.filter(
            (permission) => permission.key === key
          );

          if (!permissions || permissions.length === 0) {
            return false;
          }

          if (value?.type) {
            const permissionValues = permissions
              .flatMap((permission) => JSON.parse(permission.value))
              .filter(
                (permission) =>
                  (value.type === "store" && permission.storeId) ||
                  (value.type === "company" && permission.companyId)
              )
              .map((permission) => ({
                ...permission,
                id: permission.storeId ?? permission.companyId ?? null,
              }));

            return permissionValues.some(
              (permission) => permission.id === value.id
            );
          }

          return !!permissions;
        },

        resetStore: () => set(() => usersInitialState),
      }),
      {
        name: `${Config.sessionKey}-users@${AppVersion}`,
        storage: createJSONStorage(() => customStorage),
      }
    )
  )
);
