import {
  CCol,
  CRow,
  CCardFooter,
  CForm,
  CFormInput,
  CFormSelect,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { CategorySchema, CreateSupplier } from "src/api/suppliers";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import ErrorMessage from "src/components/ErrorMessage";
import Api from "src/api";

import { GraphQLMeta } from "src/types";
import { Company } from "src/api/companies";
import { AppLoader } from "src/components/Loader/Loader";
import { logEvent } from "src/helpers/analytics";
import { getValidity } from "src/helpers/validation";
import { normalize, normalizeUtid } from "src/helpers/strings";
import { ChangeEvent } from "react";
import { useUserStore } from "src/store/users";

const Screen = () => {
  const { currentCompany } = useUserStore();
  const navigate = useNavigate();
  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const [mutation, { loading, error }] = useMutation(
    Api.Suppliers.CREATE_SUPPLIER,
    {
      onCompleted: () => {
        navigate(`/suppliers`);
      },
    }
  );

  const formik = useFormik<CreateSupplier>({
    initialValues: {
      companyId: currentCompany?.id ?? -1,
      name: "",
      notes: "",
      legalName: null,
      utid: null,
      contactEmail: null,
      contactPhone: null,
      contactName: null,
      email: null,
      phone: null,
    },
    onSubmit: (input: CreateSupplier) => {
      if (!loading) {
        const data = {
          companyId: Number(input.companyId),
          input: {
            name: input.name?.trim().toUpperCase(),
            notes: input.notes?.trim() ?? null,
            utid: normalizeUtid(input.utid),
            legalName: input.legalName,

            email: input.email ?? null,
            phone: input.phone ?? null,

            contactEmail: input.contactEmail ?? null,
            contactPhone: input.contactPhone ?? null,
            contactName: input.contactName ?? null,
          },
        };

        logEvent("supplier.create", {
          input: data,
        });

        mutation({
          variables: data,
        });
      }
    },
    validationSchema: CategorySchema,
  });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    formik.setFieldValue("identifier", normalize(value));
    formik.setFieldValue("name", value.toUpperCase());
  };

  if (!companies?.data.data) {
    return <AppLoader />;
  }

  if (companies?.data.data.length === 0) {
    navigate(`/suppliers`);
    return null;
  }

  return (
    <CCard>
      <CForm onSubmit={formik.handleSubmit}>
        <CCardHeader>Crear Proveedor</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="3">
              <CFormSelect
                id="companyId"
                floatingLabel="Empresa"
                disabled={(companies?.data.data.length ?? 0) <= 1}
                readOnly={(companies?.data.data.length ?? 0) <= 1}
                name="companyId"
                onChange={formik.handleChange}
                value={formik.values.companyId}
              >
                <option key="0" value="0">
                  Selecciona Empresa
                </option>
                {companies?.data?.data.map((company: Company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </CFormSelect>
            </CCol>

            <CCol md="3">
              <CFormInput
                id="name"
                placeholder="Nombre"
                name="name"
                floatingLabel="Nombre"
                onChange={onNameChange}
                value={formik.values.name}
                {...getValidity(formik.values.name, formik.errors.name)}
              />
            </CCol>

            <CCol md="3">
              <CFormInput
                id="legalName"
                placeholder="Razón Social"
                name="legalName"
                floatingLabel="Razón Social"
                onChange={formik.handleChange}
                defaultValue={formik.values.legalName ?? ""}
                {...getValidity(
                  formik.values.legalName,
                  formik.errors.legalName
                )}
              />
            </CCol>

            <CCol md="3">
              <CFormInput
                id="utid"
                placeholder="CUIL / CUIT"
                name="utid"
                floatingLabel="CUIL / CUIT"
                onChange={formik.handleChange}
                defaultValue={formik.values.utid ?? ""}
                {...getValidity(formik.values.utid, formik.errors.utid)}
              />
            </CCol>
          </CRow>

          <CRow className="mt-3">
            <CCol md="2">
              <CFormInput
                id="phone"
                placeholder="Teléfono"
                name="phone"
                type="phone"
                floatingLabel="Teléfono"
                onChange={formik.handleChange}
                defaultValue={formik.values.phone ?? ""}
                {...getValidity(formik.values.phone, formik.errors.phone)}
              />
            </CCol>

            <CCol md="3">
              <CFormInput
                id="email"
                placeholder="Email"
                name="email"
                type="email"
                floatingLabel="Email"
                onChange={formik.handleChange}
                defaultValue={formik.values.email ?? ""}
                {...getValidity(formik.values.email, formik.errors.email)}
              />
            </CCol>

            <CCol md="2">
              <CFormInput
                id="contactName"
                placeholder="Nombre del Contacto"
                name="contactName"
                floatingLabel="Nombre del Contacto"
                onChange={formik.handleChange}
                defaultValue={formik.values.contactName ?? ""}
                {...getValidity(
                  formik.values.contactName,
                  formik.errors.contactName
                )}
              />
            </CCol>

            <CCol md="3">
              <CFormInput
                id="contactEmail"
                placeholder="Email del Contacto"
                name="contactEmail"
                type="email"
                floatingLabel="Email del Contacto"
                onChange={formik.handleChange}
                defaultValue={formik.values.contactEmail ?? ""}
                {...getValidity(
                  formik.values.contactEmail,
                  formik.errors.contactEmail
                )}
              />
            </CCol>

            <CCol md="2">
              <CFormInput
                id="contactPhone"
                placeholder="Teléfono del Contacto"
                name="contactPhone"
                type="phone"
                floatingLabel="Teléfono del Contacto"
                onChange={formik.handleChange}
                defaultValue={formik.values.contactPhone ?? ""}
                {...getValidity(
                  formik.values.contactPhone,
                  formik.errors.contactPhone
                )}
              />
            </CCol>
          </CRow>

          <CRow className="mt-3">
            <CCol md={12}>
              <CFormInput
                id="notes"
                floatingLabel="Notas"
                placeholder="Notas"
                onChange={formik.handleChange}
                name="notes"
                defaultValue={formik.values.notes}
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow className="px-0">
            <CCol md="9">{error && <ErrorMessage />}</CCol>
            <CCol md="3" className="flex justify-content-end">
              <CLoadingButton
                color="primary"
                loading={loading}
                size="sm"
                disabled={loading}
                type="submit"
              >
                Crear
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CForm>
    </CCard>
  );
};

export default Screen;
