import { CCol, CRow, CWidgetStatsF } from "@coreui/react-pro";
import { Summary } from "src/api/dashboard";
import { useLazyQuery } from "@apollo/client";
import Api from "src/api";

import WidgetsDropdown from "./WidgetsDropdown";
import { AppLoader } from "src/components/Loader/Loader";
import { useFormik } from "formik";
import { logEvent } from "src/helpers/analytics";
import { dateFormat } from "src/helpers/dates";
import RangeAndStorePicker from "src/components/RangeAndStorePicker";
import PaymentMethodsIncomes from "./PaymentMethodsIncomes";
import { useEffect, useState } from "react";
import InvoicingDashboard from "./InvoicingDashboard";
import { sub } from "date-fns/sub";
import { getDateRangeErrorMessage } from "src/helpers/dashboard";
import { endOfDay, startOfDay } from "date-fns";
import CIcon from "@coreui/icons-react";
import { cilBank, cilCash } from "@coreui/icons";
import { formatCurrency } from "src/helpers/numbers";
import { useUserStore } from "src/store/users";

type CheckingBalance = {
  credit: number;
  debit: number;
};

const SalesDashboard = ({ today = false }: { today?: boolean }) => {
  const { hasPermission } = useUserStore();
  const [summary, setSummary] = useState<Summary>();
  const [taxAmount, setTaxAmount] = useState<number>();
  const [balance, setBalance] = useState<CheckingBalance>({
    debit: 0,
    credit: 0,
  });
  const canSeeSales = hasPermission("LIST_SALES", { force: true });

  const initialStartDate = dateFormat(new Date(), "yyyy-MM-dd", {
    remove: { months: 1 },
  });
  const initialEndDate = dateFormat(new Date(), "yyyy-MM-dd", {
    remove: { days: 1 },
  });

  const [refetchBalance] = useLazyQuery<{ data: CheckingBalance }>(
    Api.Dashboard.CHECKING_BALANCE,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ data }) => {
        setBalance(data);
      },
    }
  );

  const [refetchTax] = useLazyQuery<{ data: number }>(
    Api.Dashboard.TAX_AMOUNTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        dateFrom: initialStartDate,
        dateTo: today ? dateFormat(new Date()) : initialEndDate,
      },
      onCompleted: ({ data }) => {
        setTaxAmount(data);
      },
    }
  );

  const [refetch, { loading }] = useLazyQuery<{ data: Summary }>(
    Api.Dashboard.SUMMARY,
    {
      fetchPolicy: "no-cache",
      variables: {
        dateFrom: initialStartDate,
        dateTo: today ? dateFormat(new Date()) : initialEndDate,
      },
      onCompleted: ({ data }) => {
        setSummary((prev) => ({
          ...prev,
          ...data,
        }));
      },
    }
  );

  const formik = useFormik<{
    dateFrom: string;
    dateTo: string;
    storeId: number;
  }>({
    initialValues: {
      dateFrom: initialStartDate,
      dateTo: initialEndDate,
      storeId: 0,
    },
    onSubmit: (formData) => {
      const dateFrom = dateFormat(formData.dateFrom);
      const dateTo = formData.dateTo ? dateFormat(formData.dateTo) : "";

      logEvent("dashboard.filter", {
        filters: {
          dateFrom,
          dateTo,
          storeId: formData.storeId > 0 ? formData.storeId : undefined,
        },
      });

      const params = {
        dateFrom: today ? dateFormat(new Date()) : dateFrom,
        dateTo: today ? dateFormat(new Date()) : dateTo,
        storeId: formData.storeId > 0 ? formData.storeId : undefined,
      };

      refetch({
        variables: params,
      });

      refetchTax({
        variables: params,
      });
    },
  });

  useEffect(() => {
    const params = {
      dateFrom: today
        ? dateFormat(startOfDay(new Date()), "yyyy-MM-dd HH:mm:ss")
        : formik.values.dateFrom,
      dateTo: today
        ? dateFormat(endOfDay(new Date()), "yyyy-MM-dd HH:mm:ss")
        : formik.values.dateTo,
      storeId: formik.values.storeId,
    };

    refetch({
      variables: params,
    });

    refetchTax({
      variables: params,
    });

    refetchBalance({
      variables: {
        storeId: formik.values.storeId,
      },
    });
  }, [
    formik.values.dateFrom,
    formik.values.dateTo,
    formik.values.storeId,
    refetch,
    refetchBalance,
    refetchTax,
    today,
  ]);

  if (!summary) {
    return <AppLoader className="mt-4" />;
  }

  const { incomesPerMethod } = summary ?? ({ incomesPerMethod: {} } as Summary);
  const hasIncomes = Object.entries(incomesPerMethod).some(
    ([_, value]) => value > 0
  );

  incomesPerMethod["IVA"] = taxAmount;

  const noData =
    !today && summary.sales === 0 && summary.money.every((p) => p.amount === 0);
  const noDataToday =
    today && summary.sales === 0 && summary.money.every((p) => p.amount === 0);

  return (
    <>
      <CRow className="mb-3">
        <RangeAndStorePicker
          hideRangePicker={today}
          maxDate={today ? new Date() : sub(new Date(), { days: 1 })}
          refetch={(props) => {
            if (props.variables.dateFrom) {
              formik.setFieldValue("dateFrom", props.variables.dateFrom);
            }

            if (props.variables.dateTo) {
              formik.setFieldValue("dateTo", props.variables.dateTo);
            }

            if (props.variables.storeId) {
              formik.setFieldValue("storeId", props.variables.storeId);
            }

            refetch(props);
            refetchTax(props);
            refetchBalance({
              variables: {
                storeId: props.variables.storeId,
              },
            });
          }}
          eventTitle="dashboard.filters"
          defaultDateFrom={formik.values.dateFrom}
          defaultDateTo={formik.values.dateTo}
        />
      </CRow>

      {loading ? (
        <AppLoader />
      ) : (
        <>
          {!noData && !noDataToday ? (
            <>
              <WidgetsDropdown
                {...summary}
                checkingAccount={incomesPerMethod.CheckingAccount}
              />

              {hasIncomes && (
                <PaymentMethodsIncomes incomes={incomesPerMethod} />
              )}
            </>
          ) : noDataToday ? (
            <div className="text-center">
              <h2 className="px-4">No hay datos para el día de hoy</h2>
            </div>
          ) : (
            <div className="text-center mt-5">
              <h2 className="px-4">
                {getDateRangeErrorMessage(
                  new Date(formik.values.dateFrom),
                  new Date(formik.values.dateTo)
                )}
              </h2>
            </div>
          )}
        </>
      )}

      {today && (
        <>
          <hr />
          <CRow>
            <CCol sm={5}>
              <InvoicingDashboard storeId={formik.values.storeId} />
            </CCol>
            <CCol sm={7}>
              <CRow className="mt-3">
                <CCol sm={6}>
                  {balance.debit < 0 && canSeeSales && (
                    <CWidgetStatsF
                      className="mb-3 no-inner-padding"
                      color="danger"
                      icon={<CIcon icon={cilCash} height={24} />}
                      padding={false}
                      title="Saldo Deudor"
                      value={formatCurrency(balance.debit)}
                    />
                  )}

                  {balance.credit > 0 && canSeeSales && (
                    <CWidgetStatsF
                      className="mb-3 no-inner-padding"
                      color="success"
                      icon={<CIcon icon={cilBank} height={24} />}
                      padding={false}
                      title="Saldo Acreedor"
                      value={formatCurrency(balance.credit)}
                    />
                  )}
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

export default SalesDashboard;
