import { PaymentMethod, Sale, SaleItem } from "src/api/sales";
import { fixedSum, formatCurrency, getTaxValue } from "src/helpers/numbers";
import { parsePaymentMethods } from "src/helpers/payments";

export const useSale = () => {
  const calculateItem = (
    item: Pick<
      SaleItem,
      "productId" | "price" | "tax" | "recharge" | "discount" | "quantity"
    >
  ) => {
    const tax = getTaxValue(item.price, item.tax);
    const recharge = Number(item.recharge);
    const discount = Number(item.discount);

    const response = {
      price: item.price - tax,
      recharge,
      discount,
      tax,
      total: item.quantity * (item.price - discount + recharge),
    };

    return response;
  };

  const calculateSummary = (
    sale: Pick<Sale, "discount" | "customerDiscount"> & {
      items: Pick<
        SaleItem,
        "productId" | "price" | "tax" | "recharge" | "discount" | "quantity"
      >[];
    },
    fee: number = 0
  ) => {
    const items = sale.items.map((item) => ({
      ...item,
      summary: calculateItem(item),
    }));

    const response = items.reduce(
      (prev, curr) => ({
        subtotal: prev.subtotal + fixedSum(curr.summary.price * curr.quantity),
        recharge: prev.recharge + curr.summary.recharge,
        saleDiscount: sale.discount,
        itemsDiscount:
          prev.itemsDiscount + fixedSum(curr.summary.discount * curr.quantity),
        customerDiscount: sale.customerDiscount,
        tax: prev.tax + fixedSum(curr.summary.tax * curr.quantity),
        total: Number(prev.total) + Number(curr.summary.total),
      }),
      {
        subtotal: 0,
        recharge: 0,
        saleDiscount: 0,
        itemsDiscount: 0,
        customerDiscount: 0,
        tax: 0,
        total: 0,
      }
    );

    return {
      ...response,
      customerDiscount: sale.customerDiscount,
      rest: fixedSum(
        response.subtotal +
          response.tax -
          response.itemsDiscount -
          response.saleDiscount -
          fee -
          sale.customerDiscount
      ),
      total: fixedSum(
        response.subtotal +
          response.tax -
          response.itemsDiscount -
          response.saleDiscount -
          fee -
          sale.customerDiscount +
          response.recharge
      ),
    };
  };

  return {
    calculateItem,
    calculateSummary,
  };
};

export const useSaleMetadata = () => {
  const { calculateSummary } = useSale();
  const generateSaleMetaData = (
    sale: Pick<Sale, "payments" | "discount" | "customerDiscount"> & {
      items: Pick<
        SaleItem,
        "productId" | "price" | "tax" | "recharge" | "discount" | "quantity"
      >[];
    }
  ) => {
    const fee = sale.payments?.find((p) => p.type === PaymentMethod.Fee);
    const summary = calculateSummary(sale, fee?.amount ?? 0);

    const items: any[] = [
      {
        left: `Métodos de pago: ${parsePaymentMethods(sale.payments)}`,
        right: {
          label: "Subtotal",
          value: formatCurrency(summary.subtotal),
        },
      },
    ];

    items.push({
      right: {
        label: "IVA",
        value: formatCurrency(summary.tax),
      },
    });

    if (summary.recharge > 0) {
      items.push({
        right: {
          label: `Recargo`,
          value: formatCurrency(summary.recharge),
        },
      });
    }

    if (summary.itemsDiscount > 0) {
      items.push({
        right: {
          label: `Descuento en Items`,
          value: `- ${formatCurrency(summary.itemsDiscount)}`,
        },
      });
    }

    if (summary.customerDiscount > 0) {
      items.push({
        right: {
          label: `Descuento de Cliente`,
          value: `- ${formatCurrency(summary.customerDiscount)}`,
        },
      });
    }

    if (summary.saleDiscount > 0) {
      items.push({
        right: {
          label: `Descuento ${summary.saleDiscount > 0 ? " en Pago" : ""}`,
          value: `- ${formatCurrency(summary.saleDiscount)}`,
        },
      });
    }

    if (fee) {
      items.push({
        right: {
          label: `Comision`,
          value: `- ${formatCurrency(fee.amount)}`,
        },
      });
    }

    items.push({
      right: {
        label: "Total",
        value: formatCurrency(summary.total),
      },
    });

    return items;
  };

  return generateSaleMetaData;
};
