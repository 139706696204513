import { useMutation } from "@apollo/client";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Api from "src/api";
import { SendInvoiceEmailSchema } from "src/api/afip";
import { Customer } from "src/api/customers";
import { SaleInvoice } from "src/api/sales";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { logEvent } from "src/helpers/analytics";
import { ForwardedModalProps } from "src/types";

const SendInvoiceModal: ForwardRefRenderFunction<
  ForwardedModalProps,
  { customer?: Customer; refetch?: () => void }
> = ({ customer, refetch }, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const toastRef = useRef<ForwardedToastProps>(null);

  const [mutation, { loading }] = useMutation(Api.Sales.SEND_INVOICE, {
    onCompleted: () => {
      toastRef.current?.show();
      refetch?.();

      setTimeout(() => {
        handleDismiss();
      }, 3000);
    },
  });

  const formik = useFormik({
    initialValues: {
      cae: "",
      type: "SaleInvoice",
      email: customer?.email ?? "",
    },
    validationSchema: SendInvoiceEmailSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      if (!loading) {
        logEvent("sale.send-invoice-email", {
          values: {
            cae: values.cae,
            type: values.type,
            emails: [values.email],
          },
        });

        mutation({
          variables: {
            cae: values.cae,
            type: values.type,
            emails: [values.email],
          },
        });
      }
    },
  });

  const handleDismiss = useCallback(() => {
    setVisible(false);
    formik.resetForm();
  }, [formik]);

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        handleDismiss();
      },
      open: (s: SaleInvoice) => {
        setVisible(true);

        formik.setValues({
          cae: s.afipInvoice.cae,
          type: "SaleInvoice",
          email: customer?.email ?? "",
        });
      },
    }),
    [customer?.email, formik, handleDismiss]
  );

  return (
    <CModal alignment="center" visible={visible} onClose={handleDismiss}>
      <CModalHeader closeButton>Enviar por Email</CModalHeader>
      <CModalBody>
        <CForm>
          <CRow>
            <CCol>
              <CFormInput
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                floatingLabel="Email"
              />
            </CCol>
          </CRow>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton size="sm" color="secondary" onClick={() => handleDismiss()}>
          Cancelar
        </CButton>
        <CLoadingButton
          size="sm"
          loading={loading}
          disabled={!formik.isValid}
          color="success"
          onClick={() => formik.handleSubmit()}
        >
          Enviar
        </CLoadingButton>
      </CModalFooter>

      <Toast ref={toastRef} text="Email enviado correctamente" />
    </CModal>
  );
};

export default forwardRef(SendInvoiceModal);
