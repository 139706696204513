import { useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CRow,
  CSmartTable,
  CTooltip,
} from "@coreui/react-pro";
import classNames from "classnames";
import { differenceInDays } from "date-fns";
import {
  CheckIcon,
  CloudDownloadIcon,
  CloudUploadIcon,
  XIcon,
} from "lucide-react";
import { useRef, useState } from "react";
import Api from "src/api";
import { AfipCertificate } from "src/api/afip";
import { logEvent } from "src/helpers/analytics";
import { dateFormat } from "src/helpers/dates";
import { GraphQLFind } from "src/types";

const Certificates = () => {
  const certificateInputRef = useRef<HTMLInputElement>(null);
  const [downloaded, setDownloaded] = useState<number>(0);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { data: certificates, loading } = useQuery<
    GraphQLFind<AfipCertificate[]>
  >(Api.Afip.LIST_CERTIFICATES);

  const downloadCertificate = async (id: number) => {
    if (!downloading) {
      setDownloading(true);

      try {
        const response = await Api.Afip.downloadCertificate(id);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", response.fileName);

        document.body.appendChild(link);

        link.click();
        link.remove();

        setDownloaded(id);
      } catch (e) {
        logEvent("afip.download.error", {
          error: (e as Error).message,
        });
      } finally {
        setDownloading(false);
      }
    }
  };

  const importCertificate = async (e) => {
    if (
      !uploading &&
      e.target.value !== "" &&
      certificateInputRef.current?.files?.[0] &&
      downloaded > 0
    ) {
      setUploading(true);

      try {
        const formData = new FormData();

        formData.append("certificate", certificateInputRef.current.files[0]);
        formData.append("id", downloaded.toString());

        await Api.Afip.uploadCertificate(formData);

        alert("Certificado cargado correctamente");

        window.location.reload();
      } catch (e) {
        logEvent("afip.upload.error", {
          error: (e as Error).message,
        });
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={9} xs="6" className="px-0">
                  Certificados AFIP
                </CCol>
                <CCol sm={3} xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={certificates?.data.length ?? 20}
                loading={loading}
                items={certificates?.data}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "utid", label: "CUIL / CUIT" },
                  {
                    key: "generatedAt",
                    label: "Fecha de Generacion",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "expiresAt",
                    label: "Fecha de Expiracion",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "createdAt",
                    label: "Fecha de Carga",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "active",
                    label: "Activo",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "actions",
                    label: "Acciones",
                    _props: { className: "text-right" },
                  },
                ]}
                tableProps={{
                  striped: true,
                }}
                scopedColumns={{
                  generatedAt: (item: AfipCertificate) => (
                    <td className="text-center">
                      {dateFormat(item.generatedAt, "dd/MM/yyyy HH:mm")}
                    </td>
                  ),
                  active: (item: AfipCertificate) => (
                    <td className="text-center">
                      {item.active ? <CheckIcon /> : <XIcon />}
                    </td>
                  ),
                  expiresAt: (item: AfipCertificate) => {
                    const diffInDays = differenceInDays(
                      item.expiresAt,
                      new Date()
                    );
                    const isExpired = diffInDays <= 0;
                    const nearToExpire = diffInDays <= 30;

                    return (
                      <td className="text-center">
                        {dateFormat(item.expiresAt, "dd/MM/yyyy HH:mm")}

                        {item.active && (
                          <small
                            className={classNames("ml-2", {
                              "text-danger": isExpired,
                              "text-warning": nearToExpire,
                              "text-success": !isExpired && !nearToExpire,
                            })}
                          >
                            {isExpired
                              ? "Expirado"
                              : `Quedan ${diffInDays} días`}
                          </small>
                        )}
                      </td>
                    );
                  },
                  createdAt: (item: AfipCertificate) => (
                    <td className="text-center">
                      {dateFormat(item.createdAt, "dd/MM/yyyy HH:mm")}
                    </td>
                  ),
                  actions: (item: AfipCertificate) => {
                    const diffInDays = differenceInDays(
                      item.expiresAt,
                      new Date()
                    );
                    const isExpired = diffInDays <= 0;
                    const nearToExpire = diffInDays <= 30;

                    if ((!isExpired && !nearToExpire) || !item.active) {
                      return <td />;
                    }

                    return (
                      <td className="text-right">
                        <div className="d-flex justify-content-end">
                          <CTooltip
                            content={<span>Descargar Archivo .csr</span>}
                          >
                            <CButton
                              size="sm"
                              color="info"
                              disabled={downloading}
                              onClick={() => {
                                if (!downloading) {
                                  downloadCertificate(item.id);
                                }
                              }}
                            >
                              <CloudDownloadIcon />
                            </CButton>
                          </CTooltip>

                          {downloaded > 0 && (
                            <CTooltip
                              content={<span>Cargar Certificado AFIP</span>}
                            >
                              <CButton
                                size="sm"
                                className="ml-2"
                                color="primary"
                                onClick={() => {
                                  certificateInputRef.current?.click();
                                }}
                              >
                                <CloudUploadIcon />
                              </CButton>
                            </CTooltip>
                          )}
                        </div>
                      </td>
                    );
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CForm>
        <input
          type="file"
          style={{ display: "none" }}
          ref={certificateInputRef}
          onChange={importCertificate}
          accept=".crt"
        />
      </CForm>
    </>
  );
};

export default Certificates;
