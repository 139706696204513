import {
  CHeader,
  CContainer,
  CSidebarBrand,
  CHeaderToggler,
  CHeaderNav,
} from "@coreui/react-pro";
import { memo } from "react";
import AppBreadcrumb from "./AppBreadcrumb";
import AppHeaderDropdown from "./AppHeaderDropdown";
import CompanyDropdown from "./CompanyDropdown";
import { NavLink } from "react-router";
import classNames from "classnames";
import { PanelLeftOpen, PanelRightOpen } from "lucide-react";
import Help from "./Help";
import { useLayoutStore } from "src/store/layout";

const TheHeader = () => {
  const { isCollapsed, setIsCollapsed } = useLayoutStore();

  return (
    <CHeader position="sticky" className={classNames("w-100 no-print")}>
      <CContainer className="mx-0 w-100">
        <CHeaderToggler
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="ps-1 align-items-center d-flex"
        >
          {isCollapsed ? <PanelLeftOpen /> : <PanelRightOpen />}
        </CHeaderToggler>

        <CSidebarBrand
          as={NavLink}
          to="/home"
          className="d-flex justify-content-center flex-2 web-hidden"
        >
          <img alt="Daruma Logo" src="avatars/default.png" height="38" />
        </CSidebarBrand>

        <div className="flex mobile-hidden">
          <AppBreadcrumb />
        </div>

        <CHeaderNav className="ps-1 gap-2 d-flex flex-1 align-items-center justify-content-end">
          <CompanyDropdown />
          <Help />
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default memo(TheHeader);
