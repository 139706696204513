import { forwardRef } from "react";
import {
  CCol,
  CRow,
  CCardFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { CategorySchema, CreateCategory } from "src/api/categories";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import ErrorMessage from "src/components/ErrorMessage";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { GraphQLMeta } from "src/types";
import { Company } from "src/api/companies";
import { AppLoader } from "src/components/Loader/Loader";
import { normalize } from "src/helpers/strings";
import { logEvent } from "src/helpers/analytics";
import { useUserStore } from "src/store/users";

const Screen = () => {
  const navigate = useNavigate();
  const { currentCompany } = useUserStore();
  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );
  const [mutation, { error, loading }] = useMutation(
    Api.Categories.CREATE_CATEGORY,
    {
      onCompleted: () => {
        navigate(`/categories`);
      },
    }
  );

  const onNameChange = (e) => {
    const { value } = e.currentTarget;

    formik.setFieldValue("identifier", normalize(value));
    formik.setFieldValue("name", value.toUpperCase());
  };

  const formik = useFormik<CreateCategory>({
    initialValues: {
      companyId: currentCompany?.id ?? 0,
      name: "",
      identifier: "",
      notes: "",
    },
    onSubmit: (input) => {
      if (!loading) {
        const data = {
          companyId: Number(input.companyId),
          name: input.name?.trim().toUpperCase(),
          identifier: input.identifier ?? normalize(input.name),
          notes: input.notes.trim() ?? null,
        };

        logEvent("category.create", {
          input: data,
        });

        mutation({
          variables: data,
        });
      }
    },
    validationSchema: CategorySchema,
  });

  if (!companies?.data.data) {
    return <AppLoader />;
  }

  if (companies?.data.data.length === 0) {
    navigate(`/categories`);
    return null;
  }

  return (
    <CCard>
      <CForm onSubmit={formik.handleSubmit}>
        <CCardHeader>Crear Categoría</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12">
              <CRow className="mt-3">
                <CCol md="3">
                  <CFormLabel htmlFor="companyId">Empresa</CFormLabel>
                </CCol>

                <CCol xs="12" md="9">
                  <CFormSelect
                    id="companyId"
                    disabled={(companies?.data.data.length ?? 0) <= 1}
                    readOnly={(companies?.data.data.length ?? 0) <= 1}
                    name="companyId"
                    onChange={formik.handleChange}
                    value={formik.values.companyId}
                  >
                    <option key="0" value="0">
                      Selecciona Empresa
                    </option>
                    {companies?.data?.data.map((company: Company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3">
                  <CFormLabel htmlFor="name">Nombre</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="name"
                    placeholder="Nombre"
                    name="name"
                    onChange={onNameChange}
                    value={formik.values.name}
                    {...getValidity(formik.values.name, formik.errors.name)}
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3">
                  <CFormLabel htmlFor="identifier">Identificador</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="identifier"
                    placeholder="Identificador"
                    name="identifier"
                    disabled
                    value={formik.values.identifier ?? ""}
                    readOnly
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3">
                  <CFormLabel htmlFor="notes">Notas</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="notes"
                    placeholder="Notas"
                    onChange={formik.handleChange}
                    name="notes"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow className="px-0">
            <CCol md="9">
              {error && (
                <ErrorMessage text="Los datos ingresados son incorrectos" />
              )}
            </CCol>
            <CCol md="3" className="flex justify-content-end">
              <CLoadingButton
                color="primary"
                loading={loading}
                size="sm"
                disabled={loading}
                type="submit"
              >
                Crear
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CForm>
    </CCard>
  );
};

export default forwardRef(Screen);
