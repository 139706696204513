import { useMutation } from "@apollo/client";
import {
  CCol,
  CRow,
  CFormInput,
  CFormLabel,
  CFormSwitch,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { useNavigate } from "react-router";
import Api from "src/api";
import { CompanyUpdate } from "src/api/companies";
import { getValidity } from "src/helpers/validation";

const FormTab = ({ company, onLoading }, ref) => {
  const navigate = useNavigate();

  const [updateMutation, { loading: updating }] = useMutation(
    Api.Companies.UPDATE_COMPANY,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [deleteMutation, { loading: deleting }] = useMutation(
    Api.Companies.DELETE_COMPANY,
    {
      onCompleted: () => {
        navigate("/companies");
      },
    }
  );

  const onDelete = useCallback(() => {
    if (!deleting && !updating) {
      deleteMutation({
        variables: {
          id: company.id,
        },
      });
    }
  }, [company.id, deleting, deleteMutation, updating]);

  const formik = useFormik<CompanyUpdate>({
    initialValues: company,
    onSubmit: (input) => {
      if (!deleting && !updating) {
        updateMutation({
          variables: {
            id: company.id,
            input: {
              name: input.name.trim(),
              legalName: input.legalName.trim(),
              streetName: input.streetName.trim(),
              streetNumber: input.streetNumber.trim(),
              province: input.province.trim(),
              city: input.city.trim(),
              zipCode: input.zipCode.trim(),
              description: input.description?.trim(),
              isFranchise:
                typeof input.isFranchise === "string"
                  ? input.isFranchise === "1"
                  : input.isFranchise,
              utid: input.utid?.trim(),
            },
          },
        });
      }
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: () => formik.handleSubmit(),
      delete: () => onDelete(),
    }),
    [formik, onDelete]
  );

  useEffect(() => {
    onLoading(deleting);
  }, [deleting, onLoading]);

  useEffect(() => {
    onLoading(updating);
  }, [updating, onLoading]);

  return (
    <CCol xs="12">
      <CRow>
        <CCol sm={3}>
          <CFormInput
            id="name"
            placeholder="Nombre"
            defaultValue={formik.values.name}
            name="name"
            floatingLabel="Nombre"
            onChange={formik.handleChange}
            {...getValidity(formik.values.name, formik.errors.name)}
          />
        </CCol>

        <CCol sm={3}>
          <CFormInput
            id="legalName"
            placeholder="Razón Social"
            defaultValue={formik.values.legalName}
            name="legalName"
            floatingLabel="Razón Social"
            onChange={formik.handleChange}
            {...getValidity(formik.values.legalName, formik.errors.legalName)}
          />
        </CCol>

        <CCol sm={3}>
          <CFormInput
            id="utid"
            placeholder="CUIT"
            defaultValue={formik.values.utid}
            name="utid"
            floatingLabel="CUIT"
            onChange={formik.handleChange}
            {...getValidity(formik.values.utid, formik.errors.utid)}
          />
        </CCol>

        <CCol sm={3}>
          <CFormLabel htmlFor="isFranchise">¿Es franquicia?</CFormLabel>
          <CFormSwitch
            id="isFranchise"
            defaultChecked={formik.values.isFranchise}
            name="isFranchise"
            onChange={formik.handleChange}
            {...getValidity(
              formik.values.isFranchise,
              formik.errors.isFranchise
            )}
          />
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CCol sm={3}>
          <CFormInput
            id="streetName"
            placeholder="Calle"
            defaultValue={formik.values.streetName}
            name="streetName"
            floatingLabel="Calle"
            onChange={formik.handleChange}
            {...getValidity(formik.values.streetName, formik.errors.streetName)}
          />
        </CCol>

        <CCol sm={1}>
          <CFormInput
            id="streetNumber"
            placeholder="Altura"
            defaultValue={formik.values.streetNumber}
            name="streetNumber"
            floatingLabel="Altura"
            onChange={formik.handleChange}
            {...getValidity(
              formik.values.streetNumber,
              formik.errors.streetNumber
            )}
          />
        </CCol>

        <CCol sm={3}>
          <CFormInput
            id="city"
            placeholder="Ciudad"
            defaultValue={formik.values.city}
            name="city"
            floatingLabel="Ciudad"
            onChange={formik.handleChange}
            {...getValidity(formik.values.city, formik.errors.city)}
          />
        </CCol>

        <CCol sm={2}>
          <CFormInput
            id="province"
            placeholder="Provincia"
            defaultValue={formik.values.province}
            name="province"
            floatingLabel="Provincia"
            onChange={formik.handleChange}
            {...getValidity(formik.values.province, formik.errors.province)}
          />
        </CCol>

        <CCol sm={1}>
          <CFormInput
            id="zipCode"
            placeholder="CP"
            defaultValue={formik.values.zipCode}
            name="zipCode"
            floatingLabel="CP"
            onChange={formik.handleChange}
            {...getValidity(formik.values.zipCode, formik.errors.zipCode)}
          />
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CCol sm={6}>
          <CFormInput
            id="description"
            placeholder="Descripción"
            defaultValue={formik.values.description}
            name="description"
            floatingLabel="Descripción"
            onChange={formik.handleChange}
            {...getValidity(
              formik.values.description,
              formik.errors.description
            )}
          />
        </CCol>
      </CRow>
    </CCol>
  );
};

export default forwardRef(FormTab);
