import { useMutation } from "@apollo/client";
import {
  CButton,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import Api from "src/api";
import yup from "src/helpers/validation";

export type ConnectRappiModalProps = {
  open: () => void;
  close: () => void;
};

const ConnectRappiModal: ForwardRefRenderFunction<
  ConnectRappiModalProps,
  { storeId: number }
> = ({ storeId }, ref) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mutation] = useMutation(Api.Stores.SET_METADATA, {
    onCompleted: () => {
      setShowModal(false);
      formik.resetForm();

      window.location.reload();
    },
  });

  const formik = useFormik({
    initialValues: {
      rappiStoreId: "",
      rappiClientId: "",
      rappiClientSecret: "",
      rappiApiKey: "",
    },
    onSubmit: (data) => {
      mutation({
        variables: {
          id: storeId,
          input: [
            {
              key: "rappiStoreId",
              value: data.rappiStoreId,
            },
            {
              key: "rappiClientId",
              value: data.rappiClientId,
            },
            {
              key: "rappiClientSecret",
              value: data.rappiClientSecret,
            },
            {
              key: "rappiApiKey",
              value: data.rappiApiKey,
            },
          ],
        },
      });
    },
    validationSchema: yup.object().shape({
      rappiStoreId: yup.string().min(3).required(),
      rappiClientId: yup.string().min(5).required(),
      rappiClientSecret: yup.string().min(5).required(),
      rappiApiKey: yup.string().min(5).required(),
    }),
    validateOnMount: true,
  });

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setShowModal(true);
      },
      close: () => {
        setShowModal(false);
        formik.resetForm();
      },
    }),
    [formik]
  );

  return (
    <CModal
      alignment="center"
      size="lg"
      visible={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      backdrop="static"
      keyboard={false}
    >
      <CModalHeader closeButton={false}>
        Conectar Punto de Venta con Rappi
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CFormLabel htmlFor="rappiStoreId">ID de Tienda de Rappi</CFormLabel>
          <CInputGroup className="mb-3">
            <CFormInput
              name="rappiStoreId"
              placeholder="12345422"
              onChange={formik.handleChange}
            />
          </CInputGroup>
        </CRow>
        <CRow>
          <CFormLabel htmlFor="rappiClientId">Client ID de Rappi</CFormLabel>
          <CInputGroup className="mb-3">
            <CFormInput
              name="rappiClientId"
              placeholder="dsaggds232-asdase"
              onChange={formik.handleChange}
            />
          </CInputGroup>
        </CRow>
        <CRow>
          <CFormLabel htmlFor="rappiClientSecret">
            Client Secret de Rappi
          </CFormLabel>
          <CInputGroup className="mb-3">
            <CFormInput
              name="rappiClientSecret"
              placeholder="asdase-das43232d"
              onChange={formik.handleChange}
            />
          </CInputGroup>
        </CRow>
        <CRow>
          <CFormLabel htmlFor="rappiApiKey">API Key de Rappi</CFormLabel>
          <CInputGroup className="mb-3">
            <CFormInput
              name="rappiApiKey"
              placeholder="asdase-das43232d"
              onChange={formik.handleChange}
            />
          </CInputGroup>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton
          size="sm"
          type="button"
          color="secondary"
          onClick={() => {
            setShowModal(false);
            formik.resetForm();
          }}
        >
          Cancelar
        </CButton>
        <CButton
          size="sm"
          type="button"
          color="success"
          disabled={!formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          Continuar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(ConnectRappiModal);
