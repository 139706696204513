import { useCallback } from "react";
import { useLayoutStore } from "src/store/layout";

export const useFeatureFlags = () => {
  const { flags } = useLayoutStore();

  const hasFlag = useCallback((flag: string) => flags[flag] ?? false, [flags]);

  return {
    hasFlag,
  };
};
