import { RetireType } from "src/api/retires";

export const retireTypes = [
  {
    id: 2,
    name: "Movimiento",
    type: RetireType.Move,
    identifier: "COUNT",
  },
  {
    id: 3,
    name: "Venta",
    type: RetireType.Sale,
    identifier: "RETURN",
  },
  {
    id: 4,
    name: "Otro",
    type: RetireType.Other,
    identifier: "INVOICE",
  },
];

export const findRetireType = (type: RetireType) =>
  retireTypes.find((rType) => rType.type === type);
