import { Customer, CustomerAddress } from "src/api/customers";

const SaleShipmentAddress = ({
  customer,
  shippingAddress,
}: {
  customer?: Customer;
  shippingAddress?: CustomerAddress;
}) => {
  if (!customer) {
    return null;
  }

  if (!shippingAddress) {
    return (
      <span>
        Dirección de Entrega: {customer.streetName} {customer.streetNumber},{" "}
        {customer.city}, {customer.province}, CP: {customer.zipCode}
      </span>
    );
  }

  return (
    <span>
      Dirección de Entrega: {shippingAddress.streetName}{" "}
      {shippingAddress.streetNumber}, {shippingAddress.city},{" "}
      {shippingAddress.province}, CP: {shippingAddress.zipCode}
    </span>
  );
};

export default SaleShipmentAddress;
