import { Link, useNavigate } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
  CTooltip,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { PriceList, PriceListSchema } from "src/api/price-lists";
import { dateFormat } from "src/helpers/dates";
import { GraphQLMeta } from "src/types";
import { usePagination } from "src/hooks/pagination";
import Api from "src/api";
import { useState } from "react";
import { useFormik } from "formik";
import { Store, StoreType } from "src/api/stores";
import { logEvent } from "src/helpers/analytics";
import FeatureAlert from "src/containers/FeatureAlert";
import Pagination from "src/components/Pagination";
import { findPointOfSale } from "src/helpers/stores";
import DuplicateModal from "./components/DuplicateModal";
import { Copy, Pencil } from "lucide-react";
import { useUserStore } from "src/store/users";
import { haveCompanyPlans } from "src/helpers/plans";
import { PlanType } from "src/api/plans";

const PriceLists = () => {
  const { hasPermission, currentCompany } = useUserStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const canSeePriceList = hasPermission("SHOW_PRICE_LIST");
  const canCreatePriceList =
    hasPermission("CREATE_PRICE_LIST") &&
    haveCompanyPlans(currentCompany, [
      PlanType.Free,
      PlanType.Pro,
      PlanType.Plus,
    ]);
  const navigate = useNavigate();
  const { page, pageChange } = usePagination("price-lists");
  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
          types: [StoreType.Store, StoreType.Digital],
        },
      },
    }
  );
  const { data: priceLists, loading } = useQuery<GraphQLMeta<PriceList>>(
    Api.PriceLists.LIST_PRICE_LISTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page,
        },
      },
    }
  );
  const [mutation, { loading: loadingMutation }] = useMutation(
    Api.PriceLists.DUPLICATE_PRICE_LIST_ITEM,
    {
      onCompleted: ({ data }) => {
        navigate(`/price-lists/${data.id}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      priceListId: 0,
      profit: 0,
      storeId: 0,
      name: "",
      dateFrom: "",
      dateTo: "",
    },
    onSubmit: async ({ priceListId, ...values }) => {
      if (!loadingMutation) {
        const input = {
          ...values,
          profit: Math.abs(Number(values.profit)),
          storeId: Number(values.storeId),
        };

        logEvent("price-lists.duplicate", {
          data: {
            id: priceListId,
            input,
          },
        });

        mutation({
          variables: {
            id: priceListId,
            input,
          },
        });
      }
    },
    validateOnChange: true,
    validationSchema: PriceListSchema,
  });

  const onDuplicate = (id: number) => {
    formik.setFieldValue("priceListId", id);
    setShowModal(true);
  };

  return (
    <>
      <FeatureAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={6} className="px-0">
                  Listas de Precios
                </CCol>
                <CCol sm={6} className="row justify-content-end">
                  {canCreatePriceList && (
                    <Link
                      to="/price-lists/new"
                      className="flex justify-content-end p-0"
                    >
                      <CButton
                        size="sm"
                        color="primary"
                        onClick={() => {
                          logEvent("price-lists.create");
                        }}
                      >
                        Crear lista de precios
                      </CButton>
                    </Link>
                  )}
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={priceLists?.data.data || []}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  { key: "store", label: "Punto de Venta" },
                  { key: "dateFrom", label: "Fecha de Inicio" },
                  { key: "dateTo", label: "Fecha de Fin" },
                  {
                    key: "updatedAt",
                    label: "Última Actualización",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "actions",
                    label: "Acciones",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{
                  store: (item: PriceList) => {
                    const currentType = findPointOfSale(item.store.type);
                    const StoreIcon = () => currentType?.icon;

                    return (
                      <td>
                        <span className="d-flex">
                          <StoreIcon />
                          <span className="ml-2">{item.store?.name}</span>
                        </span>
                      </td>
                    );
                  },
                  dateFrom: (item: PriceList) => (
                    <td>{dateFormat(item.dateFrom, "dd/MM/yyyy")}</td>
                  ),
                  dateTo: (item: PriceList) => (
                    <td>
                      {item.dateTo
                        ? dateFormat(item.dateTo, "dd/MM/yyyy")
                        : "-"}
                    </td>
                  ),
                  actions: (item: PriceList) => (
                    <td className="text-right">
                      <div className="d-flex justify-content-end">
                        {canCreatePriceList && item.editable && (
                          <CTooltip
                            content={<span>Duplicar lista de precios</span>}
                          >
                            <CButton
                              onClick={() => onDuplicate(item.id)}
                              type="button"
                              color="info"
                              size="sm"
                            >
                              <Copy />
                            </CButton>
                          </CTooltip>
                        )}

                        {canSeePriceList && (
                          <CTooltip
                            content={<span>Editar lista de precios</span>}
                          >
                            <CButton
                              color="primary"
                              size="sm"
                              onClick={() =>
                                navigate(`/price-lists/${item.id}`)
                              }
                              type="button"
                              className="ml-2"
                            >
                              <Pencil color="white" />
                            </CButton>
                          </CTooltip>
                        )}
                      </div>
                    </td>
                  ),
                  updatedAt: (item: PriceList) => (
                    <td className="text-right">
                      {dateFormat(
                        new Date(
                          item.store.type !== StoreType.Store && item.lastSyncAt
                            ? item.lastSyncAt
                            : item.updatedAt
                        ),
                        "dd/MM/yyyy HH:mm"
                      )}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />

              <Pagination
                meta={priceLists}
                page={page}
                pageChange={pageChange}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <DuplicateModal
        onDismiss={() => setShowModal(false)}
        formik={formik}
        visible={showModal}
        stores={stores?.data.data ?? []}
      />
    </>
  );
};

export default PriceLists;
