import { gql } from "@apollo/client";
import yup from "src/helpers/validation";
import { WithTrash } from "src/types";
import { CheckingAccount } from "./checkingAccounts";
import { Company } from "./companies";
import { Store } from "./stores";
import { Sale } from "./sales";

export const CustomerSchema = yup.object().shape({
  companyId: yup.string().integer().required(),
  storeId: yup.string().integer().required(),
  utid: yup.string().dniOrCuit().required(),
  name: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email(),
  alias: yup.string().nullable(),
  taxCondition: yup.string().integer().nullable(),
  streetName: yup.string().nullable(),
  streetNumber: yup.string().nullable(),
  notes: yup.string().nullable(),
  phone: yup.string().nullable(),
  city: yup.string().nullable(),
  province: yup.string().nullable(),
  zipCode: yup.string().nullable(),
  genre: yup.string(),
  discount: yup.string().numeric(),
});

export type Customer = WithTrash<{
  id: number;
  companyId: number;
  storeId: number;
  name: string;
  lastname: string;
  genre: string;
  email: string;
  alias: string;
  utid: string;
  phone: string;
  notes: string;
  streetName: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  province: string;
  discount: number;
  utidType: number;

  taxCondition: number;
  createdAt?: Date;

  sales: Sale[];
  company: Company;
  store?: Store;
  accounts: CheckingAccount[];
  addresses: CustomerAddress[];
}>;

export type CustomerAddress = {
  id: number;
  customerId: number;
  streetName: string;
  streetNumber: string;
  city: string;
  province: string;
  zipCode: string;
  notes: string;
};

export type CustomerCreate = Omit<
  Customer,
  | "id"
  | "utidType"
  | "deletedAt"
  | "company"
  | "store"
  | "accounts"
  | "sales"
  | "addresses"
>;

export type CustomerUpdate = CustomerCreate;

export const LIST_CUSTOMERS = gql`
  query getCustomers($filters: CustomerFilters) {
    data: customers(filters: $filters) {
      data {
        id
        name
        lastname
        alias
        taxCondition
        genre
        email
        utid
        utidType
        streetName
        streetNumber

        sales {
          id
        }

        accounts {
          id
          balance
        }

        deletedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query getCustomer($id: Int!) {
    data: customer(id: $id) {
      id
      companyId
      storeId
      utid
      name
      lastname
      alias
      taxCondition
      genre
      email
      phone
      notes

      streetName
      streetNumber
      city
      province
      zipCode

      discount

      createdAt
      deletedAt

      addresses {
        id
        customerId
        streetName
        streetNumber
        city
        province
        zipCode
        notes
      }

      accounts {
        id
        balance
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    data: createCustomer(input: $input) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: Int!, $input: UpdateCustomerInput!) {
    data: updateCustomer(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: Int!) {
    data: deleteCustomer(id: $id) {
      id
    }
  }
`;

export const ASSIGN_TO_SALE = gql`
  mutation assignCustomer($input: AssignCustomerInput!) {
    data: assignCustomer(input: $input) {
      id
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation addCustomerAddress(
    $customerId: Int!
    $input: CustomerAddressInput!
  ) {
    data: addCustomerAddress(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export const EDIT_ADDRESS = gql`
  mutation updateCustomerAddress(
    $id: Int!
    $customerId: Int!
    $input: CustomerAddressInput!
  ) {
    data: updateCustomerAddress(
      id: $id
      customerId: $customerId
      input: $input
    ) {
      id
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation deleteCustomerAddress($id: Int!, $customerId: Int!) {
    data: deleteCustomerAddress(id: $id, customerId: $customerId) {
      id
    }
  }
`;
