import { useMutation, useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { useMemo, useRef, useState } from "react";
import Api from "src/api";
import { Product } from "src/api/products";
import { Retire, RetireItem } from "src/api/retires";

import { AppLoader } from "src/components/Loader/Loader";
import SmartSelect from "src/components/SmartSelect";
import { logEvent } from "src/helpers/analytics";

import { GraphQLFind, GraphQLMeta } from "src/types";
import yup from "src/helpers/validation";
import { Warehouse } from "src/api/warehouses";
import { ChevronLeft, LoaderCircle, Trash } from "lucide-react";
import { useNavigate, useParams } from "react-router";
import { useUserStore } from "src/store/users";

export const RemoveStockSchema = yup.object().shape({
  productId: yup.number().min(1).required(),
  quantity: yup.number().min(0.1).required(),
});

const MoveList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const warehouseId = Number(params.warehouseId);
  const moveId = Number(params.moveId);

  if (!warehouseId || !moveId) {
    navigate(-1);
  }

  const [toWarehouseId, setWarehouseId] = useState<number>();
  const quantityRef = useRef<HTMLInputElement>(null);
  const { hasPermission, currentCompany } = useUserStore();
  const { data: warehouses } = useQuery<GraphQLMeta<Warehouse>>(
    Api.Warehouses.LIST_WAREHOUSES,
    { fetchPolicy: "no-cache", variables: { filters: { limit: 0 } } }
  );
  const { data: productsList, loading } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId: currentCompany?.id,
          warehouseId,
          limit: 0,
          trashed: 0,
          stockeable: true,
        },
      },
    }
  );
  const { data: retire, refetch } = useQuery<GraphQLFind<Retire>>(
    Api.Retires.GET_RETIRE,
    {
      variables: {
        id: moveId,
      },
      skip: !moveId,
      fetchPolicy: "no-cache",
      onCompleted: ({ data }) => {
        if (data.toWarehouse?.id) {
          setWarehouseId(data.toWarehouse.id);
        }
      },
    }
  );
  const [addItem, { loading: adding }] = useMutation(
    Api.Retires.ADD_ITEM_TO_RETIRE,
    {
      onCompleted: () => {
        (document.querySelector(".select-search-input") as any).focus();
        refetch();
        formik.resetForm();
      },
    }
  );
  const [deleteRetireItem, { loading: deleting }] = useMutation(
    Api.Retires.DELETE_ITEM_FROM_RETIRE,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
    }
  );
  const [closeRetire, { loading: closing }] = useMutation(
    Api.Retires.CLOSE_RETIRE,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
    }
  );
  const [fulfillRetireMove, { loading: fulfilling }] = useMutation(
    Api.Retires.FULFILL_RETIRE_MOVE,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
      onError: (data) => {
        if (data.message === "retire_cant_be_fulfilled") {
          alert("Este movimiento ya ha sido realizado.");

          window.location.reload();
        }
      },
    }
  );

  const [updateRetire, { loading: updating }] = useMutation(
    Api.Retires.UPDATE_RETIRE
  );

  const formik = useFormik({
    initialValues: {
      productId: 0,
      currentStock: 0,
      quantity: 0,
    },
    onSubmit: (data) => {
      if (!updating) {
        const input = {
          retireId: moveId,
          productId: data.productId,
          quantity: data.quantity,
        };

        logEvent("retires.add-item", { input });

        addItem({
          variables: {
            input,
          },
        });
      }
    },
    validateOnMount: true,
    validationSchema: RemoveStockSchema,
  });

  const products = useMemo(() => {
    if (!productsList?.data.data) {
      return [];
    }

    return productsList.data.data.map((product) => ({
      name: `${product.name} - ${
        product.barcodes.length > 0
          ? product.barcodes.map((b) => b.barcode).join(", ")
          : "Sin código de barra"
      } - (Stock: ${product.currentStock})`,
      value: product.id,
    }));
  }, [productsList]);

  if (!retire?.data || loading) {
    return <AppLoader />;
  }

  const totals = retire?.data.items.reduce(
    (prev, curr) => curr.quantity + prev,
    0
  );

  const columns = [
    {
      key: "product",
      label: "Producto",
      _props: { className: "font-weight-bold" },
    },
    {
      key: "user",
      label: "Cargado por",
      _props: { className: "text-center font-weight-bold" },
    },
    {
      key: "quantity",
      label: "Cantidad",
      _props: { className: "text-center font-weight-bold" },
    },
  ];

  const retireStatus = retire.data.status;
  const canUpdateRetire =
    retire.data.warehouse.stores?.length > 0
      ? retire.data.warehouse.stores.some(({ id }) =>
          hasPermission("UPDATE_RETIRE", { type: "store", id })
        )
      : hasPermission("UPDATE_RETIRE", {
          type: "company",
          id: retire.data.warehouse.companyId,
        });

  if (retireStatus === "OPEN") {
    columns.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right font-weight-bold" },
    });
  }

  const filteredWarehouses =
    warehouses?.data.data.filter((w) => {
      if (w.id === warehouseId) {
        return false;
      }

      if (w.stores.length > 0) {
        return w.stores
          .map((s) => s.id)
          .some(
            (storeId) =>
              hasPermission("CREATE_BATCH", { type: "store", id: storeId }) &&
              hasPermission("CREATE_RETIRE", { type: "store", id: storeId })
          );
      }

      if (w.stores.length === 0) {
        return (
          hasPermission("CREATE_BATCH", {
            type: "company",
            id: w.company.id,
          }) &&
          hasPermission("CREATE_RETIRE", { type: "company", id: w.company.id })
        );
      }

      return true;
    }) ?? [];

  const generateFooter = () => {
    if (totals <= 0) {
      return false;
    }

    const footerStack = [
      {
        label: "Cantidad de items",
      },
      "",
      {
        label: totals?.toString(),
        _props: { className: "text-center" },
      },
    ];

    if (retire.data.status === "OPEN") {
      footerStack.push("");
    }

    return footerStack;
  };

  return (
    <CCard>
      <CCardHeader>
        <CRow className="px-0">
          <CCol sm={4} className="px-0 d-flex align-items-center">
            <CButton
              onClick={() => navigate(-1)}
              variant="ghost"
              color="default"
              size="sm"
              className="no-print"
            >
              <ChevronLeft />
            </CButton>
            <div className="ml-3 only-print" />
            {canUpdateRetire &&
              retireStatus === "OPEN" &&
              `Cargar movimiento en ${retire.data.warehouse.name}`}
            {(!canUpdateRetire || retireStatus) !== "OPEN" &&
              `Movimiento #${retire.data.id} ${
                retire.data.notes ? ` - ${retire.data.notes}` : ""
              } de ${retire.data.warehouse.name}`}
          </CCol>
          <CCol sm={8} className="text-right">
            {canUpdateRetire && (
              <>
                {retireStatus === "OPEN" && retire.data.items.length > 0 && (
                  <CLoadingButton
                    disabled={
                      closing ||
                      updating ||
                      !toWarehouseId ||
                      toWarehouseId <= 0
                    }
                    onClick={() =>
                      closeRetire({
                        variables: {
                          id: moveId,
                          warehouseId: toWarehouseId,
                        },
                      })
                    }
                    size="sm"
                    color="info"
                  >
                    Cerrar movimiento
                  </CLoadingButton>
                )}

                {retireStatus === "CLOSED" && (
                  <CLoadingButton
                    loading={fulfilling}
                    onClick={() =>
                      fulfillRetireMove({
                        variables: {
                          id: moveId,
                          warehouseId: toWarehouseId,
                        },
                      })
                    }
                    size="sm"
                    color="success"
                  >
                    Mover de depósito
                  </CLoadingButton>
                )}

                {retireStatus === "FULFILLED" && (
                  <CButton
                    onClick={() => {
                      logEvent("moves.print", {
                        moveId,
                      });

                      window.print();
                    }}
                    size="sm"
                    color="success"
                    className="no-print"
                  >
                    Imprimir
                  </CButton>
                )}
              </>
            )}

            {retireStatus === "FULFILLED" && (
              <div className="only-print">
                Movido de <strong>{retire.data.warehouse.name}</strong> a{" "}
                <strong>{retire.data.toWarehouse?.name}</strong>
              </div>
            )}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        {canUpdateRetire && retireStatus === "OPEN" && (
          <CForm className="mb-3">
            <CRow>
              <CCol sm={5}>
                <CFormLabel>Depósito Destino</CFormLabel>
                <CFormSelect
                  onChange={(e) => {
                    logEvent("move-stock.warehouse.set-warehouse", {
                      warehouseId: e.target.value,
                    });

                    setWarehouseId(Number(e.target.value));
                  }}
                  name="warehouseId"
                  defaultValue={retire.data.toWarehouse?.id ?? 0}
                  onBlur={(e) => {
                    if (!updating && Number(e.target.value) > 0) {
                      updateRetire({
                        variables: {
                          id: moveId,
                          input: {
                            toWarehouseId: Number(e.target.value),
                          },
                        },
                      });
                    }
                  }}
                >
                  <option key={0} value={0}>
                    Seleccione Depósito
                  </option>
                  {filteredWarehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol className="product-search" sm={6}>
                <CFormLabel>Producto</CFormLabel>
                <SmartSelect
                  name="productId"
                  search
                  placeholder="Selecciona un producto"
                  emptyLabel="No tienes productos stockeables"
                  options={products}
                  onChange={(e) => {
                    const product = productsList?.data.data.find(
                      (p) => p.id === e
                    );

                    const currentRetireStock =
                      retire.data.items?.find((s) => s.product.id === e)
                        ?.quantity ?? 0;

                    if (product) {
                      const newStock =
                        product.currentStock - currentRetireStock;

                      formik.setValues({
                        productId: product.id,
                        currentStock: newStock,
                        quantity: newStock,
                      });

                      quantityRef.current?.focus();
                    }
                  }}
                  value={formik.values.productId.toString()}
                />
              </CCol>
              <CCol sm={3}>
                <CFormLabel>Cantidad</CFormLabel>
                <CFormInput
                  value={formik.values.quantity}
                  name="quantity"
                  ref={quantityRef}
                  type="number"
                  readOnly={
                    formik.values.quantity.toString() !== "" &&
                    formik.values.quantity <= 0
                  }
                  onChange={formik.handleChange}
                  valid={
                    !!formik.values.productId &&
                    formik.values.quantity > 0 &&
                    formik.values.quantity <= formik.values.currentStock
                  }
                  invalid={
                    !!formik.values.productId &&
                    !(
                      formik.values.quantity > 0 &&
                      formik.values.quantity <= formik.values.currentStock
                    )
                  }
                  feedbackInvalid={
                    formik.values.currentStock < 0
                      ? "No puedes retirar stock de un producto con stock 0 o negativo"
                      : "No puedes retirar mas stock de este producto"
                  }
                />
              </CCol>
              <CCol
                sm={3}
                className="d-flex align-items-start mt-9 justify-content-end"
              >
                <CLoadingButton
                  type="button"
                  color="primary"
                  size="sm"
                  onClick={formik.handleSubmit}
                  loading={adding}
                  disabled={adding || !formik.isValid}
                >
                  Agregar
                </CLoadingButton>
              </CCol>
            </CRow>
          </CForm>
        )}
        <CSmartTable
          footer={generateFooter()}
          itemsPerPage={retire.data.items.length ?? 0}
          items={retire.data.items ?? []}
          columns={columns}
          noItemsLabel={
            retire.data.status === "OPEN"
              ? "Empieza a agregar productos al egreso"
              : "No tienes items en este egreso"
          }
          scopedColumns={{
            product: (item: RetireItem) => <td>{item.product.name}</td>,
            user: (item: RetireItem) => (
              <td align="center">
                {item.user.name} {item.user.lastname}
              </td>
            ),
            quantity: (item: RetireItem) => (
              <td align="center">{item.quantity}</td>
            ),
            actions: (item: RetireItem) => (
              <td align="right">
                <CButton
                  onClick={() =>
                    deleteRetireItem({ variables: { id: item.id } })
                  }
                  size="sm"
                  color="danger"
                >
                  {deleting ? <LoaderCircle /> : <Trash />}
                </CButton>
              </td>
            ),
          }}
        />
      </CCardBody>
    </CCard>
  );
};

export default MoveList;
