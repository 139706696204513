import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { CSSProperties, memo, useCallback, useMemo, useState } from "react";
import { CSS } from "@dnd-kit/utilities";

import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react-pro";
import { GripHorizontal } from "lucide-react";

import {
  DndContext,
  MouseSensor,
  closestCenter,
  type DragEndEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";

// Cell Component
const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });

  return (
    <CButton size="sm" variant="ghost" {...attributes} {...listeners}>
      <GripHorizontal />
    </CButton>
  );
};

const DraggableRow = memo(({ row }: { row: Row<{ id: number }> }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id.toString(),
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    // connect row ref to dnd-kit, apply important styles
    <CTableRow ref={setNodeRef} style={style}>
      {row.getVisibleCells().map((cell) => (
        <CTableDataCell
          key={cell.id.toString()}
          style={{ width: cell.column.getSize() }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </CTableDataCell>
      ))}
    </CTableRow>
  );
});

function DraggableTable<T extends { id: number }>({
  columns,
  items,
  onDragChange,
}: {
  columns: ColumnDef<T>[];
  items: T[];
  onDragChange?: (items: T[]) => void;
}) {
  const [data, setData] = useState(items);

  const innerColumns = useMemo<ColumnDef<T>[]>(
    () => [
      {
        id: "drag-handle",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
        size: 60,
      },
      ...columns,
    ],
    [columns]
  );

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data?.map(({ id }) => id.toString()),
    [data]
  );

  const table = useReactTable({
    data,
    columns: innerColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
  });

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active || !over || active.id === over.id) return;

      // Solo actualiza el estado en respuesta a la acción de arrastrar, no durante el renderizado.
      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);

      const newData = arrayMove(data, oldIndex, newIndex);

      // No actualices el estado directamente en el ciclo de renderizado
      setData(newData);
      onDragChange?.(newData);
    },
    [data, dataIds, onDragChange]
  );

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div>
        <CTable>
          <CTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <CTableRow key={headerGroup.id.toString()}>
                {headerGroup.headers.map((header) => (
                  <CTableHeaderCell
                    key={header.id.toString()}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </CTableHeaderCell>
                ))}
              </CTableRow>
            ))}
          </CTableHead>

          <CTableBody>
            <SortableContext
              items={dataIds}
              strategy={verticalListSortingStrategy}
            >
              {table.getRowModel().rows.map((row) => (
                <DraggableRow key={row.id.toString()} row={row} />
              ))}
            </SortableContext>
          </CTableBody>
        </CTable>
      </div>
    </DndContext>
  );
}

const MemoizedDraggableTable = memo(DraggableTable) as <
  T extends { id: number }
>(props: {
  columns: ColumnDef<T>[];
  items: T[];
  onDragChange?: (items: T[]) => void;
}) => JSX.Element;

export default MemoizedDraggableTable;
