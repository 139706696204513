import { useNavigate, useParams } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CLoadingButton,
  CFormInput,
} from "@coreui/react-pro";

import { useMutation, useQuery } from "@apollo/client";

import { GraphQLMeta } from "src/types";
import Api from "src/api";
import { TiendaNubeWebhook } from "src/api/tienda-nube";

import { useState } from "react";
import { useUserStore } from "src/store/users";

const events = [
  { event: "order/created" },
  { event: "order/updated" },
  { event: "order/paid" },
  { event: "order/packed" },
  { event: "order/fulfilled" },
  { event: "order/cancelled" },
  { event: "order/custom_fields_updated" },
  { event: "product/created" },
  { event: "product/updated" },
  { event: "product/deleted" },
];

const isProductionEvent = (hook: TiendaNubeWebhook) =>
  hook.url.startsWith("https://graphql.daruma.cloud");

const isLocalUrl = (hook: TiendaNubeWebhook, url: string) =>
  hook.url.startsWith(`https://${url}`);

const TiendaNubeWebhooks = () => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const params = useParams();
  const storeId = Number(params.storeId);
  const [localBaseUrl, setLocalBaseUrl] =
    useState<string>("local.daruma.cloud");

  if (!storeId || !user?.isAdmin) {
    navigate("/tienda-nube");
  }

  const {
    data: items,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<TiendaNubeWebhook>>(Api.TiendaNube.LIST_WEBHOOKS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        limit: 0,
        storeId,
      },
    },
  });

  const [createWebhooks, { loading: creating }] = useMutation(
    Api.TiendaNube.CREATE_WEBHOOKS,
    {
      onCompleted: () => {
        refetch({
          limit: 0,
          storeId,
        });
      },
    }
  );

  const [deleteWebhook, { loading: deleting }] = useMutation(
    Api.TiendaNube.DELETE_WEBHOOK,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const webhooks = items?.data.data ?? [];

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center justify-content-center">
              <CCol sm="6" xs="6" className="px-0">
                Webhooks
              </CCol>
              <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow className="mb-2">
              <CCol>
                <CFormInput
                  onChange={(e) => setLocalBaseUrl(e.target.value)}
                  value={localBaseUrl}
                />
              </CCol>
            </CRow>
            <CSmartTable
              itemsPerPage={events.length}
              items={events}
              columns={[
                { key: "event", label: "Evento" },
                {
                  key: "local",
                  label: "Hook Local",
                  _props: { className: "text-center" },
                },
                {
                  key: "production",
                  label: "Hook Produccion",
                  _props: { className: "text-center" },
                },
                {
                  key: "actions",
                  label: "Acciones",
                  _props: { className: "text-right" },
                },
              ]}
              loading={loading}
              scopedColumns={{
                event: (item: TiendaNubeWebhook) => <td>{item.event}</td>,
                local: (item: TiendaNubeWebhook) => {
                  const currentUrl = webhooks.find(
                    (i) => i.event === item.event && isLocalUrl(i, localBaseUrl)
                  )?.url;

                  return <td align="center">{currentUrl ? "✅" : "❌"}</td>;
                },
                production: (item: TiendaNubeWebhook) => {
                  const currentUrl = webhooks.find(
                    (i) => i.event === item.event && isProductionEvent(i)
                  )?.url;

                  return <td align="center">{currentUrl ? "✅" : "❌"}</td>;
                },
                actions: (item: TiendaNubeWebhook) => {
                  const localUrl = webhooks.find(
                    (i) => i.event === item.event && isLocalUrl(i, localBaseUrl)
                  )?.url;

                  const prodUrl = webhooks.find(
                    (i) => i.event === item.event && isProductionEvent(i)
                  )?.url;

                  return (
                    <td align="right">
                      {(!localUrl || !prodUrl) && (
                        <CLoadingButton
                          color="primary"
                          loading={creating}
                          onClick={() =>
                            createWebhooks({
                              variables: {
                                input: {
                                  storeId,
                                  event: item.event,
                                  production: !prodUrl,
                                  local: !localUrl,
                                  localBaseUrl,
                                },
                              },
                            })
                          }
                          size="sm"
                        >
                          Crear Eventos
                        </CLoadingButton>
                      )}
                    </td>
                  );
                },
              }}
              tableProps={{
                striped: true,
              }}
            />
          </CCardBody>
        </CCard>
        <CCard className="mt-2">
          <CCardBody>
            <CSmartTable
              itemsPerPage={webhooks.length ?? 0}
              items={webhooks}
              columns={[
                { key: "event", label: "Evento" },
                {
                  key: "url",
                  label: "URL",
                  _props: { className: "text-center" },
                },
                {
                  key: "actions",
                  label: "Acciones",
                  _props: { className: "text-right" },
                },
              ]}
              loading={loading}
              scopedColumns={{
                event: (item: TiendaNubeWebhook) => <td>{item.event}</td>,
                url: (item: TiendaNubeWebhook) => {
                  return <td align="center">{item.url}</td>;
                },
                actions: (item: TiendaNubeWebhook) => {
                  return (
                    <td align="right">
                      <CLoadingButton
                        color="danger"
                        loading={deleting}
                        onClick={() =>
                          deleteWebhook({
                            variables: {
                              storeId,
                              id: item.id,
                            },
                          })
                        }
                        size="sm"
                      >
                        Eliminar Webhook
                      </CLoadingButton>
                    </td>
                  );
                },
              }}
              tableProps={{
                striped: true,
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default TiendaNubeWebhooks;
