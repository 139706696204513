import { useEffect, useRef, useState } from "react";
import {
  CCol,
  CButton,
  CCardFooter,
  CRow,
  CModal,
  CModalHeader,
  CModalFooter,
  CNavItem,
  CNav,
  CNavLink,
  CTabPane,
  CTabContent,
  CSmartTable,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
  CFormTextarea,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { CompanyWithStores } from "src/api/companies";
import { Store } from "src/api/stores";
import { GraphQLFind } from "src/types";
import { useLocation, useNavigate, useParams } from "react-router";
import Api from "src/api";
import { AppLoader } from "src/components/Loader/Loader";
import { logEvent } from "src/helpers/analytics";
import { findPointOfSale, parsePointOfSaleType } from "src/helpers/stores";
import FormTab from "./components/FormTab";
import { uniq } from "lodash";

const TabIndex = [
  "#basic",
  "#stores",
  // "#plan",
  "#categories",
  "#suppliers",
];

const EditCompany = () => {
  const location = useLocation();
  const params = useParams();
  const { hash, pathname, state } = location;
  const companyId = state?.companyId
    ? Number(state.companyId)
    : Number(params.id);
  const initialTab = TabIndex.find((index) => index === hash)
    ? TabIndex.findIndex((index) => index === hash)
    : 0;

  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<number>(initialTab);

  const { data: company, refetch } = useQuery<GraphQLFind<CompanyWithStores>>(
    Api.Companies.GET_COMPANY,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: companyId,
      },
    }
  );

  const onTabChange = (newIndex: number) => {
    navigate(`${pathname}${TabIndex[newIndex]}`);
    setCurrentTab(newIndex);
  };

  useEffect(() => {
    if (state?.refetch) {
      refetch();
    }
  }, [state, refetch]);

  if (!company?.data) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form
        company={company.data}
        currentTab={currentTab}
        onTabChange={onTabChange}
      />
    </CCol>
  );
};

type FormRefProps = {
  delete: () => void;
  submit: () => void;
};

const Form = ({
  company,
  currentTab,
  onTabChange,
}: {
  company: CompanyWithStores;
  currentTab: number;
  onTabChange: (index: number) => void;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const formTabRef = useRef<FormRefProps>(null);
  const planTabRef = useRef<FormRefProps>(null);
  const [values, setValues] = useState<{
    categories: string[];
    suppliers: string[];
  }>({
    categories: [""],
    suppliers: [""],
  });

  const [batchCreateCategories, { loading: creatingCat }] = useMutation(
    Api.Categories.CREATE_CATEGORIES,
    {
      onCompleted: ({ data }) => {
        alert(
          `Se han creado ${data.length} categorias. \n${data
            .map((d) => d.name)
            .join(`\n`)}`
        );

        window.location.reload();
      },
    }
  );

  const [batchCreateSuppliers, { loading: creatingSup }] = useMutation(
    Api.Suppliers.CREATE_SUPPLIERS,
    {
      onCompleted: ({ data }) => {
        alert(
          `Se han creado ${data.length} proveedores. \n${data
            .map((d) => d.name)
            .join(`\n`)}`
        );

        window.location.reload();
      },
    }
  );

  const creating = creatingCat || creatingSup;

  return (
    <CCard>
      <CCardHeader>Empresa: {company.name}</CCardHeader>
      <CCardBody>
        <CNav variant="tabs" role="tablist">
          <CNavItem>
            <CNavLink onClick={() => onTabChange(0)} active={currentTab === 0}>
              Datos Básicos
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(1)} active={currentTab === 1}>
              Puntos de Venta
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(2)} active={currentTab === 2}>
              Categorias
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(3)} active={currentTab === 3}>
              Proveedores
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent className="px-0 py-1">
          <CTabPane visible={currentTab === 0} className="py-3">
            <FormTab
              company={company}
              ref={formTabRef}
              onLoading={(value: boolean) => setLoading(value)}
            />
          </CTabPane>
          <CTabPane visible={currentTab === 1} className="py-3">
            <CSmartTable
              itemsPerPage={company?.stores.length ?? 20}
              items={company?.stores || []}
              columns={[
                { key: "icon", label: "", _props: { className: "w-2" } },
                {
                  key: "name",
                  label: "Nombre",
                },
                { key: "type", label: "Tipo" },
                {
                  key: "address",
                  label: "Dirección",
                },
                {
                  key: "phone",
                  label: "Teléfono",
                },
                {
                  key: "sector",
                  label: "Rubro",
                },
              ]}
              scopedColumns={{
                icon: (item: Store) => {
                  const currentType = findPointOfSale(item.type);
                  const StoreIcon = () => currentType?.icon;

                  return (
                    <td align="center">
                      <StoreIcon />
                    </td>
                  );
                },
                name: (item: Store) => (
                  <td>
                    <span>{item.name}</span>
                  </td>
                ),
                address: (item: Store) => (
                  <td>
                    <span>
                      {item.streetName} {item.streetNumber}
                    </span>
                  </td>
                ),
                type: (item: Store) => (
                  <td>{parsePointOfSaleType(item.type)}</td>
                ),
                sector: (item: Store) => <td>{item.sector?.name}</td>,
                phone: (item: Store) => (
                  <td>
                    <span>{item.phone}</span>
                  </td>
                ),
              }}
              clickableRows
              onRowClick={(item) => {
                navigate(`/stores/${item.id}`);
              }}
              tableProps={{
                striped: true,
              }}
            />
          </CTabPane>
          <CTabPane visible={currentTab === 2} className="py-2 px-3">
            <CFormTextarea
              rows={12}
              label="Lista de Categorias"
              style={{ height: 200, resize: "none" }}
              onChange={(e) => {
                setValues((prev) => ({
                  ...prev,
                  categories: e.target.value
                    .split("\n")
                    .map((a) => a.trim())
                    .filter((a) => a !== ""),
                }));
              }}
            />
          </CTabPane>
          <CTabPane visible={currentTab === 3} className="py-2 px-3">
            <CFormTextarea
              rows={12}
              label="Lista de Proveedores"
              style={{ height: 200, resize: "none" }}
              onChange={(e) => {
                setValues((prev) => ({
                  ...prev,

                  suppliers: e.target.value
                    .split("\n")
                    .map((a) => a.trim())
                    .filter((a) => a !== ""),
                }));
              }}
            />
          </CTabPane>
        </CTabContent>
      </CCardBody>

      <CCardFooter className="px-3">
        <CRow className="px-0 align-items-center">
          {currentTab === 0 ? (
            <CCol md="12" className="flex justify-content-end">
              <CButton
                size="sm"
                type="button"
                color="danger"
                className="mr-2"
                onClick={() => setShowModal(true)}
              >
                Eliminar
              </CButton>

              <CModal
                alignment="center"
                visible={showModal}
                onClose={() => setShowModal(false)}
              >
                <CModalHeader closeButton>
                  ¿Estás seguro de eliminar esta empresa?
                </CModalHeader>
                <CModalFooter>
                  <CButton
                    size="sm"
                    color="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </CButton>
                  <CLoadingButton
                    size="sm"
                    color="danger"
                    disabled={loading}
                    loading={loading}
                    onClick={() => formTabRef.current?.delete()}
                  >
                    Si, quiero eliminarlo
                  </CLoadingButton>
                </CModalFooter>
              </CModal>

              <CLoadingButton
                loading={loading}
                disabled={loading}
                size="sm"
                type="button"
                color="primary"
                onClick={() => formTabRef.current?.submit()}
              >
                Guardar
              </CLoadingButton>
            </CCol>
          ) : currentTab === 1 ? (
            <CCol md="12" className="flex justify-content-end">
              <CLoadingButton
                size="sm"
                color="primary"
                loading={loading}
                disabled={loading}
                type="button"
                onClick={() => {
                  logEvent("company.new-store", {
                    company,
                  });

                  navigate("/stores/new", {
                    state: { companyId: company.id },
                  });
                }}
              >
                Nuevo Punto de Venta
              </CLoadingButton>
            </CCol>
          ) : currentTab === 2 || currentTab === 3 ? (
            <CCol md="12" className="flex justify-content-end">
              <CLoadingButton
                size="sm"
                color="primary"
                loading={creating}
                disabled={creating}
                type="button"
                onClick={() => {
                  if (currentTab === 2) {
                    batchCreateCategories({
                      variables: {
                        companyId: company.id,
                        categories: uniq(values.categories),
                      },
                    });
                  } else {
                    batchCreateSuppliers({
                      variables: {
                        companyId: company.id,
                        suppliers: uniq(values.suppliers),
                      },
                    });
                  }
                }}
              >
                Crear
              </CLoadingButton>
            </CCol>
          ) : (
            <CCol md="12" className="flex justify-content-end">
              <CLoadingButton
                size="sm"
                loading={loading}
                disabled={loading}
                type="button"
                color="primary"
                onClick={() => planTabRef.current?.submit()}
              >
                Guardar
              </CLoadingButton>
            </CCol>
          )}
        </CRow>
      </CCardFooter>
    </CCard>
  );
};

export default EditCompany;
