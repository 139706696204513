import { useMutation, useQuery } from "@apollo/client";
import {
  CAlert,
  CCol,
  CForm,
  CFormCheck,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { sortBy } from "lodash";
import { Fragment, useRef } from "react";
import Api from "src/api";
import { BankPromotion } from "src/api/bankPromotions";
import { Store, StoreConfig, StoreType } from "src/api/stores";
import { AppLoader } from "src/components/Loader/Loader";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { logEvent } from "src/helpers/analytics";

import { paymentMethods } from "src/helpers/payments";
import { useUserStore } from "src/store/users";

import { GraphQLMeta } from "src/types";

const StoreConfigRow = ({
  store,
  bankPromotions,
}: {
  store: Store;
  bankPromotions: BankPromotion[];
}) => {
  const { hasPermission, user } = useUserStore();
  const canEdit = hasPermission("UPDATE_STORE");
  const toastRef = useRef<ForwardedToastProps>(null);

  const [mutation, { loading }] = useMutation(Api.Stores.UPDATE_CONFIG, {
    onCompleted: () => {
      toastRef.current?.show();
    },
  });

  const formik = useFormik<StoreConfig>({
    initialValues: {
      ...store.config,
      roundingMethod: {
        floor: store.config.roundingMethod?.floor ?? 5,
        none: store.config.roundingMethod?.none ?? null,
        ceil: store.config.roundingMethod?.ceil ?? 6,
      },
    },
    onSubmit: (values) => {
      if (!loading) {
        const input = {
          defaultPaymentMethod: values.paymentMethod,
          profit: Number(values.profit),
          stock: values.stock,
          calculate: values.calculate,
          rounding: values.rounding,
          roundingMethod: values.roundingMethod,
          autoPropagate: values.autoPropagate.toString() === "1",
          stickersPaperSize: values.stickersPaperSize,
          displayStock: values.displayStock.toString() === "1",
          bankPromotions: values.bankPromotions,
        };

        logEvent("store.config.update", {
          store,
          input,
        });

        mutation({
          variables: {
            id: store.id,
            input,
          },
        });
      }
    },
  });

  return (
    <div className="position-relative">
      <CRow>
        <h5>🏣 {store.name}</h5>
      </CRow>

      {bankPromotions.length > 0 && (
        <CRow className="mb-3">
          <CFormLabel>Promociones Bancarias</CFormLabel>
          <CCol md="12">
            {sortBy(bankPromotions, "name").map((bankPromotion) => (
              <CFormCheck
                inline
                key={bankPromotion.id}
                id={`bankPromotion-${bankPromotion.id}-${store.id}`}
                type="checkbox"
                name="bankPromotions"
                value={bankPromotion.id}
                label={bankPromotion.name}
                defaultChecked={
                  !!formik.values?.bankPromotions?.find(
                    (t) => t === bankPromotion.id
                  )
                }
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    formik.setFieldValue("bankPromotions", [
                      ...formik.values.bankPromotions,
                      Number(e.currentTarget.value),
                    ]);
                  } else {
                    formik.setFieldValue(
                      "bankPromotions",
                      formik.values.bankPromotions.filter(
                        (t) => t !== Number(e.currentTarget.value)
                      )
                    );
                  }
                }}
              />
            ))}
          </CCol>
        </CRow>
      )}

      <CRow>
        <CCol md="4">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="Método de Pago por Defecto"
            name="paymentMethod"
            onChange={formik.handleChange}
            defaultValue={formik.values.paymentMethod}
          >
            <option value="" key={0}>
              Ninguno
            </option>
            {paymentMethods
              .filter((paymentMethod) => !paymentMethod.digital)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((paymentMethod) => (
                <option value={paymentMethod.type} key={paymentMethod.id}>
                  {paymentMethod.name}
                </option>
              ))}
          </CFormSelect>
        </CCol>
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="Tamaño de Hoja de Etiquetas"
            name="stickersPaperSize"
            onChange={formik.handleChange}
            defaultValue={formik.values.stickersPaperSize}
          >
            <option value="A4">A4</option>
            <option value="HusaresA4">Husares A4</option>
            {user?.isAdmin && (
              <option value="HusaresPrueba">Husares Pruebas</option>
            )}
            <option value="Width80">80mm</option>
          </CFormSelect>
        </CCol>
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="¿Auto propagación de productos?"
            name="autoPropagate"
            onChange={formik.handleChange}
            defaultValue={formik.values.autoPropagate ? 1 : 0}
          >
            <option value={1}>Si</option>
            <option value={0}>No</option>
          </CFormSelect>
        </CCol>
        <CCol md="2">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="¿Mostrar Stock?"
            name="displayStock"
            onChange={formik.handleChange}
            defaultValue={formik.values.displayStock ? 1 : 0}
          >
            <option value={1}>Si</option>
            <option value={0}>No</option>
          </CFormSelect>
        </CCol>
      </CRow>

      <Toast
        color="primary"
        autohide
        visible={false}
        ref={toastRef}
        text="Configuración actualizada"
      />

      <CRow className="mt-3">
        {!canEdit ? (
          <CCol md="12">
            <CAlert color="danger" className="px-3 py-1 m-0 text-center">
              No tienes permisos para editar estas configuraciones
            </CAlert>
          </CCol>
        ) : (
          <CCol md="12" className="text-right">
            <CLoadingButton
              size="sm"
              color="primary"
              loading={loading}
              disabled={loading}
              onClick={() => formik.handleSubmit()}
            >
              Guardar
            </CLoadingButton>
          </CCol>
        )}
      </CRow>
    </div>
  );
};

const StoreSettings = ({
  companyId,
  bankPromotions,
}: {
  companyId: number;
  bankPromotions: BankPromotion[];
}) => {
  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId,
          permission: "LIST_STORES",
        },
      },
    }
  );

  if (!stores?.data.data) {
    return <AppLoader />;
  }

  return (
    <CForm>
      {stores.data.data
        .filter((store) => store.type === StoreType.Store)
        .map((store, index) => (
          <Fragment key={store.id}>
            {stores.data.data.length > 1 && index > 0 && <hr />}
            <StoreConfigRow store={store} bankPromotions={bankPromotions} />
          </Fragment>
        ))}
    </CForm>
  );
};

export default StoreSettings;
