import { forwardRef } from "react";
import {
  CCol,
  CRow,
  CCardFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import ErrorMessage from "src/components/ErrorMessage";
import Api from "src/api";

import { getValidity } from "src/helpers/validation";
import { normalize } from "src/helpers/strings";
import { logEvent } from "src/helpers/analytics";
import { CreateSector } from "src/api/sectors";
import { useUserStore } from "src/store/users";

const Screen = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();

  if (!user?.isAdmin) {
    navigate("/");
  }

  const [mutation, { error, loading }] = useMutation(
    Api.Sectors.CREATE_SECTOR,
    {
      onCompleted: () => {
        navigate(`/sectors`);
      },
    }
  );

  const onNameChange = (e) => {
    const { value } = e.currentTarget;

    formik.setFieldValue("slug", normalize(value));
    formik.setFieldValue("name", value.toUpperCase());
  };

  const formik = useFormik<CreateSector>({
    initialValues: {
      name: "",
      slug: "",
      notes: "",
    },
    onSubmit: (input) => {
      if (!loading) {
        const data = {
          name: input.name?.trim().toUpperCase(),
          slug: input.slug ?? normalize(input.slug),
          notes: input.notes.trim() ?? null,
        };

        logEvent("sector.create", {
          input: data,
        });

        mutation({
          variables: {
            input: data,
          },
        });
      }
    },
  });

  return (
    <CCard>
      <CForm onSubmit={formik.handleSubmit}>
        <CCardHeader>Crear Rubro</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12">
              <CRow className="mt-3">
                <CCol md="3">
                  <CFormLabel htmlFor="name">Nombre</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="name"
                    placeholder="Nombre"
                    name="name"
                    onChange={onNameChange}
                    value={formik.values.name}
                    {...getValidity(formik.values.name, formik.errors.name)}
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3">
                  <CFormLabel htmlFor="slug">Identificador</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="slug"
                    placeholder="Identificador"
                    name="slug"
                    disabled
                    value={formik.values.slug ?? ""}
                    readOnly
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3">
                  <CFormLabel htmlFor="notes">Notas</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="notes"
                    placeholder="Notas"
                    onChange={formik.handleChange}
                    name="notes"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow className="px-0">
            <CCol md="9">
              {error && (
                <ErrorMessage text="Los datos ingresados son incorrectos" />
              )}
            </CCol>
            <CCol md="3" className="flex justify-content-end">
              <CLoadingButton
                loading={loading}
                color="primary"
                size="sm"
                disabled={loading}
                type="submit"
              >
                Crear
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CForm>
    </CCard>
  );
};

export default forwardRef(Screen);
