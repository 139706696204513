import {
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react-pro";
import { useUserStore } from "src/store/users";

const CompanyDropdown = () => {
  const { user, currentCompany, setCurrentCompany } = useUserStore();

  if (!currentCompany || !user || user.companies.length === 1) {
    return null;
  }

  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle className="py-0">{currentCompany.name}</CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-body-secondary fw-semibold py-2 rounded-top">
          Cambiar Empresa
        </CDropdownHeader>
        {user.companies.map((company) => (
          <CDropdownItem
            key={company.id}
            onClick={() => {
              setCurrentCompany(company);
            }}
          >
            {company.name}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default CompanyDropdown;
