import { useLazyQuery } from "@apollo/client";
import {
  CAlert,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router";
import Api from "src/api";
import { FetchUtidSchema } from "src/api/afip";
import { Loader } from "src/components/Loader/Loader";
import { useUserStore } from "src/store/users";

const UTIDScreen = () => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const [utidData, setUtidData] = useState<Object | null>();

  if (!user?.isAdmin) {
    navigate("/");
  }

  const [fetchUTID, { loading, error }] = useLazyQuery(Api.Afip.FETCH_UTID, {
    fetchPolicy: "no-cache",
    onCompleted: ({ data }) => {
      setUtidData(data);
    },
    onError: () => {
      setUtidData(undefined);
    },
  });

  const formik = useFormik({
    initialValues: {
      utid: "",
      genre: "",
    },
    onSubmit: ({ utid, genre }) => {
      if (!loading) {
        fetchUTID({ variables: { utid, genre } });
      }
    },
    validateOnChange: true,
    validationSchema: FetchUtidSchema,
    validateOnMount: true,
  });

  return (
    <CCol lg={12}>
      <CForm onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Buscador de CUIT</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="8" md="8">
                <CFormInput
                  id="utid"
                  floatingLabel="CUIL / CUIT / DNI"
                  defaultValue=""
                  name="utid"
                  readOnly={loading}
                  onChange={formik.handleChange}
                />
              </CCol>

              <CCol xs="4" md="4">
                <CFormSelect
                  id="genre"
                  name="genre"
                  floatingLabel="Sexo"
                  defaultValue={formik.values.genre}
                  onChange={formik.handleChange}
                >
                  <option value="U">Seleccione Sexo / Tipo</option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                  <option value="S">Empresa</option>
                  <option value="U">Otro</option>
                </CFormSelect>
              </CCol>
            </CRow>

            {loading && (
              <CRow className="mt-2">
                <CCol sm={12}>
                  <Loader />
                </CCol>
              </CRow>
            )}

            {(error || utidData === null) && (
              <CRow className="mt-2">
                <CCol sm={12}>
                  <CAlert color={"warning"}>
                    No se han encontrado resultados para tu busqueda
                  </CAlert>
                </CCol>
              </CRow>
            )}

            {utidData && (
              <CRow className="mt-2">
                <CCol sm={12}>
                  <pre>{JSON.stringify(utidData, null, 2)}</pre>
                </CCol>
              </CRow>
            )}
          </CCardBody>
          <CCardFooter className="text-right">
            <CLoadingButton
              type="submit"
              color="primary"
              size="sm"
              loading={loading}
              disabled={!formik.isValid}
            >
              Buscar
            </CLoadingButton>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default UTIDScreen;
