import { Link, useLocation, useNavigate } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
  CFormInput,
  CForm,
  CFormSelect,
} from "@coreui/react-pro";

import { User } from "src/api/users";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm } from "src/types";
import Api from "src/api";

import { useQuery } from "@apollo/client";

import { useFormik } from "formik";
import { logEvent } from "src/helpers/analytics";
import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import Pagination from "src/components/Pagination";
import { TextSearch } from "lucide-react";
import { Column } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useUserStore } from "src/store/users";

const UsersScreen = () => {
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const { user: currentUser, hasPermission } = useUserStore();
  const {
    data: users,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<User>>(Api.Users.LIST_USERS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        trashed: 0,
        search: querySearch.search ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination("users", refetch);
  const eventKey = currentUser?.isAdmin ? "users" : "employees";

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: querySearch.search ?? "",
      trashed: 0,
    },
    onSubmit: (input) => {
      logEvent(`${eventKey}.search`, {
        input,
      });

      resetAndSearch({
        page: 1,
        search: input.search,
        trashed: currentUser?.isAdmin ? Number(input.trashed) : 0,
      });
    },
  });

  const restColumns: Column[] = [];

  if (currentUser?.isAdmin) {
    restColumns.push({
      key: "companies",
      label: "Empresas",
    });
  }

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  {currentUser?.isAdmin ? "Usuarios" : "Empleados"}
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end">
                  {hasPermission("CREATE_USER") && (
                    <Link
                      to="/users/new"
                      className="flex justify-content-end p-0"
                    >
                      <CButton
                        color="primary"
                        size="sm"
                        onClick={() => {
                          logEvent(`${eventKey}.navigate-to-create`);
                        }}
                      >
                        Crear {currentUser?.isAdmin ? "usuario" : "empleado"}
                      </CButton>
                    </Link>
                  )}
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar..."
                      name="search"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 1 }}
                    />

                    {currentUser?.isAdmin && (
                      <CFormSelect
                        name="trashed"
                        defaultValue={formik.values.trashed}
                        onChange={formik.handleChange}
                        style={{ flex: 0.2, marginLeft: 12 }}
                      >
                        <option value={-1}>Todos</option>
                        <option value={1}>Eliminados</option>
                        <option value={0}>Activos</option>
                      </CFormSelect>
                    )}

                    <CButton
                      color="primary"
                      type="submit"
                      className="ml-2 p-0 w-10 h-10"
                    >
                      <TextSearch />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={
                  users?.data.data
                    .filter(
                      (user) => user.id !== currentUser?.id && !user.isAdmin
                    )
                    .map((user) => ({
                      ...user,
                      _props: { color: user.deletedAt ? "danger" : "default" },
                    })) || []
                }
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  ...restColumns,
                  { key: "email", label: "Email" },
                  {
                    key: "loginCode",
                    label: "Código de Ingreso",
                    _props: { className: "text-center" },
                  },
                ]}
                scopedColumns={{
                  name: (item: User) => (
                    <td>
                      {item.name} {item.lastname}
                    </td>
                  ),
                  companies: (item: User) => (
                    <td>
                      {item.companies.map((c) => c.company.name).join(",")}
                    </td>
                  ),
                  loginCode: (item: User) => (
                    <td className="text-center">
                      {!item.loginCode || !item.userLoginCode
                        ? "Sin código"
                        : `${item.loginCode} - ${item.userLoginCode}`}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
                clickableRows
                onRowClick={(item) => {
                  logEvent(`${eventKey}.navigate-to-user`, {
                    user: {
                      id: item.id,
                    },
                  });

                  navigate(`/users/${item.id}`);
                }}
              />

              <Pagination meta={users} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default UsersScreen;
