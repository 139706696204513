import { Link } from "react-router";
import {
  CCol,
  CSmartTable,
  CRow,
  CButton,
  CButtonGroup,
  CLoadingButton,
} from "@coreui/react-pro";
import { calculatePrice, formatCurrency } from "src/helpers/numbers";
import { Daruma } from "src/components/Icon";
import { useEffect, useMemo, useState } from "react";
import { PriceList, PriceListItem } from "src/api/price-lists";
import { Column } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@apollo/client";
import Api from "src/api";

enum TabIndex {
  MissingInDaruma = 1,
  OutdatedPrice = 2,
}

const PriceListAudit = ({ priceList }: { priceList: PriceList }) => {
  const [currentTab, setCurrentTab] = useState<TabIndex>(
    TabIndex.MissingInDaruma
  );
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [currentTab]);

  const [syncItemsMutation, { loading }] = useMutation(
    Api.PriceLists.SYNC_ITEMS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const items = priceList.items;

  const productList = useMemo(() => {
    if (!items) {
      return [];
    }

    if (currentTab === TabIndex.MissingInDaruma) {
      return items.filter((item) => !item);
    }

    if (currentTab === TabIndex.OutdatedPrice) {
      return items.filter(
        (item) =>
          item.price !== calculatePrice(item.product.price, priceList.profit)
      );
    }

    return [];
  }, [currentTab, items, priceList.profit]);

  const rest: Column[] = [];

  rest.push({
    key: "actions",
    label: "Acciones",
    _props: { className: "text-right" },
    filter: false,
  });

  const multiSelection = selectedItems.length > 0;

  return (
    <>
      <CRow>
        <CCol sm={12} className="flex">
          <CButtonGroup size="sm" role="group">
            <CButton
              color="primary"
              variant={
                currentTab === TabIndex.MissingInDaruma ? undefined : "outline"
              }
              onClick={() => setCurrentTab(TabIndex.MissingInDaruma)}
            >
              Inexistentes en Daruma
            </CButton>
            <CButton
              color="primary"
              variant={
                currentTab === TabIndex.OutdatedPrice ? undefined : "outline"
              }
              onClick={() => setCurrentTab(TabIndex.OutdatedPrice)}
            >
              Precios desactualizados
            </CButton>
          </CButtonGroup>

          <div className="flex justify-content-end">
            {multiSelection && (
              <>
                {[TabIndex.OutdatedPrice].includes(currentTab) && (
                  <CLoadingButton
                    color="success"
                    size="sm"
                    loading={loading}
                    disabled={!multiSelection}
                    onClick={() => {
                      if (multiSelection) {
                        syncItemsMutation({
                          variables: {
                            id: priceList.id,
                            itemIds: selectedItems,
                          },
                        });
                      }
                    }}
                  >
                    Sincronizar Productos
                  </CLoadingButton>
                )}
              </>
            )}
          </div>
        </CCol>
      </CRow>

      <CSmartTable
        className="mt-2"
        itemsPerPage={20}
        items={productList}
        selected={productList.filter((p) => selectedItems.includes(p.id))}
        selectable={
          [TabIndex.OutdatedPrice].includes(currentTab) &&
          productList.length > 1
        }
        onSelectAll={() => {
          setSelectedItems(productList.map((item) => item.id));
        }}
        onSelectedItemsChange={(e) => {
          setSelectedItems(e.map((i) => i.id));
        }}
        pagination
        columns={[
          {
            key: "name",
            label: "Nombre",
            _props: { className: "font-weight-bold" },
          },
          {
            key: "price",
            label: "Precio",
            _props: { className: "text-right" },
            filter: false,
          },
          ...rest,
        ]}
        columnFilter={{
          lazy: true,
        }}
        scopedColumns={{
          name: (item: PriceListItem) => (
            <td>
              <div className="flex align-items-center">
                <Daruma />
                <span className="ml-2">
                  <Link to={`/products/${item.productId}`} target="_blank">
                    {item.product.name} - <small>SKU: {item.product.sku}</small>
                  </Link>
                </span>
              </div>
              <div className="flex align-items-center mt-1">
                <BuildingStorefrontIcon height={24} width={24} />
                <span className="ml-2">
                  {item.name} - <small>SKU: {item.sku}</small>
                </span>
              </div>
            </td>
          ),

          price: (item: PriceListItem) => (
            <td align="right">
              <div className="flex align-items-center justify-content-end">
                <Daruma />
                <span className="ml-2">
                  {formatCurrency(
                    calculatePrice(item.product.price, priceList.profit)
                  )}
                </span>
              </div>
              <div className="flex justify-content-end mt-1">
                <BuildingStorefrontIcon height={24} width={24} />
                <span className="ml-2">{formatCurrency(item.price)}</span>
              </div>
            </td>
          ),
          actions: (item: PriceListItem) => {
            const hasDiffPrice =
              calculatePrice(item.product.price, priceList.profit) !==
              item.price;

            return (
              <td align="right">
                {currentTab === TabIndex.OutdatedPrice && hasDiffPrice && (
                  <Link
                    to=""
                    onClick={() => {
                      if (!multiSelection) {
                        syncItemsMutation({
                          variables: {
                            id: priceList.id,
                            itemIds: [item.id],
                          },
                        });
                      }
                    }}
                    style={{ color: multiSelection ? "gray" : "" }}
                  >
                    <small>Actualizar Precio</small>
                  </Link>
                )}
              </td>
            );
          },
        }}
        tableProps={{
          striped: true,
          hover: true,
        }}
      />
    </>
  );
};

export default PriceListAudit;
