import { useMutation } from "@apollo/client";
import {
  CButton,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react-pro";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useNavigate } from "react-router";
import Api from "src/api";
import { Sale } from "src/api/sales";
import { StockType } from "src/api/stock";
import { useUserStore } from "src/store/users";

import { ForwardedModalProps } from "src/types";

const DeleteSaleModal: ForwardRefRenderFunction<ForwardedModalProps> = (
  _,
  ref
) => {
  const { currentCompany } = useUserStore();
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [sale, setSale] = useState<Sale>();

  const [mutation, { loading: deleting }] = useMutation(Api.Sales.DELETE_SALE, {
    onCompleted: () => {
      handleDismiss();
      navigate("/sales");
    },
  });

  const handleDismiss = () => {
    setVisible(false);
    setSale(undefined);
  };

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        handleDismiss();
      },
      open: (s: Sale) => {
        setSale(s);
        setVisible(true);
      },
    }),
    []
  );

  const manageStock =
    currentCompany &&
    [StockType.Limited, StockType.Mixed].includes(currentCompany.config.stock);

  return (
    <CModal alignment="center" visible={visible} onClose={handleDismiss}>
      <CModalHeader closeButton>Eliminar Venta #{sale?.id}</CModalHeader>
      <CModalBody>
        ¿Estas seguro que quieres eliminar esta venta?
        {manageStock && (
          <p className="mt-3">
            ¿Tu empresa esta configurada para manejar stock, quieres que a se
            vez se restockeen los productos vendidos?
          </p>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton size="sm" color="secondary" onClick={() => handleDismiss()}>
          Cancelar
        </CButton>
        {manageStock && (
          <CLoadingButton
            loading={deleting}
            size="sm"
            color="warning"
            onClick={() => {
              if (sale) {
                mutation({
                  variables: {
                    id: sale.id,
                    restock: true,
                  },
                });
              }
            }}
          >
            Eliminar con restock
          </CLoadingButton>
        )}
        <CLoadingButton
          loading={deleting}
          size="sm"
          color="danger"
          onClick={() => {
            if (sale) {
              mutation({
                variables: {
                  id: sale.id,
                  restock: false,
                },
              });
            }
          }}
        >
          Eliminar{manageStock ? " sin restock" : ""}
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(DeleteSaleModal);
