import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Product } from "src/api/products";
import { GraphQLMeta } from "src/types";
import Api from "src/api";
import PlanAlert from "src/containers/PlanAlert";
import { sortBy } from "lodash";
import { useUserStore } from "src/store/users";
import DraggableTable from "src/components/DraggableTable/DraggableTable";
import { formatCurrency } from "src/helpers/numbers";
import { AppLoader } from "src/components/Loader/Loader";
import { useMemo, useRef } from "react";

const Favorites = () => {
  const { currentCompany } = useUserStore();
  const orderedItems = useRef<Product[]>(null);

  const [updateMutation, { loading: updating }] = useMutation(
    Api.Products.REORDER_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const { data: products, loading } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      variables: {
        fetchPolicy: "no-cache",
        filters: {
          limit: 0,
          companyId: currentCompany?.id,
        },
      },
      onCompleted: () => {
        orderedItems.current = [];
      },
    }
  );

  const items = useMemo(() => {
    return sortBy(
      products?.data.data.filter((p) => p.order !== null) ?? [],
      "order"
    );
  }, [products?.data.data]);

  if (!products?.data.data) {
    return <AppLoader />;
  }

  return (
    <>
      <PlanAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={12} className="px-3">
                  Productos Favoritos - Reordenar
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <DraggableTable<Product>
                onDragChange={(items: Product[]) => {
                  orderedItems.current = items;
                }}
                columns={[
                  {
                    accessorKey: "id",
                    header: () => "ID",
                  },
                  {
                    accessorKey: "name",
                    header: () => "Nombre",
                  },
                  {
                    accessorKey: "sku",
                    header: () => "SKU",
                  },
                  {
                    accessorKey: "barcodes",
                    header: () => "Código(s) de Barra",
                    cell: ({ row }) => (
                      <>
                        {row.original.barcodes
                          ?.map(({ barcode }) => barcode)
                          .join(", ") ?? ""}
                      </>
                    ),
                  },
                  {
                    accessorKey: "price",
                    header: () => "Precio de Venta",
                    cell: ({ row }) => formatCurrency(row.original.price),
                  },
                ]}
                items={items}
              />
            </CCardBody>

            <CCardFooter>
              <CRow>
                <CCol sm={12} className="d-flex gap-2 justify-content-end">
                  <CLoadingButton
                    size="sm"
                    color="danger"
                    onClick={() => window.location.reload()}
                    loading={loading}
                    disabled={updating}
                  >
                    Revertir cambios
                  </CLoadingButton>
                  <CLoadingButton
                    size="sm"
                    color="primary"
                    onClick={() => {
                      updateMutation({
                        variables: {
                          companyId: currentCompany?.id ?? 0,
                          productIds: orderedItems.current?.map((p) => p.id),
                        },
                      });
                    }}
                    disabled={loading}
                    loading={updating}
                  >
                    Guardar cambios
                  </CLoadingButton>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Favorites;
