import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { customStorage } from "./utils";
import Config from "src/config";
import { AppVersion, CommitHash, GenericState } from "src/types";

export type LayoutState = GenericState & {
  visibleAside: boolean;
  isCollapsed: boolean;

  toggleAside: (newState?: boolean) => void;
  setIsCollapsed: (newState: boolean) => void;

  commitHash: string;
  flags: Record<string, boolean>;

  setCommitHash: (commitHash: string) => void;
  setFeatureFlags: (flags: Record<string, boolean>) => void;
};

export const layoutInitialState = {
  visibleAside: false,
  isCollapsed: false,
  commitHash: CommitHash,
  flags: {},
};

export const useLayoutStore = create<
  LayoutState,
  [["zustand/devtools", never], ["zustand/persist", LayoutState]]
>(
  devtools(
    persist(
      (set) => ({
        ...layoutInitialState,

        toggleAside: (newState?: boolean) =>
          set((state) => ({
            ...state,
            visibleAside: newState ?? !state.visibleAside,
          })),
        setIsCollapsed: (newState: boolean) =>
          set((state) => ({
            ...state,
            isCollapsed: newState,
          })),
        setCommitHash: (commitHash: string) =>
          set((state) => ({
            ...state,
            commitHash,
          })),

        setFeatureFlags: (flags: Record<string, boolean>) =>
          set((state) => ({
            ...state,
            flags,
          })),

        resetStore: () => set(() => layoutInitialState),
      }),
      {
        name: `${Config.sessionKey}-layout@${AppVersion}`,
        storage: createJSONStorage<LayoutState>(() => customStorage),
      }
    )
  )
);
