import { useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CFormInput,
  CForm,
  CFormSelect,
} from "@coreui/react-pro";
import {
  Column,
  Item,
} from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router";
import Api from "src/api";
import { PaymentStatus } from "src/api/companies";
import { Customer } from "src/api/customers";
import Pagination from "src/components/Pagination/Pagination";
import FeatureAlert from "src/containers/FeatureAlert";
import { logEvent } from "src/helpers/analytics";
import { formatCurrency } from "src/helpers/numbers";
import { getCompanyPaymentStatus } from "src/helpers/payments";
import { isEmptyOrNull, queryStringToObject } from "src/helpers/strings";
import { usePagination } from "src/hooks/pagination";
import { useUserStore } from "src/store/users";
import { GraphQLMeta, SearchForm } from "src/types";

const CustomersScreen = () => {
  const { currentCompany } = useUserStore();
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const {
    data: customers,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Customer>>(Api.Customers.LIST_CUSTOMERS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        trashed: querySearch.trashed ? Number(querySearch.trashed) : 0,
        search: querySearch.search ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination(
    "customers",
    refetch
  );

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: querySearch.search ?? "",
      trashed: querySearch.trashed ? Number(querySearch.trashed) : 0,
    },

    onSubmit: (input) => {
      if (!loading) {
        logEvent(`customers.search`, {
          input,
        });

        resetAndSearch({
          search: input.search,
          page: 1,
          trashed: Number(input.trashed),
        });
      }
    },
  });

  const isDebtor =
    getCompanyPaymentStatus(currentCompany?.paymentStatus) ===
    PaymentStatus.DEBTOR;

  const hasCheckingAccounts = customers?.data.data.some(
    (c) => c.accounts.length > 0
  );
  const columns: Column[] = [];

  if (hasCheckingAccounts) {
    columns.push({
      key: "balance",
      label: "Balance",
      _props: { className: "text-right" },
    });
  }

  return (
    <>
      <FeatureAlert />

      {!isDebtor && (
        <CRow>
          <CCol xl={12}>
            <CCard>
              <CCardHeader>
                <CRow className="align-items-center justify-content-center">
                  <CCol sm={9} className="px-0">
                    Clientes
                  </CCol>
                  <CCol sm={3} className="row justify-content-end">
                    <Link
                      to="/customers/new"
                      className="flex justify-content-end p-0"
                    >
                      <CButton
                        color="primary"
                        size="sm"
                        onClick={() => {
                          logEvent("customers.create");
                        }}
                      >
                        Crear Cliente
                      </CButton>
                    </Link>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CForm onSubmit={formik.handleSubmit} className="mb-3">
                  <CRow className="align-items-center justify-content-center">
                    <CCol
                      sm={12}
                      className="row justify-content-end"
                      style={{ flexWrap: "nowrap" }}
                    >
                      <CFormInput
                        placeholder="Buscar..."
                        name="search"
                        defaultValue={formik.values.search}
                        onChange={formik.handleChange}
                        style={{ flex: 1 }}
                      />
                      <CFormSelect
                        name="trashed"
                        defaultValue={formik.values.trashed}
                        onChange={formik.handleChange}
                        className="my-select"
                        style={{
                          flex: 0.2,
                          marginLeft: 12,
                        }}
                      >
                        <option value={-1}>Todos</option>
                        <option value={0}>Activos</option>
                        {hasCheckingAccounts && (
                          <option value={2}>Deudores</option>
                        )}
                        <option value={1}>Eliminados</option>
                      </CFormSelect>
                      <CButton
                        style={{ width: 70, marginLeft: 12 }}
                        type="submit"
                        size="sm"
                        color="primary"
                      >
                        Buscar
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>

                <CSmartTable
                  itemsPerPage={20}
                  loading={loading}
                  items={
                    customers?.data.data.map((customer) => ({
                      ...customer,
                      _props: {
                        color: customer.deletedAt ? "danger" : "default",
                      },
                    })) || []
                  }
                  columns={[
                    { key: "id", label: "ID" },
                    { key: "utid", label: "CUIT / CUIL / DNI" },
                    {
                      key: "name",
                      label: "Nombre y Apellido (alias)",
                    },
                    { key: "email", label: "Email" },
                    { key: "sales", label: "Cant. de Compras" },
                    ...columns,
                  ]}
                  scopedColumns={{
                    name: (item: Customer) => (
                      <td>
                        {[item.name, item.lastname].filter((a) => a).join(" ")}
                        {!isEmptyOrNull(item.alias) ? ` (${item.alias})` : ""}
                      </td>
                    ),
                    sales: (item: Customer) => (
                      <td>{item.sales.length} compra(s)</td>
                    ),
                    balance: (item: Customer) => (
                      <td className="text-right">
                        {item.accounts?.length > 0
                          ? formatCurrency(item.accounts[0].balance)
                          : "-"}
                      </td>
                    ),
                  }}
                  tableProps={{
                    striped: formik.values.trashed !== 1,
                  }}
                  clickableRows
                  onRowClick={(item: Item) => {
                    logEvent("suppliers.navigate", {
                      date: item,
                    });

                    navigate(`/customers/${item.id}`);
                  }}
                />

                <Pagination
                  meta={customers}
                  page={page}
                  pageChange={pageChange}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      )}
    </>
  );
};

export default CustomersScreen;
