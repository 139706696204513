import {
  BuildingStorefrontIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { Store, StoreType } from "src/api/stores";
import {
  MercadoShops,
  MercadoLibre,
  TiendaNube,
  Rappi,
} from "src/components/Icon";

export const parsePointOfSaleType = (type: StoreType) =>
  findPointOfSale(type)?.label ?? "";

export const findPointOfSale = (type: StoreType) =>
  storeTypes.find((t) => t.id === type);

export const storeTypes = [
  {
    id: StoreType.Store,
    label: "Tienda Fisica",
    icon: <BuildingStorefrontIcon height={24} width={24} />,
  },
  {
    id: StoreType.Digital,
    label: "Tienda Digital",
    icon: <GlobeAltIcon height={24} width={24} />,
  },
  {
    id: StoreType.TiendaNube,
    label: "Tienda Nube",
    icon: <TiendaNube size={24} />,
  },
  {
    id: StoreType.MercadoLibre,
    label: "Mercado Libre",
    icon: <MercadoLibre size={24} />,
  },
  {
    id: StoreType.MercadoShops,
    label: "Mercado Shops",
    icon: <MercadoShops size={24} />,
  },
  {
    id: StoreType.Rappi,
    label: "Rappi",
    icon: <Rappi size={24} />,
  },
];

export const getStoreIcon = (store: Pick<Store, "type">) => {
  const currentType = findPointOfSale(store.type);

  return () => currentType?.icon;
};
