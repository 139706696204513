import Audit from "./views/audits/Audit";

import Dashboard from "./views/dashboard/Dashboard";
import DashboardToday from "./views/dashboard/DashboardToday";

import BankPromotions from "./views/bank-promotions/BankPromotions";
import BankPromotion from "./views/bank-promotions/BankPromotion";
import NewBankPromotion from "./views/bank-promotions/NewBankPromotion";

import Promotions from "./views/promotions/Promotions";
import Promotion from "./views/promotions/Promotion";
import NewPromotion from "./views/promotions/NewPromotion";

import Categories from "./views/categories/Categories";
import Category from "./views/categories/Category";
import NewCategory from "./views/categories/NewCategory";
import CategoryProducts from "./views/categories/CategoryProducts";

import Customers from "./views/customers/Customers";
import Customer from "./views/customers/Customer";
import NewCustomer from "./views/customers/NewCustomer";

import Plans from "./views/plans/Plans";
import Plan from "./views/plans/Plan";
import MyPlan from "./views/plans/MyPlan";
import NewPlanInvoice from "./views/plans/NewPlanInvoice";

import Rappi from "./views/rappi/Rappi";
import RappiSales from "./views/rappi/RappiSales";
import RappiAudit from "./views/rappi/RappiAudit";

import TiendaNube from "./views/tienda-nube/TiendaNube";
import TiendaNubeSales from "./views/tienda-nube/TiendaNubeSales";
import TiendaNubeSale from "./views/tienda-nube/TiendaNubeSale";
import TiendaNubeAudit from "./views/tienda-nube/TiendaNubeAudit";
import TiendaNubeWebhooks from "./views/tienda-nube/TiendaNubeWebhooks";

import Groups from "./views/permissions/Groups";
import Group from "./views/permissions/Group";
import NewGroup from "./views/permissions/NewGroup";

import Companies from "./views/companies/Companies";
import Company from "./views/companies/Company";
import NewCompany from "./views/companies/NewCompany";

import Stores from "./views/stores/Stores";
import Store from "./views/stores/Store";
import NewStore from "./views/stores/NewStore";

import Registers from "./views/registers/Registers";
import Register from "./views/registers/Register";
import RegisterSession from "./views/registers/RegisterSession";
import NewRegister from "./views/registers/NewRegister";

import PriceLists from "./views/price-lists/PriceLists";
import PriceList from "./views/price-lists/PriceList";
import NewPriceList from "./views/price-lists/NewPriceList";

import Favorites from "./views/products/Favorites";
import Products from "./views/products/Products";
import Product from "./views/products/Product";
import NewProduct from "./views/products/NewProduct";

import Invoicing from "./views/invoicing/Invoicing";
import Certificates from "./views/invoicing/Certificates";

import MercadoLibre from "./views/meli/MercadoLibre";
import MercadoLibreSale from "./views/meli/MercadoLibreSale";
import MercadoLibreSales from "./views/meli/MercadoLibreSales";
import MercadoLibreAudit from "./views/meli/MercadoLibreAudit";

import MercadoShops from "./views/meli/MercadoShops";
import MercadoShopsSale from "./views/meli/MercadoShopsSale";
import MercadoShopsSales from "./views/meli/MercadoShopsSales";

import MercadoPago from "./views/meli/MercadoPago";

import MPNewStore from "./views/meli/NewStore";

import Users from "./views/users/Users";
import User from "./views/users/User";
import NewUser from "./views/users/NewUser";

import Support from "./views/support/Support";
import SupportRequests from "./views/support/SupportRequests";

import Versions from "./views/versions/Versions";
import UTID from "./views/utid/Utid";

import Quotes from "./views/quotes/Quotes";
import Quote from "./views/quotes/Quote";
import NewQuote from "./views/quotes/NewQuote";

// import Invoices from "./views/invoices/Invoices";

import Sales from "./views/sales/Sales";
import Sale from "./views/sales/Sale";
import NewSale from "./views/sales/NewSale";

import Orders from "./views/orders/Orders";
import Order from "./views/orders/Order";

import Suppliers from "./views/suppliers/Suppliers";
import Supplier from "./views/suppliers/Supplier";
import NewSupplier from "./views/suppliers/NewSupplier";
import SupplierProducts from "./views/suppliers/SupplierProducts";
import SupplierShipments from "./views/suppliers/SupplierShipments";
import Shipment from "./views/suppliers/Shipment";

import Reports from "./views/reports/Reports";

import Sectors from "./views/sectors/Sectors";
import NewSector from "./views/sectors/NewSector";
import Sector from "./views/sectors/Sector";

import Settings from "./views/settings/Settings";

import Stock from "./views/stock/Stock";
import StockAddList from "./views/stock/StockAddList";
import StockMoveList from "./views/stock/StockMoveList";
import StockRetireList from "./views/stock/StockRetireList";

import BatchList from "./views/stock/components/BatchList";
import MoveList from "./views/stock/components/MoveList";
import RetireList from "./views/stock/components/RetireList";

import Warehouses from "./views/warehouses/Warehouses";
import Warehouse from "./views/warehouses/Warehouse";
import NewWarehouse from "./views/warehouses/NewWarehouse";
import Methods from "./views/registers/Methods";
import NewMethod from "./views/registers/NewMethod";
import StockDetailScreen from "./views/stock/StockDetail";
import { useUserStore } from "./store/users";

const { user } = useUserStore.getState();

const routes = [
  { path: "/", name: "Admin", helpVideo: "" },
  { path: "/audit", name: "Auditoría", component: Audit, helpVideo: "" },
  { path: "/home", name: "Hoy", component: DashboardToday, helpVideo: "" },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    helpVideo: "",
  },
  {
    path: "/categories/:id/products",
    name: "Productos de Categoría",
    component: CategoryProducts,
    helpVideo: "",
  },
  {
    path: "/categories/:id",
    name: "Categoría",
    component: Category,
    helpVideo: "",
  },
  {
    path: "/categories/new",
    name: "Nueva Categoría",
    component: NewCategory,
    helpVideo: "",
  },
  {
    path: "/categories",
    name: "Categorías",
    component: Categories,
    helpVideo: "",
  },
  {
    path: "/customers/new",
    name: "Nuevo Cliente",
    component: NewCustomer,
    helpVideo: "",
  },
  {
    path: "/customers/:id",
    name: "Cliente",
    component: Customer,
    helpVideo: "",
  },
  { path: "/customers", name: "Clientes", component: Customers, helpVideo: "" },
  {
    path: "/suppliers/:id/products",
    name: "Productos de Proveedor",
    component: SupplierProducts,
    helpVideo: "",
  },
  {
    path: "/suppliers/:id/shipments/:shipmentId",
    name: "Pedido a Proveedor",
    component: Shipment,
    helpVideo: "",
  },
  {
    path: "/suppliers/:id/shipments",
    name: "Pedidos a Proveedor",
    component: SupplierShipments,
    helpVideo: "",
  },
  {
    path: "/suppliers/:id",
    name: "Proveedor",
    component: Supplier,
    helpVideo: "",
  },
  {
    path: "/suppliers/new",
    name: "Nuevo Proveedor",
    component: NewSupplier,
    helpVideo: "",
  },
  {
    path: "/suppliers",
    name: "Proveedores",
    component: Suppliers,
    helpVideo: "",
  },
  {
    path: "/stock/batches",
    name: "Ingresos",
    component: StockAddList,
    helpVideo: "",
  },
  {
    path: "/stock/:warehouseId/batches",
    name: "Ingresos",
    component: StockAddList,
    helpVideo: "",
  },
  {
    path: "/stock/:warehouseId/batches/:batchId",
    name: "Ingresos",
    component: BatchList,
    helpVideo: "",
  },
  {
    path: "/stock/retires",
    name: "Egresos",
    component: StockRetireList,
    helpVideo: "",
  },
  {
    path: "/stock/:warehouseId/products/:productId",
    name: "Detalle de Movimientos",
    component: StockDetailScreen,
    helpVideo: "",
  },
  {
    path: "/stock/:warehouseId/retires",
    name: "Egresos",
    component: StockRetireList,
    helpVideo: "",
  },
  {
    path: "/stock/:warehouseId/retires/:retireId",
    name: "Egresos",
    component: RetireList,
    helpVideo: "",
  },
  {
    path: "/stock/moves",
    name: "Mover Stock",
    component: StockMoveList,
    helpVideo: "",
  },
  {
    path: "/stock/:warehouseId/moves",
    name: "Mover Stock",
    component: StockMoveList,
    helpVideo: "",
  },
  {
    path: "/stock/:warehouseId/moves/:moveId",
    name: "Mover Stock",
    component: MoveList,
    helpVideo: "",
  },
  { path: "/stock", name: "Stock", component: Stock, helpVideo: "" },
  {
    path: "/companies",
    name: "Empresas",
    component: Companies,
    helpVideo: "",
  },
  {
    path: "/companies/new",
    name: "Nueva Empresa",
    component: NewCompany,
    helpVideo: "",
  },
  {
    path: "/stores/new",
    name: "Nueva Tienda",
    component: NewStore,
    helpVideo: "",
  },
  {
    path: "/stores/:id",
    name: "Tienda",
    component: Store,
    helpVideo: "",
  },
  {
    path: "/invoicing",
    name: "Factura Electrónica",
    component: Invoicing,
    helpVideo: "",
  },
  {
    path: "/invoicing/certificates",
    name: "Certificados AFIP",
    component: Certificates,
    helpVideo: "",
  },
  {
    path: "/registers",
    name: "Cajas Registradoras",
    component: Registers,
    helpVideo: "",
  },
  {
    path: "/registers/methods/new",
    name: "Nuevo Método de Ingreso / Egreso",
    component: NewMethod,
    helpVideo: "",
  },
  {
    path: "/registers/methods",
    name: "Métodos de Ingreso / Egreso",
    component: Methods,
    helpVideo: "",
  },
  {
    path: "/registers/new",
    name: "Nueva Caja Registradora",
    component: NewRegister,
    helpVideo: "",
  },
  {
    path: "/registers/:id",
    name: "Caja Registradora",
    component: Register,
    helpVideo: "",
  },
  {
    path: "/registers/:registerId/sessions/:id",
    name: "Transacciones",
    component: RegisterSession,
    helpVideo: "",
  },
  {
    path: "/companies/:id",
    name: "Empresa",
    component: Company,
    helpVideo: "",
  },
  {
    path: "/groups",
    name: "Grupos de Permisos",
    component: Groups,
    helpVideo: "",
  },
  {
    path: "/groups/new",
    name: "Nuevo Grupo de Permisos",
    component: NewGroup,
    helpVideo: "",
  },
  {
    path: "/groups/:id",
    name: "Grupo de Permisos",
    component: Group,
    helpVideo: "",
  },
  {
    path: "/plans",
    name: "Planes",
    component: Plans,
    helpVideo: "",
  },
  {
    path: "/plans/invoice/new",
    name: "Crear Factura",
    component: NewPlanInvoice,
    helpVideo: "",
  },
  {
    path: "/plans/:id",
    name: "Plan",
    component: Plan,
    helpVideo: "",
  },
  {
    path: "/plan",
    name: "Tu plan",
    component: MyPlan,
    helpVideo: "",
  },
  {
    path: "/mercado-libre/:storeId/products",
    name: "Auditoría de Productos",
    component: MercadoLibreAudit,
    helpVideo: "",
  },
  {
    path: "/mercado-libre/:storeId/sales",
    name: "Ventas de Mercado Libre",
    component: MercadoLibreSales,
    helpVideo: "",
  },
  {
    path: "/mercado-libre/:storeId/sales/:orderId",
    name: "Venta de Mercado Libre",
    component: MercadoLibreSale,
    helpVideo: "",
  },
  {
    path: "/mercado-libre",
    name: "Ventas de Mercado Libre",
    component: MercadoLibre,
    helpVideo: "",
  },
  {
    path: "/mercado-shops/:storeId/sales",
    name: "Ventas de Mercado Shops",
    component: MercadoShopsSales,
    helpVideo: "",
  },
  {
    path: "/mercado-shops/:storeId/sales/:orderId",
    name: "Venta de Mercado Shops",
    component: MercadoShopsSale,
    helpVideo: "",
  },
  {
    path: "/mercado-shops",
    name: "Mercado Shops",
    component: MercadoShops,
    helpVideo: "",
  },
  {
    path: "/mercado-pago",
    name: "Mercado Pago",
    component: MercadoPago,
    helpVideo: "",
  },
  {
    path: "/mercado-pago/stores/new",
    name: "Mercado Pago - Nueva Tienda",
    component: MPNewStore,
    helpVideo: "",
  },
  {
    path: "/price-lists",
    name: "Listas de Precios",
    component: PriceLists,
    helpVideo: "",
  },
  {
    path: "/price-lists/new",
    name: "Nueva Lista de Precios",
    component: NewPriceList,
    helpVideo: "",
  },
  {
    path: "/price-lists/:id",
    name: "Detalles de la Lista de Precios",
    component: PriceList,
    helpVideo: "",
  },
  { path: "/products", name: "Productos", component: Products, helpVideo: "" },
  {
    path: "/products/new",
    name: "Nuevo Producto",
    component: NewProduct,
    helpVideo: "",
  },
  {
    path: "/products/favorites",
    name: "Productos Favoritos",
    component: Favorites,
    helpVideo: "",
  },
  {
    path: "/products/:id",
    name: "Detalles del Producto",
    component: Product,
    helpVideo: "",
  },
  // {
  //   path: "/invoices",
  //   name: "Facturas",
  //   component: Invoices,
  //   helpVideo: "",
  // },
  {
    path: "/sales",
    name: "Ventas",
    component: Sales,
    helpVideo: "",
  },
  {
    path: "/sales/:id",
    name: "Venta",
    component: Sale,
    helpVideo: "",
  },
  {
    path: "/sales/new",
    name: "Nueva Venta",
    component: NewSale,
    helpVideo: "",
  },
  {
    path: "/settings",
    name: "Configuraciones",
    component: Settings,
    helpVideo: "",
  },
  {
    path: "/promotions/new",
    name: "Crear Promoción",
    component: NewPromotion,
    helpVideo: "",
  },
  {
    path: "/promotions/:id",
    name: "Promoción",
    component: Promotion,
    helpVideo: "",
  },
  {
    path: "/promotions",
    name: "Promociones",
    component: Promotions,
    helpVideo: "",
  },
  {
    path: "/banks/new",
    name: "Crear Promoción Bancaria",
    component: NewBankPromotion,
    helpVideo: "",
  },
  {
    path: "/banks/:id",
    name: "Promoción Bancaria",
    component: BankPromotion,
    helpVideo: "",
  },
  {
    path: "/banks",
    name: "Promociones Bancarias",
    component: BankPromotions,
    helpVideo: "",
  },
  {
    path: "/quotes/:id",
    name: "Presupuesto",
    component: Quote,
    helpVideo: "",
  },
  {
    path: "/quotes/new",
    name: "Crear Presupuesto",
    component: NewQuote,
    helpVideo: "",
  },
  {
    path: "/quotes",
    name: "Presupuestos",
    component: Quotes,
    helpVideo: "",
  },
  {
    path: "/stores",
    name: "Puntos de Venta",
    component: Stores,
    helpVideo: "",
  },
  {
    path: "/rappi/:storeId/sales",
    name: "Ventas en Rappi",
    component: RappiSales,
    helpVideo: "",
  },
  {
    path: "/rappi/:storeId/products",
    name: "Auditoría de Productos",
    component: RappiAudit,
    helpVideo: "",
  },
  {
    path: "/rappi",
    name: "Rappi",
    component: Rappi,
    helpVideo: "",
  },
  {
    path: "/tienda-nube/:storeId/webhooks",
    name: "Webhooks",
    component: TiendaNubeWebhooks,
    helpVideo: "",
  },
  {
    path: "/tienda-nube/:storeId/products",
    name: "Auditoría de Productos",
    component: TiendaNubeAudit,
    helpVideo: "",
  },
  {
    path: "/tienda-nube/:storeId/sales/:orderId",
    name: "Venta de Tienda Nube",
    component: TiendaNubeSale,
    helpVideo: "",
  },
  {
    path: "/tienda-nube/:storeId/sales",
    name: "Ventas en Tienda Nube",
    component: TiendaNubeSales,
    helpVideo: "",
  },
  {
    path: "/tienda-nube",
    name: "Tienda Nube",
    component: TiendaNube,
    helpVideo: "",
  },
  {
    path: "/orders",
    name: "Pedidos",
    component: Orders,
    helpVideo: "",
  },
  {
    path: "/orders/:id",
    name: "Pedido",
    component: Order,
    helpVideo: "",
  },
  {
    path: "/reports",
    name: "Reportes",
    component: Reports,
    helpVideo: "",
  },
  {
    path: "/sectors",
    name: "Rubros",
    component: Sectors,
    helpVideo: "",
  },
  {
    path: "/sectors/new",
    name: "Nuevo Rubro",
    component: NewSector,
    helpVideo: "",
  },
  {
    path: "/sectors/:id",
    name: "Rubro",
    component: Sector,
    helpVideo: "",
  },
  {
    path: "/users",
    name: user?.isAdmin ? "Usuarios" : "Empleados",
    component: Users,
    helpVideo: "",
  },
  {
    path: "/users/new",
    name: user?.isAdmin ? "Nuevo Usuario" : "Nuevo Empleado",
    component: NewUser,
    helpVideo: "",
  },
  {
    path: "/users/:id",
    name: user?.isAdmin ? "Detalles del Usuario" : "Detalles del Empleado",
    component: User,
    helpVideo: "",
  },
  {
    path: "/warehouses/new",
    name: "Nuevo Depósito",
    component: NewWarehouse,
    helpVideo: "",
  },
  {
    path: "/warehouses/:id",
    name: "Depósito",
    component: Warehouse,
    helpVideo: "",
  },
  {
    path: "/warehouses",
    name: "Depósitos",
    component: Warehouses,
    helpVideo: "",
  },
  {
    path: "/versions",
    name: "Versiones",
    component: Versions,
    helpVideo: "",
  },
  {
    path: "/utid",
    name: "Buscador de CUIT",
    component: UTID,
    helpVideo: "",
  },
  {
    path: "/support/:id",
    name: "Incidencia",
    component: Support,
    helpVideo: "",
  },
  {
    path: "/support",
    name: "Incidencias",
    component: SupportRequests,
    helpVideo: "",
  },
  {
    path: "/me",
    name: "Mi perfil",
    component: User,
    helpVideo: "",
  },
];

export default routes;
