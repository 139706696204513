import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CDatePicker,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useNavigate } from "react-router";
import Api from "src/api";
import { useMutation, useQuery } from "@apollo/client";
import { GraphQLMeta } from "src/types";
import { Company } from "src/api/companies";
import { AppLoader } from "src/components/Loader/Loader";
import { Plan } from "src/api/plans";
import SmartSelect from "src/components/SmartSelect";
import { sortBy } from "lodash";
import { useFormik } from "formik";
import { formatCurrency } from "src/helpers/numbers";
import { dateFormat } from "src/helpers/dates";
import yup, { getValidity } from "src/helpers/validation";
import { startOfMonth } from "date-fns/startOfMonth";
import { endOfMonth } from "date-fns/endOfMonth";
import { useUserStore } from "src/store/users";

export const CreatePlanInvoiceSchema = yup.object().shape({
  companyId: yup.number().min(1).required(),
  planId: yup.number().min(1).required(),
  price: yup.number().min(0).required(),
  dateFrom: yup.date().required(),
  dateTo: yup.date().required(),
});

const NewPlanInvoice = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();

  const [createMutation, { loading: creating }] = useMutation(
    Api.Plans.CREATE_SALE_INVOICE,
    {
      onCompleted: () => {},
      onError: (e) => {
        if (e.message === "plan_already_exists") {
          alert(
            "Ya existe una factura para esta empresa que colapsa con ese periodo"
          );
        }
      },
    }
  );

  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        limit: 0,
      },
    }
  );

  const { data: plans } = useQuery<GraphQLMeta<Plan>>(Api.Plans.LIST_PLANS, {
    variables: {
      limit: 0,
    },
  });

  if (!user?.isAdmin) {
    navigate("/");
  }

  const formik = useFormik({
    initialValues: {
      companyId: 0,
      planId: 1,
      price: 0,
      dateFrom: dateFormat(startOfMonth(new Date()), "yyyy-MM-dd"),
      dateTo: dateFormat(endOfMonth(new Date()), "yyyy-MM-dd"),
    },
    onSubmit: (data) => {
      if (!creating) {
        createMutation({
          variables: {
            companyId: Number(data.companyId),
            planId: Number(data.planId),
            price: Number(data.price),
            dateFrom: data.dateFrom,
            dateTo: data.dateTo,
          },
        });
      }
    },
    validationSchema: CreatePlanInvoiceSchema,
  });

  if (!user?.isAdmin || !companies?.data.data || !plans?.data.data) {
    return <AppLoader />;
  }

  return (
    <CRow>
      <CCol xl={12}>
        <CForm onSubmit={formik.handleSubmit}>
          <CCard>
            <CCardHeader>Crear Factura</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol
                  sm={3}
                  className={!!formik.errors.companyId ? "select-invalid" : ""}
                >
                  <CFormLabel>Empresa</CFormLabel>
                  <SmartSelect
                    name="companyId"
                    search
                    options={
                      sortBy(companies?.data.data ?? [], "name").map(
                        (company) => ({
                          name: `${company.id} - ${company.name}`,
                          value: company.id,
                        })
                      ) ?? []
                    }
                    resultsCount={10}
                    placeholder="Selecciona Empresa"
                    onChange={(e) => {
                      formik.setFieldValue("companyId", Number(e));
                    }}
                    defaultValue={formik.values.companyId.toString()}
                  />
                </CCol>
                <CCol
                  sm={3}
                  className={!!formik.errors.planId ? "select-invalid" : ""}
                >
                  <CFormLabel>Plan</CFormLabel>
                  <SmartSelect
                    name="planId"
                    search
                    options={
                      sortBy(plans?.data.data ?? [], "id").map((plan) => ({
                        name: `${plan.name} - ${formatCurrency(plan.price)}`,
                        value: plan.id,
                      })) ?? []
                    }
                    resultsCount={10}
                    placeholder="Selecciona Plan"
                    onChange={(e) => {
                      const plan = plans?.data.data?.find(
                        (p) => p.id === Number(e)
                      );

                      if (plan) {
                        formik.setFieldValue("planId", plan.id);
                        formik.setFieldValue("price", plan.price);
                      }
                    }}
                    defaultValue={formik.values.planId.toString()}
                    invalid={!!formik.errors.planId}
                  />
                </CCol>
                <CCol sm={2}>
                  <CFormLabel>Precio</CFormLabel>
                  <CFormInput
                    name="price"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.price}
                    {...getValidity(formik.values.price, formik.errors.price)}
                  />
                </CCol>
                <CCol sm={2}>
                  <CFormLabel>Fecha de Inicio</CFormLabel>
                  <CDatePicker
                    onDateChange={(e) => {
                      if (e) {
                        formik.setFieldValue(
                          "dateFrom",
                          dateFormat(e, "yyyy-MM-dd")
                        );
                      }
                    }}
                    date={formik.values.dateFrom}
                    {...getValidity(
                      formik.values.dateFrom,
                      formik.errors.dateFrom
                    )}
                  />
                </CCol>
                <CCol sm={2}>
                  <CFormLabel>Fecha de Fin</CFormLabel>
                  <CDatePicker
                    onDateChange={(e) => {
                      if (e) {
                        formik.setFieldValue(
                          "dateTo",
                          dateFormat(e, "yyyy-MM-dd")
                        );
                      }
                    }}
                    date={formik.values.dateTo}
                    {...getValidity(formik.values.dateTo, formik.errors.dateTo)}
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CRow>
                <CCol sm={4}></CCol>
                <CCol sm={8} className="text-right">
                  <CLoadingButton
                    size="sm"
                    color="primary"
                    loading={creating}
                    disabled={!formik.isValid || creating}
                    type="submit"
                  >
                    Crear
                  </CLoadingButton>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  );
};

export default NewPlanInvoice;
