import { AppVersion } from "src/types";
import Config from "src/config";
import { useLayoutStore } from "./layout";
import { useUserStore } from "./users";
import { useCallback } from "react";

const clearOldKeys = () => {
  const envKey = Config.sessionKey;
  const currentKey = `${Config.sessionKey}@${AppVersion}`;

  const keys = Object.keys(localStorage).filter(
    (key) => key.includes(envKey) && key !== currentKey
  );

  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const useGenericStore = () => {
  const layoutStore = useLayoutStore();
  const userStore = useUserStore();

  const resetStore = useCallback(() => {
    layoutStore.resetStore();
    userStore.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    resetStore,
  };
};

clearOldKeys();
