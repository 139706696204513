import { useQuery } from "@apollo/client";
import {
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CCard,
} from "@coreui/react-pro";
import { useNavigate, useParams } from "react-router";
import Api from "src/api";
import { SupportRequest } from "src/api/support";
import { Loader } from "src/components/Loader/Loader";
import { dateFormat } from "src/helpers/dates";
import { useUserStore } from "src/store/users";

import { GraphQLFind } from "src/types";

const SupportScreen = () => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const params = useParams();
  let supportId = Number(params.id);

  if (!supportId || !user?.isAdmin) {
    navigate("/support");
  }

  const { data: support } = useQuery<GraphQLFind<SupportRequest>>(
    Api.Support.GET_SUPPORT,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: supportId,
      },
      onError: () => {
        navigate(-1);
      },
    }
  );

  if (!support?.data) {
    return <Loader />;
  }

  return (
    <CCard>
      <Form support={support.data} />
    </CCard>
  );
};

const Form = ({ support }: { support: SupportRequest }) => {
  return (
    <CForm>
      <CCardHeader>
        <CRow>
          <CCol sm={5}>
            Incidencia: #{support.id.toString().padStart(6, "0")}
          </CCol>
          <CCol sm={7} className="text-right">
            Ultima Actualizacion:{" "}
            {dateFormat(support.updatedAt, "dd/MM/yyyy HH:mm")}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody></CCardBody>
    </CForm>
  );
};

export default SupportScreen;
