const SvgComponent = ({
  containerClassName,
  size = 24,
}: {
  containerClassName?: string;
  size: number;
}) => (
  <svg
    viewBox="0 0 596.11 562"
    height={size}
    width={size}
    className={containerClassName ?? ""}
  >
    <path
      d="M388.2 0H9.75C3.25 0 0 3.25 0 9.75v53.6c0 60.1 42.23 92.58 118.57 92.58h271.25c29.24 0 51.98 22.74 51.98 51.98s-22.74 51.98-51.98 51.98H84.46c-4.87 0-8.12 3.25-9.75 6.5L4.87 550.63c0 3.25 0 6.5 1.62 8.12s4.87 3.25 8.12 3.25h95.83c35.73 0 53.6-29.24 56.85-45.48l27.61-102.33h193.29c113.7 0 207.91-92.58 207.91-207.91S503.53 0 388.2 0Z"
      fill="#ed2f42"
    />
  </svg>
);
export default SvgComponent;
