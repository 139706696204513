import { useLocation, useNavigate } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CFormSelect,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CBadge,
} from "@coreui/react-pro";
import { useQuery } from "@apollo/client";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { useFormik } from "formik";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm, WithDate } from "src/types";
import Api from "src/api";

import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import { useCanNavigate } from "src/helpers/permissions";
import Pagination from "src/components/Pagination";
import { Plus } from "lucide-react";
import { Quote } from "src/api/quotes";
import { parseQuoteStatus } from "src/helpers/quotes";
import { useUserStore } from "src/store/users";

const Quotes = () => {
  const { hasPermission } = useUserStore();
  const querySearch = queryStringToObject(useLocation().search);
  const canSeeQuote = hasPermission("SHOW_QUOTE");
  const canCreateQuote = hasPermission("CREATE_QUOTE");
  const navigate = useNavigate();

  useCanNavigate("LIST_QUOTES");

  const {
    data: quotes,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Quote>>(Api.Quotes.LIST_QUOTES, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        trashed: querySearch.trashed ? Number(querySearch.trashed) : -1,
        search: querySearch.search ?? "",
        date: querySearch.date ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination("quotes", refetch);

  const formik = useFormik<WithDate<SearchForm>>({
    initialValues: {
      date: querySearch.date,
      search: querySearch.search ?? "",
      trashed: Number(-1),
    },

    onSubmit: (input) => {
      if (!loading) {
        const query: Record<string, unknown> = {
          date: input.date ?? "",
          search: input.search ?? "",
          trashed: Number(input.trashed),
          page: 1,
        };

        resetAndSearch(query);
      }
    },
  });

  return (
    <>
      <PlanAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  Presupuestos
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="d-flex gap-2 align-items-center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar por ID"
                      name="search"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 1 }}
                    />

                    <CFormSelect
                      name="trashed"
                      onChange={formik.handleChange}
                      style={{ flex: 0.2, marginLeft: 12 }}
                    >
                      <option value={-1}>Todas</option>
                      <option value={1}>Eliminadas</option>
                      <option value={0}>Finalizadas</option>
                    </CFormSelect>
                    <CFormInput
                      name="date"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.date}
                      type="date"
                      style={{ flex: 0.2, marginLeft: 12 }}
                    />
                    <CDropdown
                      variant="btn-group"
                      className="pr-0"
                      alignment="end"
                    >
                      <CButton type="submit" size="sm" color="primary">
                        Buscar
                      </CButton>

                      {canCreateQuote && (
                        <>
                          <CDropdownToggle color="primary" split />

                          <CDropdownMenu>
                            <CDropdownItem href="/#/quotes/new">
                              <div className="d-flex gap-2 align-items-center justify-content-between">
                                <Plus size={18} />
                                Crear presupuesto
                              </div>
                            </CDropdownItem>
                          </CDropdownMenu>
                        </>
                      )}
                    </CDropdown>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                itemsPerPage={20}
                noItemsLabel="No hay presupuestos"
                items={
                  quotes?.data.data.map((quote) => ({
                    ...quote,
                    _props: { color: quote.deletedAt ? "danger" : "default" },
                  })) || []
                }
                columns={[
                  { key: "id", label: "ID" },
                  { key: "user", label: "Vendedor" },
                  { key: "customer", label: "Cliente" },
                  {
                    key: "amount",
                    label: "Monto",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "status",
                    label: "Estado",
                    _props: { className: "font-weight-bold text-center" },
                  },
                  {
                    key: "date",
                    label: "Fecha",
                    _props: { className: "font-weight-bold text-right" },
                  },
                ]}
                loading={loading}
                scopedColumns={{
                  user: (quote: Quote) => (
                    <td>
                      {quote.user?.name} {quote.user?.lastname}
                    </td>
                  ),
                  customer: (quote: Quote) => (
                    <td>
                      {quote.customer?.name} {quote.customer?.lastname}
                    </td>
                  ),
                  amount: (quote: Quote) => (
                    <td className="text-right">
                      {formatCurrency(
                        quote.items.reduce(
                          (prev, curr) => prev + curr.quantity * curr.price,
                          0
                        )
                      )}
                    </td>
                  ),
                  status: (quote: Quote) => {
                    const status = parseQuoteStatus(quote.status);

                    return (
                      <td className="text-center">
                        <CBadge color={status.color}>{status.label}</CBadge>
                      </td>
                    );
                  },
                  date: (quote: Quote) => (
                    <td className="text-right">
                      {dateFormat(quote.date, "dd/MM/yyyy HH:mm")}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: canSeeQuote,
                }}
                clickableRows={canSeeQuote}
                onRowClick={(quote) => {
                  if (canSeeQuote) {
                    navigate(`/quotes/${quote.id}`);
                  }
                }}
              />

              <Pagination meta={quotes} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Quotes;
