import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarHeader,
} from "@coreui/react-pro";
import useSidebar from "src/hooks/sidebar";
import { memo } from "react";
import { AppSidebarNav } from "./AppSidebarNav";
import { NavLink } from "react-router";
import classNames from "classnames";
import AppVersionButton from "src/components/AppVersionButton";
import { useLayoutStore } from "src/store/layout";

const TheSidebar = () => {
  const { top } = useSidebar();
  const { isCollapsed } = useLayoutStore();

  return (
    <CSidebar
      className={classNames("h-100 no-print border-end")}
      colorScheme="dark"
      visible={!isCollapsed}
    >
      <CSidebarHeader className="border-bottom">
        <CSidebarBrand as={NavLink} to="/home">
          <div className="sidebar-brand-full justify-content-center">
            <img alt="Daruma Logo" src="avatars/default.png" height="38" />
            <span className="h2 mb-0 ml-2 c-sidebar-brand-full">Daruma</span>
          </div>
        </CSidebarBrand>
      </CSidebarHeader>

      <AppSidebarNav items={top} />

      <CSidebarNav style={{ flex: "none", justifyContent: "flex-end" }}>
        <AppVersionButton />
      </CSidebarNav>
    </CSidebar>
  );
};

export default memo(TheSidebar);
