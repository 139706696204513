import { useQuery } from "@apollo/client";
import { CButton, CLink, CRow, CSmartTable, CTooltip } from "@coreui/react-pro";
import { ArrowRight, CheckIcon, FileDown, Send, XIcon } from "lucide-react";
import { useRef } from "react";
import Api from "src/api";
import { CheckingAccountReport } from "src/api/checkingAccounts";
import { Customer } from "src/api/customers";
import { AppLoader } from "src/components/Loader/Loader";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { getPaginationProps } from "src/hooks/pagination";
import { ForwardedModalProps, GraphQLMeta } from "src/types";
import SendReportModal from "./SendReportModal";

const CustomerReports = ({ customer }: { customer: Customer }) => {
  const checkingAccountId = customer.accounts[0]?.id ?? 0;
  const sendReportModalRef = useRef<ForwardedModalProps>(null);

  const {
    data: checking,
    loading,
    refetch,
  } = useQuery<GraphQLMeta<CheckingAccountReport>>(
    Api.CheckingAccounts.LIST_REPORTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        accountId: checkingAccountId,
      },
      skip: checkingAccountId === 0,
    }
  );

  if (checkingAccountId === 0 || !checking?.data) {
    return <AppLoader />;
  }

  return (
    <>
      <CRow>
        <CSmartTable
          itemsPerPage={20}
          items={checking?.data?.data ?? []}
          {...getPaginationProps(checking.data.data)}
          columns={[
            {
              key: "period",
              label: "Periodo",
            },
            {
              key: "sales",
              label: "# de Ventas",
              _props: { className: "text-center" },
            },
            {
              key: "items",
              label: "# de Items",
              _props: { className: "text-center" },
            },
            {
              key: "balance",
              label: "Balance",
              _props: { className: "text-center" },
            },
            {
              key: "emailSent",
              label: "Mail Enviado",
              _props: { className: "text-center" },
            },
            {
              key: "actions",
              label: "Acciones",
              _props: { className: "text-right" },
            },
          ]}
          loading={loading}
          scopedColumns={{
            period: (report: CheckingAccountReport) => (
              <td>
                {dateFormat(report.dateFrom, "dd/MM/yyyy")} al{" "}
                {dateFormat(report.dateTo, "dd/MM/yyyy")}
              </td>
            ),
            sales: (report: CheckingAccountReport) => (
              <td className="text-center">{report.salesCount}</td>
            ),
            items: (report: CheckingAccountReport) => (
              <td className="text-center">{report.itemsCount}</td>
            ),
            balance: (report: CheckingAccountReport) => (
              <td>
                <div className="d-flex align-items-center justify-content-center gap-2">
                  {formatCurrency(report.prevBalance)} <ArrowRight />
                  {formatCurrency(report.nextBalance)}
                </div>
              </td>
            ),
            emailSent: (report: CheckingAccountReport) => (
              <td align="center">
                {report.emailSent ? (
                  <CTooltip
                    content={`Enviado el ${dateFormat(
                      report.emailSent,
                      "dd/MM/yyyy HH:mm"
                    )}`}
                  >
                    <CheckIcon />
                  </CTooltip>
                ) : (
                  <XIcon />
                )}
              </td>
            ),
            actions: (report: CheckingAccountReport) => (
              <td className="text-right">
                <CTooltip content={<span>Enviar Reporte por Email</span>}>
                  <CButton
                    size="sm"
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      sendReportModalRef.current?.open(report);
                    }}
                  >
                    <Send color="white" />
                  </CButton>
                </CTooltip>

                <CTooltip content="Descargar Reporte">
                  <CLink target="_blank" href={report.pdfUrl}>
                    <CButton type="button" size="sm" color="primary">
                      <FileDown />
                    </CButton>
                  </CLink>
                </CTooltip>
              </td>
            ),
          }}
          tableProps={{
            striped: true,
          }}
        />
      </CRow>

      <SendReportModal ref={sendReportModalRef} refetch={refetch} />
    </>
  );
};

export default CustomerReports;
