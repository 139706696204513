import yup from "src/helpers/validation";
import { gql } from "@apollo/client";
import { Store } from "./stores";
import { Currency, RoundingConfig } from "src/types";
import { StockType } from "./stock";
import { CompanyPlan } from "./plans";
import request from "./request";

export const CompanySchema = yup.object().shape({
  name: yup.string().required(),
  legalName: yup.string().required(),
});

export enum CompanyPermission {
  Create = "CREATE_COMPANY",
}

export type CompanyPaymentStatus = {
  companyId: number;
  statuses: StorePaymentStatus[];
};

export type StorePaymentStatus = {
  companyId: number;
  storeId: number;
  paymentStatus: PaymentStatus;
};

export enum PaymentStatus {
  // Prueba
  TRIAL = "TRIAL",
  // Dia 1 a 7
  PENDING = "PENDING",
  // Dia 7 a 10
  WARNING = "WARNING",
  // Dia 10 a 15
  UNPAID = "UNPAID",
  // Dia 15+
  DEBTOR = "DEBTOR",
  // payedAt !== null
  PAID = "PAID",
  // not exists
  TERMINATED = "TERMINATED",
  // deletedAt IS NULL
  CANCELLED = "CANCELLED",
  // dateFrom > now
  FUTURE = "FUTURE",
}

export type CompanyConfig = RoundingConfig & {
  profit: number;
  stock: StockType;
  defaultPaymentMethod: string;
  calculate: string;
  allowNegativeStock: boolean;
  currency: Currency;
};

export type Company = {
  id: number;
  name: string;
  legalName: string;
  streetNumber: string;
  streetName: string;
  logoUrl: string;
  description?: string;
  utid: string;
  province: string;
  city: string;
  zipCode: string;
  isFranchise: boolean;
  paymentStatus: CompanyPaymentStatus;
  config: CompanyConfig;
  stores: Store[];
  currentPlan: CompanyPlan;
};

export type CompanyWithStores = Company & {
  stores: Store[];
};

export type CompanyCreation = Omit<
  Company,
  "id" | "config" | "stores" | "paymentStatus" | "currentPlan" | "logoUrl"
>;

export type CompanyUpdate = CompanyCreation & Pick<Company, "paymentStatus">;

export const LIST_COMPANIES = gql`
  query getCompanies($filters: CompanyFilters) {
    data: companies(filters: $filters) {
      data {
        id
        description
        name
        legalName

        isFranchise

        streetName
        streetNumber
        province
        city

        currentPlan {
          type
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($id: Int!) {
    data: company(id: $id) {
      id
      name
      legalName
      utid
      streetName
      streetNumber
      province
      city
      zipCode

      description
      type
      isFranchise
      logoUrl

      currentPlan {
        type
      }

      paymentStatus {
        statuses {
          companyId
          storeId
          paymentStatus
        }
      }

      config {
        defaultPaymentMethod
        profit
        stock
        allowNegativeStock
        calculate
        currency
        rounding
        roundingMethod {
          ceil
          none
          floor
        }
      }

      stores {
        id
        name
        streetName
        streetNumber
        phone
        type

        sector {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    data: createCompany(input: $input) {
      id
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: Int!, $input: UpdateCompanyInput!) {
    data: updateCompany(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: Int!) {
    data: deleteCompany(id: $id) {
      id
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation updateCompanyConfig($id: Int!, $input: UpdateCompanyConfigInput!) {
    data: updateCompanyConfig(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_PRODUCTS = gql`
  mutation updateCompanyProductsConfig(
    $companyId: Int!
    $config: ProductsConfigInput!
  ) {
    data: updateCompanyProductsConfig(companyId: $companyId, config: $config) {
      id
    }
  }
`;

export const uploadLogo = async (companyId: number, body: FormData) =>
  request.put(`/imports/companies/${companyId}/logo`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
