import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
} from "@coreui/react-pro";
import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta } from "src/types";
import Api from "src/api";

import { logEvent } from "src/helpers/analytics";

import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import Pagination from "src/components/Pagination";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { SupportRequest } from "src/api/support";
import { dateFormat } from "src/helpers/dates";
import { Pencil } from "lucide-react";
import { useUserStore } from "src/store/users";

const SupportsScreen = () => {
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const { user } = useUserStore();

  const {
    data: support,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<SupportRequest>>(Api.Support.LIST_SUPPORTS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
      },
    },
  });
  const { page, pageChange } = usePagination("support", refetch);

  const fields: any[] = [
    { key: "id", label: "ID" },
    { key: "reporter", label: "Reportador por" },
    { key: "company", label: "Empresa" },
    { key: "store", label: "Punto de Venta" },
    { key: "register", label: "Caja Registradora" },
    { key: "status", label: "Estado" },
    { key: "notes", label: "Notas" },
    { key: "lastUpdate", label: "Actualizado" },
  ];

  if (user?.isAdmin) {
    fields.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right" },
    });
  }

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>Incidencias</CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={support?.data.data || []}
                columns={fields}
                tableProps={{
                  striped: true,
                }}
                scopedColumns={{
                  id: (item: SupportRequest) => (
                    <td>{item.id.toString().padStart(6, "0")}</td>
                  ),
                  reporter: (item: SupportRequest) => (
                    <td>
                      {item.reporter.name} {item.reporter.lastname}
                    </td>
                  ),
                  company: (item: SupportRequest) => (
                    <td>{item.company.name}</td>
                  ),
                  store: (item: SupportRequest) => <td>{item.store?.name}</td>,
                  register: (item: SupportRequest) => (
                    <td>{item.register?.name}</td>
                  ),
                  status: (item: SupportRequest) => <td>{item.status}</td>,
                  notes: (item: SupportRequest) => <td>{item.notes}</td>,
                  lastUpdate: (item: SupportRequest) => (
                    <td>{dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm")}</td>
                  ),
                  actions: (item: SupportRequest) => (
                    <td className="text-right">
                      <CButton
                        size="sm"
                        color="info"
                        onClick={(e) => {
                          navigate(`/Supports/${item.id}`);

                          e.stopPropagation();
                        }}
                      >
                        <Pencil color="white" />
                      </CButton>
                    </td>
                  ),
                }}
                clickableRows
                onRowClick={(item: Item) => {
                  logEvent("support.navigate", {
                    date: item,
                  });

                  navigate(`/support/${item.id}`);
                }}
              />

              <Pagination meta={support} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default SupportsScreen;
