import { useEffect } from "react";
import { logEvent } from "src/helpers/analytics";
import { getMaintenanceStatus } from "src/helpers/database";
import { useUserStore } from "src/store/users";
import { AppVersion } from "src/types";

const Maintenance = () => {
  const { user } = useUserStore();

  useEffect(() => {
    logEvent("maintenance", {
      currentVersion: AppVersion,
      service: "ADMIN",
      user: {
        id: user?.id,
        email: user?.email,
        name: user?.name,
      },
    });

    setTimeout(() => {
      logEvent("maintenance.auto-redirect", {
        currentVersion: AppVersion,
        service: "ADMIN",
        user: {
          id: user?.id,
          email: user?.email,
          name: user?.name,
        },
      });
    }, 5000);

    getMaintenanceStatus("/#/maintenance");
  }, [user]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        height: "100%",
        flexDirection: "column",
        paddingLeft: 16,
        paddingRight: 16,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        alt="Daruma Logo"
        src="avatars/default.png"
        height="152"
        style={{ rotate: "90deg" }}
      />

      <h2
        style={{
          fontSize: 24,
          marginTop: 12,
          paddingLeft: 32,
          paddingRight: 32,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Daruma se encuentra en mantenimiento
      </h2>
      <h5
        style={{
          fontSize: 20,
          marginTop: 12,
          paddingLeft: 32,
          paddingRight: 32,
          textAlign: "center",
          fontWeight: "600",
        }}
      >
        Lamentamos las molestias pero si ves esta pantalla es porque estamos
        haciendo tareas de mantenimiento para mejorar tu experiencia con el
        sistema. Vuelve a intentar en unos minutos.
      </h5>
    </div>
  );
};

export default Maintenance;
