import { Suspense, useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router";
import "./scss/style.scss";
import { newGraphQLClient } from "src/services/graphql";
import { ApolloProvider } from "@apollo/client";

import Login from "src/views/pages/login/Login";
import TheLayout from "src/containers/TheLayout";
import SocketProvider from "src/contexts/Socket";
import {
  getAdminVersion,
  getCommitVersion,
  getMaintenanceStatus,
} from "./helpers/database";
import Maintenance from "./views/Maintenance";
import MyEvents from "./helpers/myEvents";
import { useLayoutStore } from "./store/layout";
import { useUserStore } from "./store/users";
import { useGenericStore } from "./store";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Logout = () => {
  const { resetStore } = useGenericStore();

  useEffect(() => {
    resetStore();

    window.location.href = "/";
  }, [resetStore]);

  return null;
};

const App = () => {
  const { setCommitHash, setFeatureFlags } = useLayoutStore();
  const { user } = useUserStore();
  const GraphQLClient = newGraphQLClient(user);

  useEffect(() => {
    getAdminVersion();
    getCommitVersion();
    getMaintenanceStatus(`/${window.location.hash}`);
  }, []);

  useEffect(() => {
    MyEvents.addEventListener("commitHash", (e: { detail: string }) => {
      setCommitHash(e.detail);
    });
  }, [setCommitHash]);

  useEffect(() => {
    MyEvents.addEventListener(
      "featureFlags",
      (e: { detail: Record<string, boolean> }) => {
        setFeatureFlags(e.detail);
      }
    );
  }, [setFeatureFlags]);

  if (!user) {
    return (
      <ApolloProvider client={GraphQLClient}>
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </ApolloProvider>
    );
  }

  return (
    <ApolloProvider client={GraphQLClient}>
      <SocketProvider user={user}>
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/logout" element={<Logout />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/*" element={<TheLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </SocketProvider>
    </ApolloProvider>
  );
};

export default App;
