import { useLocation, useNavigate } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CFormSelect,
  CDropdownMenu,
  CDropdownItem,
  CDropdown,
  CDropdownToggle,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { PaymentMethod, Sale, SaleStatus } from "src/api/sales";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { parsePaymentMethods, paymentMethods } from "src/helpers/payments";

import { useFormik } from "formik";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm, WithDate } from "src/types";
import Api from "src/api";

import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import { useCanNavigate } from "src/helpers/permissions";
import Pagination from "src/components/Pagination";
import { Store, StoreType } from "src/api/stores";
import { sortBy, uniq } from "lodash";
import { FileText, Plus } from "lucide-react";
import SmartSelect, { SmartSelectType } from "src/components/SmartSelect";
import { useUserStore } from "src/store/users";
import { getStoreIcon } from "src/helpers/stores";

const Sales = () => {
  const { hasPermission, user, currentCompany } = useUserStore();
  const querySearch = queryStringToObject(useLocation().search);
  const canSeeSale = hasPermission("SHOW_SALE");
  const navigate = useNavigate();

  useCanNavigate("LIST_SALES");

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId: currentCompany?.id,
          permission: "LIST_SALES",
          types: [StoreType.Store, StoreType.TiendaNube],
          limit: 0,
        },
      },
    }
  );

  const {
    data: sales,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Sale>>(Api.Sales.LIST_SALES, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        trashed: querySearch.trashed ? Number(querySearch.trashed) : -1,
        search: querySearch.search ?? "",
        storeId: querySearch.storeId ? Number(querySearch.storeId) : null,
        paymentMethod: querySearch.paymentMethod
          ? querySearch.paymentMethod
          : undefined,
        date: querySearch.date ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination("sales", refetch);

  const formik = useFormik<
    WithDate<
      SearchForm & { storeId?: number | null; paymentMethod?: string | null }
    >
  >({
    initialValues: {
      date: querySearch.date,
      search: querySearch.search ?? "",
      trashed: Number(-1),
      storeId: querySearch.storeId ? Number(querySearch.storeId) : null,
      paymentMethod: querySearch.paymentMethod ?? "",
    },

    onSubmit: (input) => {
      if (!loading) {
        const query: Record<string, unknown> = {
          date: input.date ?? "",
          search: input.search ?? "",
          trashed: Number(input.trashed),
          page: 1,
        };

        if (input.storeId && Number(input.storeId) > 0) {
          query.storeId = Number(input.storeId);
        }

        if (input.paymentMethod) {
          query.paymentMethod = input.paymentMethod;
        }

        resetAndSearch(query);
      }
    },
  });

  return (
    <>
      <PlanAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  Ventas
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="d-flex gap-2 align-items-center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar por ID"
                      name="search"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 1 }}
                    />

                    <div style={{ flex: 0.5 }}>
                      <SmartSelect
                        type={SmartSelectType.Stores}
                        name="storeId"
                        search
                        value={formik.values.storeId?.toString()}
                        options={[
                          { value: -1, name: "Todos los Puntos de Venta" },
                          ...(sortBy(stores?.data.data ?? [], "name").map(
                            (store) => ({
                              name: user?.isAdmin
                                ? `${store.name} (${store.company.name})`
                                : store.name,
                              value: store.id,
                              type: store.type,
                            })
                          ) ?? []),
                        ]}
                        resultsCount={10}
                        placeholder="Selecciona Punto de Venta"
                        onChange={(e) => {
                          formik.setFieldValue("storeId", Number(e));
                        }}
                      />
                    </div>

                    <div style={{ flex: 0.5 }}>
                      <SmartSelect
                        type={SmartSelectType.PaymentMethods}
                        name="paymentMethod"
                        value={formik.values.paymentMethod?.toString()}
                        placeholder="Selecciona Método de Pago"
                        options={[
                          { value: 0, name: "Todos" },
                          ...(paymentMethods
                            ?.filter(({ id, digital }) => !digital && id > 0)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((paymentMethod) => ({
                              value: paymentMethod.type,
                              name: paymentMethod.name,
                              type: paymentMethod.type,
                            })) ?? []),
                        ]}
                        onChange={(e) => {
                          const paymentMethod = e;

                          formik.setFieldValue("paymentMethod", paymentMethod);
                        }}
                      />
                    </div>

                    <CFormSelect
                      name="trashed"
                      onChange={formik.handleChange}
                      style={{ flex: 0.2, marginLeft: 12 }}
                    >
                      <option value={-1}>Todas</option>
                      <option value={1}>Eliminadas</option>
                      <option value={2}>Abiertas</option>
                      <option value={0}>Finalizadas</option>
                    </CFormSelect>
                    <CFormInput
                      name="date"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.date}
                      type="date"
                      style={{ flex: 0.2, marginLeft: 12 }}
                    />

                    <CDropdown
                      variant="btn-group"
                      className="pr-0"
                      alignment="end"
                    >
                      <CButton size="sm" type="submit" color="primary">
                        Buscar
                      </CButton>

                      <CDropdownToggle color="primary" size="sm" split />

                      <CDropdownMenu>
                        <CDropdownItem
                          type="button"
                          onClick={() => {
                            navigate("/sales/new");
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <Plus size={18} />
                            Crear Venta
                          </div>
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                itemsPerPage={20}
                items={
                  sales?.data.data.map((sale) => ({
                    ...sale,
                    _props: {
                      color: sale.deletedAt
                        ? sale.status === SaleStatus.Cancelled
                          ? "warning"
                          : "danger"
                        : sale.status === SaleStatus.InProgress
                        ? "primary"
                        : "default",
                    },
                  })) || []
                }
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "store",
                    label: "Punto de Venta",
                    _props: { className: "font-weight-bold" },
                  },
                  { key: "user", label: "Vendedor" },
                  { key: "payments", label: "Métodos de Pago" },
                  {
                    key: "amount",
                    label: "Monto",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "date",
                    label: "Fecha",
                    _props: { className: "font-weight-bold text-right" },
                  },
                  {
                    key: "file",
                    label: "",
                    _props: { className: "text-right" },
                  },
                ]}
                loading={loading}
                scopedColumns={{
                  store: (sale: Sale) => {
                    const StoreIcon = getStoreIcon(sale.store);

                    return (
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <StoreIcon />
                          {user?.isAdmin ? `${sale.store.company.name} - ` : ""}
                          {sale.store.name}
                        </div>
                      </td>
                    );
                  },
                  user: (sale: Sale) => (
                    <td>
                      {sale.user?.name} {sale.user?.lastname}
                    </td>
                  ),
                  payments: (sale: Sale) => (
                    <td>{parsePaymentMethods(sale.payments)}</td>
                  ),
                  amount: (sale: Sale) => (
                    <td className="text-right">
                      {formatCurrency(
                        sale.payments
                          .filter(
                            (payment) =>
                              ![
                                PaymentMethod.Discount,
                                PaymentMethod.CustomerDiscount,
                              ].includes(payment.type)
                          )
                          .reduce((prev, curr) => prev + curr.amount, 0)
                      )}
                    </td>
                  ),
                  date: (sale: Sale) => (
                    <td className="text-right">
                      {dateFormat(sale.date, "dd/MM/yyyy HH:mm")}
                    </td>
                  ),
                  file: (sale: Sale) => {
                    const invoicesLetter = uniq(
                      sale.invoices.map((i) => i.voucher?.voucher?.id)
                    );

                    return (
                      <td className="text-right">
                        {sale.invoices?.length > 0 && (
                          <div className="d-flex align-items-center justify-content-end gap-1">
                            <FileText /> {invoicesLetter.join(",")}
                          </div>
                        )}
                      </td>
                    );
                  },
                }}
                tableProps={{
                  striped: true,
                  hover: canSeeSale,
                }}
                clickableRows={canSeeSale}
                onRowClick={(sale) => {
                  if (canSeeSale) {
                    navigate(`/sales/${sale.id}`);
                  }
                }}
              />

              <Pagination meta={sales} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Sales;
