import { useQuery } from "@apollo/client";
import { CAlert, CRow } from "@coreui/react-pro";
import Api from "src/api";
import { PaymentStatus } from "src/api/companies";
import { ToPayLink } from "./FeatureAlert";
import { getCompanyPaymentStatus } from "src/helpers/payments";
import { useUserStore } from "src/store/users";
import { PlanType } from "src/api/plans";

const PlanAlert = () => {
  const { currentCompany } = useUserStore();

  const { data: company } = useQuery(Api.Companies.GET_COMPANY, {
    skip: !currentCompany?.id,
    fetchPolicy: "no-cache",
    variables: {
      id: currentCompany?.id,
    },
  });

  if (!company?.data) {
    return null;
  }

  const planType = company.data.currentPlan?.type;
  const paymentStatus = company.data.paymentStatus;

  const isWarning =
    getCompanyPaymentStatus(paymentStatus) === PaymentStatus.WARNING;

  const isUnpaid =
    getCompanyPaymentStatus(paymentStatus) === PaymentStatus.UNPAID;

  const isDebtor =
    getCompanyPaymentStatus(paymentStatus) === PaymentStatus.DEBTOR;

  const shouldDisplay =
    planType !== PlanType.Free && (isWarning || isUnpaid || isDebtor);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <CRow className="mx-0 mb-3">
      <CAlert color={isWarning ? "warning" : "danger"} className="mb-0">
        {isWarning && (
          <>
            ¡Hola! Esperamos que estés disfrutando de todas las funcionalidades
            que te ofrece nuestro sistema. <br />
            Solo queremos recordarte que tu plan está por vencer en pocos días.
            Para que no pierdas acceso a todo lo que te ofrece, te sugerimos que
            regularices tu situación. <ToPayLink />
            <br />
            ¡Gracias por confiar en nosotros!
          </>
        )}

        {isUnpaid && (
          <>
            ¡Hola! Esperamos que estés disfrutando de todas las funcionalidades
            que te ofrece nuestro sistema. <br />
            Solo queremos recordarte que tu plan está vencido, por lo que
            algunas funcionalides estaran limitadas y no podras usarlas. Para
            recuperarlas y que no pierdas acceso a todo lo que te ofrecemos, te
            sugerimos que regularices tu situación. <ToPayLink />
            <br /> ¡Gracias por confiar en nosotros!
          </>
        )}

        {isDebtor && (
          <>
            ¡Hola! Esperamos que estés disfrutando de todas las funcionalidades
            que te ofrece nuestro sistema. <br />
            Solo queremos recordarte que tu plan está vencido, y el impago
            genera recargos, tambien es probable que algunas funcionalides esten
            limitadas. Para que no pierdas acceso a todo lo que te ofrecemos, te
            sugerimos que regularices tu situación. <ToPayLink />
            <br /> ¡Gracias por confiar en nosotros!
          </>
        )}
        {/* pagando en el{" "}
        <CAlertLink href={currentPlan.nextPlan?.paymentLink} target="_blank">
          siguiente link
        </CAlertLink>
        */}
      </CAlert>
    </CRow>
  );
};

export default PlanAlert;
