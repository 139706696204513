import { useQuery } from "@apollo/client";
import { CCol, CFormLabel, CRow, CSmartTable } from "@coreui/react-pro";
import { sortBy } from "lodash";
import { useState } from "react";
import { Link, useNavigate } from "react-router";
import Api from "src/api";
import { TopSellerProduct } from "src/api/reports";
import { Store, StoreType } from "src/api/stores";
import { AppLoader } from "src/components/Loader/Loader";
import SmartSelect, { SmartSelectType } from "src/components/SmartSelect";
import { logEvent } from "src/helpers/analytics";
import { getLastSixMonthsWeeks } from "src/helpers/dates";
import { useUserStore } from "src/store/users";

import { GraphQLFind, GraphQLMeta } from "src/types";

const weeks = getLastSixMonthsWeeks();

const ProductsBestSeller = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  const [storeId, setStoreId] = useState<number>(0);
  const [weekNumber, setWeekNumber] = useState<number>(weeks[0].number);

  const {
    data: topProducts,
    refetch,
    loading,
  } = useQuery<
    GraphQLFind<{ amount: number; productId: number; productName: string }[]>
  >(Api.Reports.TOP_SELL_PRODUCTS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        dateFrom: weeks[0].dateFrom,
        dateTo: weeks[0].dateTo,
      },
    },
  });

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          permission: "LIST_SALES",
          limit: 0,
          type: StoreType.Store,
        },
      },
    }
  );

  if (!topProducts?.data) {
    return <AppLoader />;
  }

  const productsColumns = [
    {
      key: "index",
      label: "ID",
    },
    {
      key: "name",
      label: "Producto",
      _props: { className: "font-weight-bold" },
    },
    { key: "amount", label: "Cantidad", _props: { className: "text-center" } },
  ];

  if (user?.isAdmin) {
    productsColumns.push({ key: "company", label: "Empresa" });
  }

  return (
    <>
      <CRow>
        <CCol md="4">
          <CFormLabel>
            <strong>Punto de Venta</strong>
          </CFormLabel>
          <SmartSelect
            type={SmartSelectType.Stores}
            name="storeId"
            search
            options={[
              { value: 0, name: "Todos" },
              ...(sortBy(stores?.data.data ?? [], "name").map((store) => ({
                name: user?.isAdmin
                  ? `${store.name} (${store.company.name})`
                  : store.name,
                value: store.id,
                type: store.type,
              })) ?? []),
            ]}
            resultsCount={10}
            placeholder="Selecciona Punto de Venta"
            onChange={(e) => {
              logEvent("dashboard.filters.set-store", {
                storeId: e,
              });

              setStoreId(Number(e));

              const week = weeks.find((week) => week.number === weekNumber);

              refetch({
                filters: {
                  dateFrom: week?.dateFrom,
                  dateTo: week?.dateTo,
                  storeId,
                },
              });
            }}
            value={storeId.toString()}
          />
        </CCol>
        <CCol md="4">
          <CFormLabel>
            <strong>Semana</strong>
          </CFormLabel>
          <SmartSelect
            name="weekNumber"
            value={weekNumber.toString()}
            onChange={(value) => {
              const week = weeks.find((week) => week.number === Number(value));

              setWeekNumber(Number(value));
              refetch({
                filters: {
                  dateFrom: week?.dateFrom,
                  dateTo: week?.dateTo,
                  storeId,
                },
              });
            }}
            options={weeks.map((week) => ({
              value: week.number,
              name: week.label,
            }))}
          />
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CCol md="12">
          <CSmartTable
            loading={loading}
            itemsPerPage={topProducts?.data?.length ?? 20}
            items={topProducts.data}
            noItemsLabel="No has hecho ventas en esta semana"
            columns={productsColumns}
            onRowClick={(item) => {
              logEvent("reports.top-products.navigate", {
                product: item,
              });

              navigate(`/products/${item.id}`);
            }}
            clickableRows
            tableProps={{
              hover: true,
              striped: true,
            }}
            scopedColumns={{
              index: (item: TopSellerProduct) => (
                <td>
                  <Link to={`/products/${item.productId}`}>
                    {item.productId}
                  </Link>
                </td>
              ),
              name: (item: TopSellerProduct) => <td>{item.name}</td>,
              amount: (item: TopSellerProduct) => (
                <td className="text-center">{item.amount}</td>
              ),
              company: (item: TopSellerProduct) => <td>{item.company.name}</td>,
            }}
          />
        </CCol>
      </CRow>
    </>
  );
};

export default ProductsBestSeller;
