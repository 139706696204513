import { useMutation } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import Api from "src/api";
import { TransactionKind } from "src/api/registers";
import ErrorMessage from "src/components/ErrorMessage";
import SaveButton from "src/components/SaveButton";
import { getValidity } from "src/helpers/validation";
import { useUserStore } from "src/store/users";

const NewMethod = () => {
  const { currentCompany } = useUserStore();
  const navigate = useNavigate();
  const [createMutation, { loading, error }] = useMutation(
    Api.Registers.CREATE_TRANSACTION_TYPE,
    {
      onCompleted: () => {
        navigate("/registers/methods");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
    },
    onSubmit: () => {
      if (!loading) {
        createMutation({
          variables: {
            input: {
              companyId: currentCompany?.id ?? null,
              name: formik.values.name,
              type: formik.values.type,
            },
          },
        });
      }
    },
  });

  return (
    <CCol lg={12}>
      <CForm className="form-horizontal" onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Nuevo Método</CCardHeader>
          <CCardBody>
            <CRow className="mt-3">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="type">Tipo</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormSelect
                  id="type"
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                >
                  <option value={0}>Selecciona Tipo de Movimiento</option>
                  <option value={TransactionKind.WithdrawMoney}>Egreso</option>
                  <option value={TransactionKind.AddMoney}>Ingreso</option>
                  <option value={TransactionKind.Sell}>Venta</option>
                </CFormSelect>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter className="px-3">
            <CRow className="px-0 align-items-center">
              <CCol md="8">{error && <ErrorMessage />}</CCol>
              <CCol md="4" className="flex justify-content-end">
                <SaveButton
                  loading={loading}
                  disabled={loading || !formik.isValid}
                />
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default NewMethod;
