import { useEffect } from "react";
import {
  CCol,
  CButton,
  CForm,
  CSmartTable,
  CRow,
  CFormInput,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdown,
  CTooltip,
} from "@coreui/react-pro";
import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useFormik } from "formik";
import Api from "src/api";
import { usePagination } from "src/hooks/pagination";
import { queryStringToObject } from "src/helpers/strings";
import { useLocation } from "react-router";
import { GraphQLMeta, SearchForm } from "src/types";
import { PriceListItem } from "src/api/price-lists";
import { StoreType } from "src/api/stores";
import Pagination from "src/components/Pagination";
import { FileDown, LoaderCircle, Unlink } from "lucide-react";
import { formatCurrency } from "src/helpers/numbers";
import Daruma from "src/components/Icon/Daruma";
import { findPointOfSale } from "src/helpers/stores";
import { Column } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { MercadoLibre, MercadoShops, TiendaNube } from "src/components/Icon";

const NonEditablePriceListItems = ({
  priceListId,
  type,
}: {
  priceListId: number;
  type: StoreType;
}) => {
  const querySearch = queryStringToObject(useLocation().search);

  const {
    data: items,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<PriceListItem>>(
    Api.PriceLists.LIST_PRICE_LIST_ITEMS,
    {
      variables: {
        fetchPolicy: "no-cache",
        filters: {
          page: querySearch.page ? Number(querySearch.page) : 1,
          priceListId,
          search: querySearch.search ?? "",
        },
      },
    }
  );
  const { page, pageChange, resetAndSearch } = usePagination(
    `price-lists/${priceListId}`,
    refetch,
    { priceListId }
  );

  const [tiendaNubeDownload, { loading: tiendaNubeLoading }] = useMutation(
    Api.TiendaNube.DOWNLOAD_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [deleteMutation, { loading: deleting }] = useMutation(
    Api.PriceLists.DELETE_PRICE_LIST_ITEM,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const [mercadoLibreDownload, { loading: mercadoLibreLoading }] = useMutation(
    Api.MercadoLibre.DOWNLOAD_MP_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [mercadoShopsDownload, { loading: mercadoShopsLoading }] = useMutation(
    Api.MercadoShops.DOWNLOAD_MS_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [rappiDownload, { loading: rappiLoading }] = useMutation(
    Api.Rappi.DOWNLOAD_RAPPI_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const formik = useFormik<SearchForm & { priceListId: number }>({
    initialValues: {
      search: querySearch.search ?? "",
      priceListId,
    },
    onSubmit: (data) => {
      if (!loading) {
        resetAndSearch({
          search: data.search,
        });
      }
    },
  });

  useEffect(() => {
    resetAndSearch({
      search: formik.values.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restColumns: Column[] = [];

  if (type !== StoreType.Rappi) {
    restColumns.push({
      key: "url",
      label: "Acciones",
      _props: {
        className: "text-right",
      },
    });
  }

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CRow>
        <CCol sm={12} className="d-flex align-items-center">
          <CFormInput
            placeholder="Buscar..."
            name="search"
            autoFocus
            onChange={formik.handleChange}
            defaultValue={formik.values.search}
            style={{ flex: 1 }}
          />

          <CDropdown
            variant="btn-group"
            autoClose={false}
            className="pl-4 pr-0"
            alignment="end"
          >
            <CButton type="submit" color="primary" className="my-button">
              Buscar
            </CButton>

            <CDropdownToggle className="my-button" color="primary" split />

            <CDropdownMenu>
              <CDropdownItem
                disabled={
                  rappiLoading ||
                  tiendaNubeLoading ||
                  mercadoLibreLoading ||
                  mercadoShopsLoading
                }
                as="button"
                type="button"
                onClick={() => {
                  let downloadMutation = (
                    _?:
                      | MutationFunctionOptions<
                          any,
                          OperationVariables,
                          DefaultContext,
                          ApolloCache<any>
                        >
                      | undefined
                  ) => {};

                  if (type === StoreType.TiendaNube) {
                    downloadMutation = (props) => {
                      tiendaNubeDownload(props);
                    };
                  }

                  if (type === StoreType.MercadoLibre) {
                    downloadMutation = (props) => {
                      mercadoLibreDownload(props);
                    };
                  }

                  if (type === StoreType.MercadoShops) {
                    downloadMutation = (props) => {
                      mercadoShopsDownload(props);
                    };
                  }

                  if (type === StoreType.Rappi) {
                    downloadMutation = (props) => {
                      rappiDownload(props);
                    };
                  }

                  downloadMutation({
                    variables: {
                      priceListId,
                    },
                  });
                }}
              >
                <div className="d-flex gap-2 align-items-center justify-content-between">
                  {rappiLoading ||
                  tiendaNubeLoading ||
                  mercadoLibreLoading ||
                  mercadoShopsLoading ? (
                    <LoaderCircle size={18} />
                  ) : (
                    <FileDown size={18} />
                  )}
                  Descargar productos
                </div>
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CCol>
      </CRow>

      <CSmartTable
        itemsPerPage={items?.data.meta?.perPage ?? 10}
        className="mt-3"
        items={items?.data.data ?? []}
        columns={[
          {
            key: "productId",
            label: "ID",
          },
          {
            key: "sku",
            label: "SKU",
          },
          {
            key: "name",
            label: "Nombre",
          },
          {
            key: "productPrice",
            label: "Precio de Producto",
            _props: {
              className: "text-right",
            },
          },
          {
            key: "price",
            label: "Precio en Lista de Precios",
            _props: {
              className: "text-right",
            },
          },
          ...restColumns,
        ]}
        scopedColumns={{
          productId: (item: PriceListItem) => (
            <td>
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/#/products/${item.productId}`}
                >
                  {item.productId}
                </a>
              </span>
            </td>
          ),
          name: (item: PriceListItem) => (
            <td>
              <span>{item.name}</span>
            </td>
          ),
          sku: (item: PriceListItem) => (
            <td>
              <span>{item.sku ?? "-"}</span>
            </td>
          ),
          productPrice: (item: PriceListItem) => {
            return (
              <td className="text-right">
                <div className="flex justify-content-end">
                  <Daruma />
                  <span className="ml-2">
                    {formatCurrency(item.product.price)}
                  </span>
                </div>
              </td>
            );
          },
          price: (item: PriceListItem) => {
            const currentType = findPointOfSale(item.priceList.store.type);
            const StoreIcon = () => currentType?.icon;

            return (
              <td className="text-right">
                <div className="flex justify-content-end">
                  <StoreIcon />
                  <span className="ml-2">{formatCurrency(item.price)}</span>
                </div>
              </td>
            );
          },
          url: (item: PriceListItem) => (
            <td className="text-right">
              {type === StoreType.TiendaNube &&
                item.metadata?.tiendaNubeUrl && (
                  <CTooltip content="Ver en Tienda Nube">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={item.metadata?.tiendaNubeUrl}
                    >
                      <CButton size="sm" variant="ghost" color="info">
                        <TiendaNube size={24} />
                      </CButton>
                    </a>
                  </CTooltip>
                )}

              {type === StoreType.MercadoLibre && (
                <CTooltip content="Ver en Mercado Libre">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.metadata?.mercadoLibreUrl}
                  >
                    <CButton size="sm" variant="ghost" color="info">
                      <MercadoLibre size={24} />
                    </CButton>
                  </a>
                </CTooltip>
              )}

              {type === StoreType.MercadoShops && (
                <CTooltip content="Ver en Mercado Libre">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.metadata?.mercadoShopsUrl}
                  >
                    <CButton size="sm" variant="ghost" color="info">
                      <MercadoShops size={24} />
                    </CButton>
                  </a>
                </CTooltip>
              )}

              <CTooltip content="Desenlazar producto">
                <CButton
                  onClick={() => deleteMutation({ variables: { id: item.id } })}
                  size="sm"
                  color="danger"
                  className="ml-2"
                  disabled={deleting}
                >
                  <Unlink size={20} />
                </CButton>
              </CTooltip>
            </td>
          ),
        }}
        tableProps={{
          striped: true,
        }}
      />

      <Pagination meta={items} page={page} pageChange={pageChange} />
    </CForm>
  );
};
export default NonEditablePriceListItems;
