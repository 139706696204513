import {
  CCol,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CFormSelect,
  CCardHeader,
  CFormSwitch,
  CMultiSelect,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { CreateProduct, taxes, ProductSchema, Product } from "src/api/products";
import { useFormik } from "formik";
import ErrorMessage from "src/components/ErrorMessage";
import SaveButton from "src/components/SaveButton";
import { GraphQLFind, GraphQLMeta, GraphQLMutation } from "src/types";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { Supplier } from "src/api/suppliers";
import { Category } from "src/api/categories";
import { useEffect, useRef } from "react";
import { calculatePrice, calculateProfit } from "src/helpers/numbers";
import SuccessModal from "./components/SuccessModal";
import { Company } from "src/api/companies";
import { logEvent } from "src/helpers/analytics";
import ConfirmDialog from "./components/ConfirmDialog";
import { StockType } from "src/api/stock";
import { useProductError } from "src/hooks/useProductError";
import { useUserStore } from "src/store/users";

const NewProduct = () => {
  const { currentCompany } = useUserStore();
  const successModalRef = useRef<any>(null);
  const confirmDialogRef = useRef<any>(null);
  const { handleProductErrorMessage } = useProductError();

  const [mutation, { error, loading }] = useMutation(
    Api.Products.CREATE_PRODUCT,
    {
      onCompleted: (res: GraphQLMutation<Product>) => {
        successModalRef.current.open(res.data.id);
      },
      onError: (error) => {
        handleProductErrorMessage(error, { confirmDialogRef });
      },
    }
  );

  const { data: company } = useQuery<GraphQLFind<Company>>(
    Api.Companies.GET_COMPANY,
    {
      fetchPolicy: "no-cache",
      skip: !currentCompany?.id,
      variables: {
        id: currentCompany?.id,
      },
    }
  );

  const { data: suppliers } = useQuery<GraphQLMeta<Supplier>>(
    Api.Suppliers.LIST_SUPPLIERS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const { data: categories } = useQuery<GraphQLMeta<Category>>(
    Api.Categories.LIST_CATEGORIES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const formik = useFormik<CreateProduct>({
    initialValues: {
      name: "",
      sku: "",
      brands: "",
      barcode: "",
      barcodeType: "EAN13",
      categoryId: 0,
      cost: 0,
      profit: 0,
      tax: 0,
      price: 0,
      companyId: currentCompany?.id ?? 0,
      force: false,
      stockAlert: 10,
      productExpires: false,
      multipleBarcodes: false,
      isGranel: false,
      isFractionable: false,
      stockeable:
        company?.data.config.stock === StockType.Mixed
          ? false
          : company?.data.config.stock === StockType.Limited,
      supplierIds: [],
    },
    onSubmit: (data) => {
      if (!loading) {
        confirmDialogRef.current?.close();

        const categoryId = data.categoryId ? Number(data.categoryId) : null;

        const input = {
          name: data.name?.trim(),
          sku: data.sku?.trim(),
          brands: data.brands?.trim(),
          barcode: data.barcode?.trim(),
          barcodeType: data.barcodeType,
          companyId: Number(data.companyId),
          categoryId: categoryId && categoryId > 0 ? categoryId : null,
          productExpires: data.productExpires,
          multipleBarcodes: data.multipleBarcodes,
          isGranel: data.isGranel,
          isFractionable: data.isFractionable,
          stockeable:
            company?.data.config.stock === StockType.Mixed
              ? data.stockeable
              : company?.data.config.stock === StockType.Limited,
          cost: Number(data.cost),
          profit: Number(data.profit),
          tax: Number(data.tax),
          price: Number(data.price),
          force: data.force === true,
          supplierIds: data.supplierIds,
        } as CreateProduct;

        if (input.stockeable === true) {
          input.stockAlert = Number(data.stockAlert);
        }

        logEvent("product.create.submit", {
          input,
        });

        mutation({
          variables: {
            input,
          },
        });
      }
    },
    validationSchema: ProductSchema,
    validateOnChange: true,
  });

  useEffect(() => {
    if (formik.values.profit < 0) {
      formik.setFieldValue("profit", Math.abs(formik.values.profit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.profit]);

  if (!categories?.data.data || !suppliers?.data.data || !company?.data) {
    return null;
  }

  const options = suppliers.data.data.map((supplier) => ({
    value: supplier.id,
    text: supplier.name,
    label: supplier.name,
    selected: formik.values.supplierIds.includes(supplier.id),
  }));

  return (
    <>
      <CCol lg={12}>
        <CForm className="form-horizontal" onSubmit={formik.handleSubmit}>
          <CCard>
            <CCardHeader>Nuevo Producto</CCardHeader>
            <CCardBody>
              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="name">Nombre</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="name"
                    placeholder="Nombre"
                    name="name"
                    onChange={formik.handleChange}
                    {...getValidity(formik.values.name, formik.errors.name)}
                  />
                </CCol>
              </CRow>
              {!formik.values.multipleBarcodes && (
                <CRow className="mt-3">
                  <CCol md="3" className="table-center">
                    <CFormLabel htmlFor="barcode">Código de Barra</CFormLabel>
                  </CCol>
                  <CCol xs="12" md="9">
                    <CFormInput
                      id="barcode"
                      placeholder="Código de Barra"
                      name="barcode"
                      onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      onChange={formik.handleChange}
                      {...getValidity(
                        formik.values.barcode,
                        formik.errors.barcode
                      )}
                    />
                  </CCol>
                </CRow>
              )}
              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="sku">SKU</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="sku"
                    placeholder="SKU"
                    defaultValue={formik.values.sku}
                    name="sku"
                    onChange={formik.handleChange}
                    {...getValidity(formik.values.sku, formik.errors.sku)}
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="supplierId">Proveedor</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CMultiSelect
                    id="supplierIds"
                    options={options}
                    placeholder="Seleccione Proveedor"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "supplierIds",
                        e.map(({ value }) => value)
                      );
                    }}
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="brands">Marca</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormInput
                    id="brands"
                    placeholder="Marca"
                    defaultValue={formik.values.brands ?? ""}
                    name="brands"
                    onChange={formik.handleChange}
                    {...getValidity(formik.values.brands, formik.errors.brands)}
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="categoryId">Categoría</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormSelect
                    id="categoryId"
                    name="categoryId"
                    onChange={formik.handleChange}
                    {...getValidity(
                      formik.values.categoryId,
                      formik.errors.categoryId
                    )}
                    defaultValue={formik.values.categoryId ?? 0}
                  >
                    <option key="0" value="0">
                      Seleccione Categoría
                    </option>
                    {categories.data.data.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="cost">Costo</CFormLabel>
                </CCol>
                <CCol xs="3">
                  <CFormInput
                    id="cost"
                    placeholder="Costo"
                    step="0.01"
                    type="number"
                    defaultValue={formik.values.cost}
                    onChange={(e) => {
                      formik.handleChange(e);

                      const price = calculatePrice(
                        Number(e.currentTarget.value),
                        Number(formik.values.profit),
                        Number(formik.values.tax),
                        company.data.config
                      );

                      formik.setFieldValue("price", price);
                    }}
                    {...getValidity(formik.values.cost, formik.errors.cost)}
                  />
                </CCol>

                <CCol md="3" className="table-center justify-content-end">
                  <CFormLabel htmlFor="profit">Ganancia (%)</CFormLabel>
                </CCol>
                <CCol md="3">
                  <CFormInput
                    id="profit"
                    placeholder="Ganancia (%)"
                    name="profit"
                    type="number"
                    step="0.01"
                    disabled={company.data.config.calculate === "PROFIT"}
                    onChange={(e) => {
                      formik.handleChange(e);

                      const price = calculatePrice(
                        Number(formik.values.cost),
                        Number(e.currentTarget.value),
                        Number(formik.values.tax),
                        company.data.config
                      );

                      formik.setFieldValue("price", price);
                    }}
                    defaultValue={formik.values.profit}
                    value={formik.values.profit}
                    {...getValidity(formik.values.profit, formik.errors.profit)}
                  />
                </CCol>
              </CRow>

              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="tax">IVA</CFormLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CFormSelect
                    id="tax"
                    name="tax"
                    onChange={(e) => {
                      formik.handleChange(e);

                      const price = calculatePrice(
                        Number(formik.values.cost),
                        Number(formik.values.profit),
                        Number(e.currentTarget.value),
                        company.data.config
                      );

                      formik.setFieldValue("price", price);
                    }}
                    defaultValue={formik.values.tax}
                    {...getValidity(formik.values.tax, formik.errors.tax)}
                  >
                    <option key="-1" value="-1">
                      Seleccione IVA
                    </option>
                    {taxes.map((tax) => (
                      <option key={tax.id} value={tax.value}>
                        {tax.value} %
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="price">Precio</CFormLabel>
                </CCol>
                <CCol md="9">
                  <CFormInput
                    id="price"
                    step="0.01"
                    placeholder="Precio"
                    name="price"
                    type="number"
                    value={formik.values.price}
                    defaultValue={formik.values.price}
                    onChange={(e) => {
                      formik.handleChange(e);

                      const profit = calculateProfit(
                        Number(formik.values.cost),
                        Number(e.currentTarget.value),
                        Number(formik.values.tax)
                      );

                      formik.setFieldValue("profit", profit);
                    }}
                  />
                </CCol>
              </CRow>

              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="multipleBarcodes">
                    Multiple Códigos de Barra
                  </CFormLabel>
                </CCol>
                <CCol md="3" className="d-flex align-items-center">
                  <CFormSwitch
                    id="multipleBarcodes"
                    name="multipleBarcodes"
                    checked={formik.values.multipleBarcodes}
                    defaultChecked={formik.values.multipleBarcodes}
                    onChange={formik.handleChange}
                  />
                </CCol>

                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="productExpires">
                    ¿Producto con Vencimiento?
                  </CFormLabel>
                </CCol>
                <CCol md="3" className="d-flex align-items-center">
                  <CFormSwitch
                    id="productExpires"
                    name="productExpires"
                    checked={formik.values.productExpires}
                    defaultChecked={formik.values.productExpires}
                    onChange={formik.handleChange}
                  />
                </CCol>
              </CRow>

              <CRow className="mt-3">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="isGranel">
                    ¿Producto a granel?
                  </CFormLabel>
                </CCol>
                <CCol md="3" className="d-flex align-items-center">
                  <CFormSwitch
                    id="isGranel"
                    name="isGranel"
                    checked={formik.values.isGranel}
                    defaultChecked={formik.values.isGranel}
                    onChange={formik.handleChange}
                  />
                </CCol>

                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="isFractionable">
                    ¿Producto fraccionable?
                  </CFormLabel>
                </CCol>
                <CCol md="3" className="d-flex align-items-center">
                  <CFormSwitch
                    id="isFractionable"
                    name="isFractionable"
                    checked={formik.values.isFractionable}
                    defaultChecked={formik.values.isFractionable}
                    onChange={formik.handleChange}
                  />
                </CCol>
              </CRow>

              <CRow className="mt-3">
                {[StockType.Limited, StockType.Mixed].includes(
                  company.data.config.stock
                ) && (
                  <>
                    <CCol md="3" className="table-center">
                      <CFormLabel htmlFor="stockeable">
                        ¿Maneja Stock?
                      </CFormLabel>
                    </CCol>
                    <CCol md="3" className="d-flex align-items-center">
                      <CFormSwitch
                        id="stockeable"
                        name="stockeable"
                        checked={formik.values.stockeable}
                        defaultChecked={formik.values.stockeable}
                        onChange={formik.handleChange}
                      />
                    </CCol>
                  </>
                )}
                {(company.data.config.stock === StockType.Limited ||
                  (company.data.config.stock === StockType.Mixed &&
                    formik.values.stockeable)) && (
                  <>
                    <CCol md="3" className="table-center">
                      <CFormLabel htmlFor="stockAlert">
                        Alerta de Stock
                      </CFormLabel>
                    </CCol>
                    <CCol md="2">
                      <CFormInput
                        id="stockAlert"
                        name="stockAlert"
                        type="number"
                        step="1"
                        min={0}
                        defaultValue={formik.values.stockAlert}
                        onChange={formik.handleChange}
                      />
                    </CCol>
                  </>
                )}
              </CRow>
            </CCardBody>
            <CCardFooter className="px-3">
              <CRow className="px-0 align-items-center">
                <CCol md="8">{error && <ErrorMessage />}</CCol>
                <CCol md="4" className="flex justify-content-end">
                  <SaveButton
                    loading={loading}
                    disabled={loading || !formik.isValid}
                  />
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>

      <SuccessModal ref={successModalRef} />

      <ConfirmDialog
        onSubmit={() => {
          formik.setFieldValue("force", true);
          formik.handleSubmit();
        }}
        ref={confirmDialogRef}
      />
    </>
  );
};

export default NewProduct;
