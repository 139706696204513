import { gql } from "@apollo/client";

export const LIST_INVOICES = gql`
  query ($filters: SaleInvoiceFilters) {
    data: saleInvoices(filters: $filters) {
      data {
        id
        saleId
        cae
        invoiceId
        invoiceTypeId
        posId

        afipInvoice {
          cae
          voucher {
            id
            name
          }
          document {
            id
          }
          dniNumber
          total
          packId
        }

        relatedTo

        parent {
          id
        }

        sale {
          id

          store {
            name
            type
          }

          deletedAt
        }

        pdfUrl
        emailSent
        createdAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;
