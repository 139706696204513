import AdminDashboard from "./components/AdminDashboard";
import OwnerDashboard from "./components/OwnerDashboard";
import PlanAlert from "src/containers/PlanAlert";
import { useUserStore } from "src/store/users";

const Dashboard = () => {
  const { user, hasPermission } = useUserStore();

  if (user?.isAdmin) {
    return <AdminDashboard today />;
  }

  return (
    <>
      <PlanAlert />

      {hasPermission("SHOW_ANALYTIC") && <OwnerDashboard today />}
    </>
  );
};

export default Dashboard;
