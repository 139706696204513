import { memo } from "react";
import SalesDashboard from "./SalesDashboard";
import { useUserStore } from "src/store/users";

const OwnerDashboard = ({ today = false }: { today?: boolean }) => {
  const { hasPermission } = useUserStore();
  const canSeeSales = hasPermission("LIST_SALES", { force: true });

  return <>{canSeeSales && <SalesDashboard today={today} />}</>;
};

export default memo(OwnerDashboard);
