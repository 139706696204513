import { useRef, useState } from "react";
import {
  CCol,
  CButton,
  CCardFooter,
  CTabContent,
  CTabPane,
  CNavItem,
  CNavLink,
  CNav,
  CRow,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { PriceList } from "src/api/price-lists";
import { GraphQLFind } from "src/types";
import { useNavigate, useParams } from "react-router";
import PriceListData from "./components/PriceListData";
import PriceListItems from "./components/PriceListItems";
import NonEditablePriceListItems from "./components/NonEditablePriceListItems";
import DeleteModal from "./components/DeleteModal";
import ErrorMessage from "src/components/ErrorMessage";
import Api from "src/api";
import { AppLoader } from "src/components/Loader/Loader";
import { useUserStore } from "src/store/users";
import PriceListAudit from "./components/PriceListAudit";

const EditPriceList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const priceListId = Number(params.id);

  if (!priceListId) {
    navigate("/price-lists");
  }

  const deleteModalRef = useRef<any>(null);
  const priceListItemsRef = useRef<any>(null);
  const priceListDataRef = useRef<any>(null);
  const { hasPermission } = useUserStore();
  const canEditPriceList = hasPermission("UPDATE_PRICE_LIST");
  const canDeletePriceList = hasPermission("DELETE_PRICE_LIST");

  const [currentTab, setCurrentTab] = useState<number>(0);
  const { data: priceList, refetch } = useQuery<GraphQLFind<PriceList>>(
    Api.PriceLists.GET_PRICE_LIST,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: priceListId,
        order: "name",
      },
      onError: () => {
        navigate(-1);
      },
    }
  );

  const [deleteMutation, { error, loading }] = useMutation(
    Api.PriceLists.DELETE_PRICE_LIST,
    {
      onCompleted: () => {
        navigate("/price-lists");
      },
    }
  );

  const onDelete = () => {
    if (!loading) {
      deleteMutation({
        variables: {
          id: priceListId,
        },
      });
    }
  };

  if (!priceList?.data) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <CCard>
        <CCardHeader>Lista de Precios: {priceList.data.name}</CCardHeader>
        <CCardBody>
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                onClick={() => setCurrentTab(0)}
                active={currentTab === 0}
              >
                Datos
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                onClick={() => setCurrentTab(1)}
                active={currentTab === 1}
              >
                Productos
              </CNavLink>
            </CNavItem>
            {priceList.data.editable && (
              <CNavItem>
                <CNavLink
                  onClick={() => setCurrentTab(2)}
                  active={currentTab === 2}
                >
                  Auditoria
                </CNavLink>
              </CNavItem>
            )}
          </CNav>
          <CTabContent className="px-0">
            <CTabPane visible={currentTab === 0}>
              <PriceListData
                priceList={priceList.data}
                priceListId={priceListId}
                ref={priceListDataRef}
              />
            </CTabPane>
            <CTabPane visible={currentTab === 1}>
              {priceList.data.editable ? (
                <PriceListItems
                  refetch={refetch}
                  priceList={priceList.data}
                  priceListId={priceListId}
                  ref={priceListItemsRef}
                />
              ) : (
                <NonEditablePriceListItems
                  priceListId={priceList.data.id}
                  type={priceList.data.store.type}
                />
              )}
            </CTabPane>
            <CTabPane visible={currentTab === 2}>
              <PriceListAudit priceList={priceList.data} />
            </CTabPane>
          </CTabContent>
        </CCardBody>
        {(canEditPriceList || canDeletePriceList) && (
          <CCardFooter>
            <CRow>
              <CCol md="6">{error && <ErrorMessage />}</CCol>
              <CCol md="6" className="flex justify-content-end">
                {canDeletePriceList && priceList.data.editable && (
                  <>
                    <DeleteModal ref={deleteModalRef} onDelete={onDelete} />
                    <CButton
                      size="sm"
                      type="button"
                      color="danger"
                      className="mr-2"
                      onClick={() => deleteModalRef.current?.open()}
                    >
                      Eliminar
                    </CButton>
                  </>
                )}

                {((canEditPriceList &&
                  !priceList.data.editable &&
                  currentTab === 0) ||
                  priceList.data.editable) && (
                  <CLoadingButton
                    size="sm"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    onClick={() => {
                      if (currentTab === 0) {
                        priceListDataRef.current?.submit();
                      }

                      if (currentTab === 1) {
                        priceListItemsRef.current?.submit();
                      }
                    }}
                    type="button"
                  >
                    Guardar
                  </CLoadingButton>
                )}
              </CCol>
            </CRow>
          </CCardFooter>
        )}
      </CCard>
    </CCol>
  );
};

export default EditPriceList;
