import { useMutation, useQuery } from "@apollo/client";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormSelect,
  CLink,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { Fragment, useRef } from "react";
import Api from "src/api";
import { Store, StoreConfig, StoreMetadata, StoreType } from "src/api/stores";
import { AppLoader } from "src/components/Loader/Loader";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import FeatureAlert from "src/containers/FeatureAlert";
import { logEvent } from "src/helpers/analytics";
import { GraphQLFind, GraphQLMeta } from "src/types";
import AfipConnected from "../invoicing/components/AfipConnected";
import { AfipStatus } from "src/api/afip";
import { Rappi } from "src/components/Icon";
import { useUserStore } from "src/store/users";

const StoreConfigRow = ({ store }: { store: Store }) => {
  const { hasPermission } = useUserStore();
  const canEdit = hasPermission("UPDATE_STORE");
  const toastRef = useRef<ForwardedToastProps>(null);

  const [mutation, { loading }] = useMutation(Api.Stores.UPDATE_CONFIG, {
    onCompleted: () => {
      toastRef.current?.show();
    },
  });

  const [downloadMutation, { loading: downloading }] = useMutation(
    Api.Rappi.DOWNLOAD_IMAGES,
    {
      onCompleted: () => {
        toastRef.current?.show(
          "Las imagenes de productos han sido actualizadas"
        );
      },
    }
  );

  const formik = useFormik<
    Pick<StoreConfig, "autoPropagate"> &
      Pick<StoreMetadata, "rappiAutoDownloadOrders">
  >({
    initialValues: {
      autoPropagate: store.config.autoPropagate,
      rappiAutoDownloadOrders: store.metadata?.rappiAutoDownloadOrders ?? false,
    },
    onSubmit: (values) => {
      if (!loading) {
        const input = {
          autoPropagate: values.autoPropagate.toString() === "1",
          metadata: [
            {
              key: "rappiAutoDownloadOrders",
              value:
                values.rappiAutoDownloadOrders?.toString() === "1"
                  ? "true"
                  : "false",
            },
          ],
        };

        logEvent("rappi-store.config.update", {
          store,
          input,
        });

        mutation({
          variables: {
            id: store.id,
            input,
          },
        });
      }
    },
  });

  return (
    <div className="position-relative">
      <CRow>
        <h5>
          <Rappi size={24} /> {store.name}
        </h5>
      </CRow>
      <CRow>
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="¿Auto propagación de productos?"
            name="autoPropagate"
            onChange={formik.handleChange}
            defaultValue={formik.values.autoPropagate ? 1 : 0}
          >
            <option value={1}>Si</option>
            <option value={0}>No</option>
          </CFormSelect>
        </CCol>
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="¿Auto descarga de ventas?"
            name="rappiAutoDownloadOrders"
            onChange={formik.handleChange}
            defaultValue={formik.values.rappiAutoDownloadOrders ? 1 : 0}
          >
            <option value={1}>Si</option>
            <option value={0}>No</option>
          </CFormSelect>
        </CCol>
      </CRow>

      <Toast
        color="primary"
        autohide
        visible={false}
        ref={toastRef}
        text="Configuración actualizada"
      />

      <CRow className="mt-3">
        {!canEdit ? (
          <CCol md="12">
            <CAlert color="danger" className="px-3 py-1 m-0 text-center">
              No tienes permisos para editar estas configuraciones
            </CAlert>
          </CCol>
        ) : (
          <CCol md="12" className="text-right">
            <CLoadingButton
              size="sm"
              color="warning"
              loading={downloading}
              className="mr-2"
              onClick={() =>
                downloadMutation({
                  variables: {
                    storeId: store.id,
                  },
                })
              }
            >
              Descargar imagenes
            </CLoadingButton>

            <CLink href={`/#/rappi/${store.id}/products`}>
              <CButton size="sm" color="danger" className="mr-2">
                Auditoria productos
              </CButton>
            </CLink>

            <CLink href={`/#/rappi/${store.id}/sales`}>
              <CButton size="sm" color="info" className="mr-2">
                Ver ventas
              </CButton>
            </CLink>

            <CLoadingButton
              size="sm"
              color="primary"
              loading={loading}
              disabled={loading}
              onClick={() => formik.handleSubmit()}
            >
              Guardar
            </CLoadingButton>
          </CCol>
        )}
      </CRow>
    </div>
  );
};

const RappiScreen = () => {
  const { user } = useUserStore();
  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          type: StoreType.Rappi,
        },
      },
    }
  );

  if (!stores?.data?.data) {
    return <AppLoader />;
  }

  const companyId = stores.data.data[0].company.id;

  return (
    <>
      <FeatureAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>Configuración de Tiendas</CCardHeader>
            <CCardBody>
              {stores.data.data.map((store, index) => (
                <Fragment key={store.id}>
                  {stores.data.data.length > 1 && index > 0 && <hr />}
                  <StoreConfigRow store={store} key={store.id} />
                </Fragment>
              ))}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {!user?.isAdmin && <AfipConfiguration companyId={companyId} />}
    </>
  );
};

const AfipConfiguration = ({ companyId }) => {
  const { data: connection } = useQuery<GraphQLFind<AfipStatus[]>>(
    Api.Afip.GET_STATUS,
    {
      fetchPolicy: "no-cache",
      variables: {
        companyId,
      },
    }
  );

  return (
    <div className="mt-4">
      {connection?.data
        .filter(
          (currentConnection) =>
            currentConnection.status === "CONNECTED" &&
            currentConnection.store.type === StoreType.Rappi
        )
        .map((currentConnection, index) => (
          <Fragment key={currentConnection.store.id}>
            {connection?.data.length > 1 && index > 0 && <hr />}
            <AfipConnected
              companyId={currentConnection.company.id}
              storeId={currentConnection.store.id}
              type={StoreType.Rappi}
              services={currentConnection.services ?? []}
            />
          </Fragment>
        ))}
    </div>
  );
};

export default RappiScreen;
