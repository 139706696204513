import { gql } from "@apollo/client";
import { Customer, CustomerAddress } from "./customers";
import { Store } from "./stores";
import { Product } from "./products";
import { User } from "./users";
import { Nullable, WithTrash } from "src/types";
import { AfipInvoice, ElectronicInvoice } from "./afip";
import request from "./request";
import { CashRegister } from "./registers";
import { Promotion } from "./promotions";

export enum SaleOrigin {
  TiendaNube = "TiendaNube",
  Rappi = "Rappi",
  AdminWeb = "AdminWeb",
  FrontWeb = "FrontWeb",
  FrontMobile = "FrontMobile",
}

export enum SaleStatus {
  Cancelled = "CANCELLED",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
}

export type CartTotals = {
  subtotal: number;
  recharge: number;
  discount: number;
  itemsDiscount: number;
  customerDiscount: number;
  tax: number;
  total: number;
};

export type Sale = WithTrash<{
  id: number;
  customerId: number | null;
  recharge: number;
  discount: number;
  customerDiscount: number;
  date: string;
  origin: SaleOrigin;
  status: SaleStatus;
  notes: string;

  store: Store;
  payments: SalePayment[];
  customer?: Customer;
  items: SaleItem[];
  user: User;
  invoices: SaleInvoice[];
  electronicInvoices: ElectronicInvoice[];
  register: CashRegister;
  shippingAddress?: CustomerAddress;

  afipError?: boolean;
}>;

export type CreditNote =
  | Omit<SaleInvoice, "creditNotes">
  | Omit<ElectronicInvoice, "creditNotes">;

export type SaleInvoice = {
  id: number;
  relatedTo?: number;
  saleId: number;
  createdAt: Date;
  pdfUrl: Nullable<string>;
  emailSent: Nullable<Date>;
  cae: number;
  invoiceId: number;
  invoiceTypeId: number;
  posId: number;
  afipInvoice: AfipInvoice;
  voucher: AfipInvoice;
  creditNotes: CreditNote[];
  sale: Sale;
};

export type SaleItem = {
  id: number;
  saleId: number;
  price: number;
  priceListItemId: number;
  product: Product;
  cost: number;
  profit: number;
  productId: number;
  quantity: number;
  recharge: number;
  discount: number;
  tax: number;
  summary: {
    tax: number;
    price: number;
    total: number;
    recharge: number;
    discount: number;
  };
  promotion: Nullable<Promotion>;
};

export type SalePayment = {
  amount: number;
  type: PaymentMethod;
  saleId: number;
  payedInMonths: number;
  id: number;
};

export enum PaymentMethod {
  Cash = "Cash",
  MercadoPago = "MercadoPago",
  CreditCard = "CreditCard",
  PrepaidCard = "PrepaidCard",
  DebitCard = "DebitCard",
  Discount = "Discount",
  CustomerDiscount = "CustomerDiscount",
  Edenred = "Edenred",
  CuentaDNI = "CuentaDNI",
  RappiPay = "RappiPay",
  IVA = "IVA",
  Custom = "Custom",
  TiendaNubeWallet = "TiendaNubeWallet",
  Fee = "Fee",
  Nave = "Nave",
  OpenpayBBVA = "OpenpayBBVA",
  ViuMi = "ViuMi",
  GetNet = "GetNet",
  BankTransfer = "BankTransfer",
  CheckingAccount = "CheckingAccount",
  Check = "Check",
  eCheck = "eCheck",
  PedidosYa = "PedidosYa",
}

export enum CashRegisterAction {
  AddMoney = "AddMoney",
  WithdrawMoney = "WithdrawMoney",
  Sell = "Sell",
}

export const LIST_SALES = gql`
  query getSales($filters: SaleFilters) {
    data: sales(filters: $filters) {
      data {
        id
        date
        discount
        customer {
          id
          utid
          name
          lastname
          email
        }
        store {
          name
          type

          company {
            name
          }
        }
        items {
          product {
            name
          }
          quantity
          price
          tax
          discount
          recharge
        }
        user {
          id
          name
          lastname
        }
        payments {
          amount
          type
        }
        invoices {
          id
          voucher {
            voucher {
              id: identifier
            }
          }
        }
        status
        origin
        deletedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_SALE = gql`
  query getSale($id: Int!) {
    data: sale(id: $id) {
      id
      date
      discount
      customerDiscount
      origin
      status
      sessionId
      notes

      customer {
        id
        utid
        utidType
        name
        lastname
        email
        alias
        taxCondition

        streetName
        streetNumber
        city
        province
        zipCode

        accounts {
          id
          balance
        }
      }

      register {
        config {
          paymentMethods
        }
      }

      store {
        id
        name
        utid
        type

        config {
          invoiceTypes {
            id
            name
            anonymous
          }
        }

        company {
          id
          utid
          isFranchise
        }
      }

      shippingAddress {
        streetName
        streetNumber
        city
        province
        zipCode
      }

      payments {
        id
        amount
        type
        cardNumber
        payedInMonths
      }

      items {
        product {
          id
          name
        }
        promotion {
          id
          name
        }
        quantity
        profit
        cost
        price
        tax
        discount
        recharge
      }

      afipError
      invoices {
        id
        saleId
        cae
        invoiceId
        invoiceTypeId
        posId

        afipInvoice {
          cae
          voucher {
            id
            name
          }
          document {
            id
          }
          dniNumber
          total
          packId
        }

        relatedTo

        parent {
          id
        }

        pdfUrl
        emailSent
        createdAt
      }

      metadata {
        mercadoLibreOrderId
        mercadoLibrePackId
      }

      electronicInvoices {
        utid
        cae
        pdfUrl
        emailSent
        createdAt

        voucher {
          dniNumber
          total

          document {
            id
            name
          }

          pointOfSale {
            id
            stringType
          }

          voucher {
            name
          }

          seller {
            legalName
            address {
              streetName
              streetNumber
              zipCode
              province
            }
            startDate
          }

          payer {
            legalName
          }

          qr
        }
      }

      deletedAt
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($id: Int!, $note: String!) {
    data: updateNote(id: $id, note: $note) {
      id
    }
  }
`;

export const DELETE_SALE = gql`
  mutation deleteSale($id: Int!, $restock: Boolean) {
    data: deleteSale(id: $id, restock: $restock) {
      id
    }
  }
`;

export const CANCEL_SALE = gql`
  mutation cancelSale($id: Int!) {
    data: cancelSale(id: $id) {
      id
    }
  }
`;

export const UPDATE_SALE = gql`
  mutation updateSale($id: Int!, $input: UpdateSaleInput!) {
    data: updateSale(id: $id, input: $input) {
      id
    }
  }
`;

export const CREATE_SALE = gql`
  mutation createSale($input: CreateSaleInput!) {
    data: createSale(input: $input) {
      id
      date
      discount
      customer {
        id
        name
        lastname
        email
        utid
      }
      store {
        name

        company {
          name
        }
      }
      payments {
        amount
        type
      }
      items {
        product {
          name
        }
        cost
        profit
        quantity
        price
        tax
        discount
        recharge
      }
      register {
        name
      }
      user {
        name
        lastname
      }
      invoices {
        utid
        cae

        afipInvoice {
          date
          processDate
          dniNumber

          document {
            id
            name
          }

          pointOfSale {
            id
            stringType
          }

          voucher {
            id
            name
          }

          seller {
            legalName
            address {
              streetName
              streetNumber
              zipCode
              province
            }
            startDate
          }

          invoiceNumber

          payer {
            legalName
            address {
              streetName
              streetNumber
              zipCode
              province
            }
          }

          qr
        }
      }
    }
  }
`;

export const DOWNLOAD_SALE_INVOICE = (
  saleId: number,
  cae: string,
  type: "credit" | "invoice"
) =>
  request.post(
    `/sales/${saleId}/invoice/${cae}`,
    {
      type,
    },
    {
      responseType: "blob",
    }
  );

export const ATTACH_INVOICE = gql`
  mutation attachInvoice($id: Int!, $cae: String!) {
    data: attachInvoice(id: $id, cae: $cae) {
      id
    }
  }
`;

export const SEND_INVOICE = gql`
  mutation ($cae: String!, $type: String!, $emails: [String!]!) {
    data: sendInvoice(cae: $cae, type: $type, emails: $emails) {
      id
    }
  }
`;

export const UPDATE_SALE_PAYMENT = gql`
  mutation updateSalePayment($id: Int!, $type: String) {
    data: updateSalePayment(id: $id, type: $type) {
      id
    }
  }
`;

export const DELETE_SALE_PAYMENT = gql`
  mutation deleteSalePayment($id: Int!) {
    data: deleteSalePayment(id: $id) {
      id
    }
  }
`;

export const ADD_SALE_PAYMENTS = gql`
  mutation addSalePayments($id: Int!, $payments: [SalePaymentInput!]!) {
    data: addSalePayments(id: $id, payments: $payments) {
      id
    }
  }
`;

export const FINISH_SALE = gql`
  mutation finishSale($id: Int!) {
    data: finishSale(id: $id) {
      id
    }
  }
`;
